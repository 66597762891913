import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit{

  selected_faq: string = '';

  constructor() {
  }

  ngOnInit(): void {

  }

  setFAQSelected(str: string) {
    if (str === this.selected_faq) {
      this.selected_faq = '0';
    } else {
      this.selected_faq = str;
    }
  }

  openGitbook(url:string){
    window.open(url,'_blank')
  }

}
