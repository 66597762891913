<div class="flex flex-col justify-center gap-4 p-4 sm:p-8 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="title text-white">{{'dialog.change_name.info_name'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <div class="flex flex-col w-full items-start text-start gap-2 mt-5">
    <label class="subtitle text-white !font-bold">{{'dialog.change_name.title_how'|translate}}</label>
    <label class="subtitle text-light-gray">{{'dialog.change_name.desc_how'|translate}}</label>
  </div>
</div>
