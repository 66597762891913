<div class="container_header h-24 md:h-32 p-4 md:p-8 items-center justify-between flex-row gap-8  mx-auto" >
  <div class="hidden lg:block w-full max-w-3xl">
    <div class="flex flex-row w-full items-center justify-between md:gap-10 lg:gap-20">
      <app-image src="/logo/logo-o-viola-chiaro.png" class=" image cursor-pointer" [routerLink]="'/dashboard/summary'"></app-image>
      <div class="hidden lg:flex flex-row w-full max-w-md	items-center justify-between px-4" >
        <div class="flex flex-row items-center gap-2">
          <app-image src="icon_token/fdc.svg" class="w-6"></app-image>
          <label class="amount">{{fdc | currency: '':'':'1.2-3'}} FDC</label>
        </div>
        <div class="flex flex-row items-center gap-2">
          <app-image src="icon_token/hydro.svg" class="w-6"></app-image>
          <label class="amount">{{hydro| currency: '':'':'1.2-3'}} HYD</label>
        </div>
      </div>
    </div>
  </div>
  <app-image src="/logo/stemma-viola-chiaro.png" class="block lg:hidden image cursor-pointer !w-10" [routerLink]="'/dashboard/summary'"></app-image>
  <div  class="flex flex-row w-full items-center justify-end gap-8">
    <div *ngIf="planets && planets.length > 0" class="flex flex-col gap-4">
      <button [matMenuTriggerFor]="menu" class="flex flex-row items-center gap-3 styling_select ">
        <label class="desc_planet ml-0">{{planetSelectedName}}</label>
        <span *ngIf="attackUpComing && planetSelectedName" class="relative flex h-3 w-3 mr-4 ml-auto">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-signarok-red opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-red">
        </span>
      </span>
      </button>
      <mat-menu #menu="matMenu" class="flex flex-col !bg-light-purple !rounded-none !w-40 !mt-2">
        <div mat-menu-item *ngFor="let planet of planets;let i = index" (click)="setSelect(planet)">
          <div class="flex !flex-row items-center gap-2" >
            <span class="desc_planet">{{ planet.name }}</span>
            <span *ngIf="planet.attackUpComing" class="relative flex h-3 w-3  mr-4 ml-auto">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-signarok-red opacity-75"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-red"></span>
            </span>
          </div>
        </div>
      </mat-menu>
    </div>
    <div class="hidden lg:flex outside items-center justify-center cursor-pointer mr-12">
      <div class="flex inside text-white items-center justify-center cursor-pointer" (click)="disconnectWallet()">
        <label class="cursor-pointer">{{walletAddress}}</label>
      </div>
    </div>
    <app-image src="/sidebar_icon/menu.svg" class="block lg:hidden w-10 " (click)="drawer.toggle()"></app-image>
  </div>
</div>
