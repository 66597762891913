<div class="flex flex-col px-0 sm:px-2 md:flex-row w-full h-full items-start justify-between gap-10">
  <div class="flex flex-col bg-tab w-full  md:w-1/2 gap-10 mr-4 xl:mr-10 2xl:mr-20 ">
    <div class="w-full h-56 md:!h-96 background_image" [ngStyle]="{background:'url(../../../../../assets/images/structure/swap.png)'}">
    </div>
  </div>
  <div class="flex flex-col w-full h-full md:w-1/2 gap-8">
    <label class="title text-white">{{'dialog.swap.title'|translate}}</label>
    <form
      [formGroup]="swapForm"
      (ngSubmit)="swapToken()"
      class="flex flex-col w-full items-start gap-4"
    >
      <label class="subtitle text-white">{{'dialog.swap.subtitle'|translate}}</label>
      <div class="flex flex-row w-full max-w-sm items-center justify-start gap-2 bg-light-black text-white p-2  h-9">
        <input class="bg-transparent p-2 w-full outline-0" type="number" [placeholder]="'FDC'" formControlName="amount" (input)="setHYD($event)"/>
      </div>
      <label class="subtitle text-white">{{'dialog.swap.subtitle_change'|translate}}</label>
      <div class="flex flex-row w-full max-w-sm items-center justify-start gap-2">
        <img alt="" src="assets/images/icon_token/hydro.svg" class="w-6"/>
        <label class="subtitle text-white">: {{hyd | currency: '':'':'1.2-3'}}</label>
      </div>
      <div class="flex flex-col w-full gap-2 items-start mt-4">
        <button *ngIf="!loadSwap" [disabled]="this.swapForm.get('amount')?.value === 0 || this.swapForm.get('amount')?.value > fdc" [class]="this.swapForm.get('amount')?.value >0 && this.swapForm.get('amount')?.value <= fdc ? 'button !m-0 text-white bg-purple cursor-pointer':'button !m-0 text-white bg-dark-black opacity-60 '" type="submit">{{'dialog.buy_ships.confirm'|translate}}</button>
        <button *ngIf="loadSwap" class="button text-white bg-purple flex items-center justify-center !m-0">
          <mat-spinner [diameter]="20"></mat-spinner>
        </button>
      </div>
    </form>
  </div>
</div>
