import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-update-structure',
  templateUrl: './dialog-update-structure.component.html',
  styleUrls: ['./dialog-update-structure.component.scss']
})
export class DialogUpdateStructureComponent implements OnInit{
  price:any;
  time:any;
  name:any;
  address:any;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogUpdateStructureComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogUpdateStructureComponent
  ) {}

  ngOnInit(){
    this.name = this.data.name;
    this.price = this.data.price
    this.time = this.data.time
  }

  confirmUpdate() {
    this._dialogRef.close({data:'confirm'})
  }

}
