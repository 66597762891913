import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-view-ships-destroyed',
  templateUrl: './dialog-view-ships-destroyed.component.html',
  styleUrls: ['./dialog-view-ships-destroyed.component.scss']
})
export class DialogViewShipsDestroyedComponent implements OnInit{
  ships:any;
  allShips:any;
  constructor(
    public _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: DialogViewShipsDestroyedComponent
  ) {}

  ngOnInit(){
    const allAmount = this.data.ships
    this.allShips = this.data.allShips
    const ships = []
    for(let i = 0; i < allAmount.amounts.length; i++){
      const allData = this.allShips[allAmount.type[i]]
      ships.push({uri: allData.imageURL,type:allAmount.type[i],amount:allAmount.amounts[i],name: allData.metadata.name})
    }
    this.ships = ships.filter((ship)=> ship.amount > 0);
  }
}
