<div class="flex flex-col justify-center gap-4 p-4 sm:p-8 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="title text-white">{{'dialog.force_reveal.title'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <div class="flex flex-col w-full items-start text-start gap-2 mt-5">
    <label class="subtitle text-white !font-bold">{{'dialog.force_reveal.description'|translate}}</label>
  </div>
  <div class="flex flex-row w-full items-center justify-center gap-8 mt-5">
    <button class="button !m-0 text-white bg-purple" (click)="_dialog.closeAll()">{{"dialog.force_reveal.cancel" | translate}}</button>
    <button class="button !m-0 text-white bg-purple" (click)="confirm('confirmed')">{{"dialog.force_reveal.confirm" | translate}}</button>
  </div>
</div>
