<div *ngIf="this.scoreThisWeek && this.scoreLastWeek && this.scoreNextWeek" class="flex flex-col gap-10 px-0 sm:px-2 lg:pl-0 lg:pr-10">
  <div class="flex flex-col md:flex-row items-center justify-start gap-8">
    <div class="flex flex-row items-center gap-4">
      <img src="assets/images/icon/arrow_back.svg" class="cursor-pointer" (click)="setPage('-')"  alt="">
      <div class="flex flex-col items-center justify-around">
        <div class="flex flex-row items-center justify-around gap-2">
          <label class="subtitle text-white">{{this.scoreThisWeek.roundInfo.startWeek * 1000 | date: "dd-MM-YYYY HH:mm":"it"}}</label>
          <label class="subtitle text-white">/</label>
          <label class="subtitle text-white">{{this.scoreThisWeek.roundInfo.endWeek * 1000 | date: "dd-MM-YYYY HH:mm":"it"}}</label>
        </div>
      </div>
      <img src="assets/images/icon/arrow_next.svg" class="cursor-pointer" (click)="setPage('+')" alt="">
    </div>
    <div class="flex flex-row items-center justify-start gap-4">
      <span [class]="type === 'off' ? 'text-sm subtitle text-light-purple !font-bold cursor-default' :'text-sm subtitle text-light-gray cursor-default'">{{"dashboard.fleet.attack" | translate}}</span>
      <label class="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" aria-labelledby="Toggle" id="Toggle" [value]="type"  class="sr-only peer outline-0" (click)="setType(type === 'off' ? 'def': 'off')">
        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-light-purple peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-light-purple"></div>
      </label>
      <span [class]="type === 'def' ? 'text-sm subtitle text-light-purple !font-bold cursor-default' :'text-sm subtitle text-light-gray cursor-default'">{{"dashboard.fleet.defense" | translate}}</span>
    </div>
  </div>
  <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row items-start justify-between w-full max-w-7xl gap-4">
    <div class="flex flex-col items-start justify-start w-full gap-8">
      <div class="flex flex-col items-start justify-start w-full gap-4">
        <label class="title_card text-white">{{'dashboard.statistics.reward_last_week'|translate}}</label>
        <div *ngIf="type === 'def'" class="card_time_build flex flex-col items-start w-full p-4 gap-4" >
          <label class="subtitle text-white">{{'dashboard.statistics.def'|translate}}</label>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.score'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.scoreDef}}</label>
            </div>
            <div class="flex flex-col gap-2  w-60">
              <label class="desc-h text-light-purple gap-2">{{'dashboard.statistics.score_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.scoreTotDef}}</label>
            </div>
          </div>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.reward'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.rewardDef | currency: '':'':'1.2'}} FDC</label>
            </div>
            <div class="flex flex-col gap-2  w-60">
              <label class="desc-h text-light-purple gap-2">{{'dashboard.statistics.reward_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.rewardTotDef | currency: '':'':'1.2'}} FDC</label>
            </div>
          </div>
        </div>
        <div *ngIf="type === 'off'" class="card_time_build flex flex-col items-start w-full p-4 gap-4" >
          <label class="subtitle text-white">{{'dashboard.statistics.off'|translate}}</label>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.score'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.scoreOff}}</label>
            </div>
            <div class="flex flex-col gap-2  w-60">
              <label class="desc-h text-light-purple gap-2">{{'dashboard.statistics.score_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.scoreTotOff}}</label>
            </div>
          </div>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.reward'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.rewardOff | currency: '':'':'1.2'}} FDC</label>
            </div>
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.reward_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreLastWeek.rewardTotOff | currency: '':'':'1.2'}} FDC</label>
            </div>
          </div>
        </div>
        <button *ngIf="!loadClaim" [class]="this.scoreLastWeek.roundToClaim > 0 ? 'button !m-0 text-white bg-purple cursor-pointer' : 'button !m-0 text-white bg-dark-black opacity-60'" [disabled]="this.scoreLastWeek.roundToClaim === 0" (click)="claimAllReward()">{{'dashboard.statistics.claim'|translate}}</button>
        <button *ngIf="loadClaim" class="button text-white bg-purple flex items-center justify-center !m-0">
          <mat-spinner [diameter]="20"></mat-spinner>
        </button>
      </div>
      <div class="flex flex-col items-start justify-start w-full gap-4">
        <div class="flex flex-row gap-2 items-start">
          <label class="title_card text-white">{{'dashboard.statistics.reward_next_week'|translate}}</label>
          <img src="assets/images/icon/info.svg" alt="" class="w-6 cursor-pointer" (click)="openDescription()">
        </div>
        <div class="card_time_build flex flex-col items-start w-full p-4 gap-4" >
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.off'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreNextWeek.rewardTotOff | currency: '':'':'1.2'}} FDC</label>
            </div>
            <div class="flex flex-col gap-2  w-60">
              <label class="desc-h text-light-purple gap-2">{{'dashboard.statistics.def'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreNextWeek.rewardTotDef | currency: '':'':'1.2'}} FDC</label>
            </div>
          </div>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.reward_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreNextWeek.rewardTotOff + this.scoreNextWeek.rewardTotDef | currency: '':'':'1.2'}} FDC</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col items-start justify-start w-full max-w-lg gap-7">
      <div class="flex flex-col items-start justify-start w-full gap-4">
        <label class="title_card text-white">{{'dashboard.statistics.reward_this_week'|translate}}</label>
        <div *ngIf="type === 'def'" class="card_time_build flex flex-col items-start w-full p-4 gap-4" >
          <label class="subtitle text-white">{{'dashboard.statistics.def'|translate}}</label>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.score'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.scoreDef}}</label>
            </div>
            <div class="flex flex-col gap-2  w-60">
              <label class="desc-h text-light-purple gap-2">{{'dashboard.statistics.score_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.scoreTotDef}}</label>
            </div>
          </div>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.reward'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.rewardDef | currency: '':'':'1.2'}} FDC</label>
            </div>
            <div class="flex flex-col gap-2  w-60">
              <label class="desc-h text-light-purple gap-2">{{'dashboard.statistics.reward_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.rewardTotDef | currency: '':'':'1.2'}} FDC</label>
            </div>
          </div>
        </div>
        <div *ngIf="type === 'off'" class="card_time_build flex flex-col items-start w-full p-4 gap-4" >
          <label class="subtitle text-white">{{'dashboard.statistics.off'|translate}}</label>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.score'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.scoreOff}}</label>
            </div>
            <div class="flex flex-col gap-2  w-60">
              <label class="desc-h text-light-purple gap-2">{{'dashboard.statistics.score_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.scoreTotOff}}</label>
            </div>
          </div>
          <div class="flex flex-row items-center justify-between w-full gap-4">
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.reward'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.rewardOff | currency: '':'':'1.2'}} FDC</label>
            </div>
            <div class="flex flex-col gap-2 w-60">
              <label class="desc-h text-light-purple">{{'dashboard.statistics.reward_tot'|translate}}: </label>
              <label class="text-white desc-h">{{this.scoreThisWeek.rewardTotOff | currency: '':'':'1.2'}} FDC</label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center justify-between w-full">
        <label class="title_card text-white">{{'dashboard.statistics.ranking'|translate}}</label>
        <label class="subtitle text-light-purple">{{'dashboard.statistics.latest_update'|translate}}:  <label class="desc-h text-white">{{timeUpdate * 1000 | date: "dd-MM-YYYY HH:mm":"it"}}</label></label>
      </div>
      <table class="w-full max-w-7xl py-4 px-4 text-sm text-left shadow-none">
        <thead class="text-xs text-light-gray uppercase bg-transparent">
        <tr>
          <th scope="col" class=" subtitle text-light-purple cursor-default">
            {{"dashboard.statistics.name" | translate}}
          </th>
          <th scope="col" class=" subtitle text-light-purple cursor-default">
            {{"dashboard.statistics.id" | translate}}
          </th>
          <th scope="col" class=" subtitle text-light-purple cursor-default">
            {{"dashboard.statistics.score" | translate}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let planet of filterRanking;let i = index" [class]="i%2 ? 'bg-tab' : 'bg-transparent'">
          <td class="py-2 subtitle text-white">
            {{planet.name}}
          </td>
          <td class="py-2 subtitle text-white">
            {{planet.planetId}}
          </td>
          <td class="py-2 subtitle text-white">
            {{planet.score}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
