import {Component, DoCheck, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {PlanetService} from "../../../../core/services/planet.service";
import {AccountService} from "../../../../core/services/account.service";
import {GlobalVariables, WalletService} from "@scalingparrots/dapp-angular-lib";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, DoCheck{
  path:any = '';
  address:any = '';
  fdc:any;
  hydro:number=0;
  planetSelected:any;
  @Input() drawer:any;
  @Input() attackUpComing:any;
  @Input() planetAttacked:any;
  constructor(
    private router: Router,
    private _planetService:PlanetService,
    private _walletService:WalletService,
    private _accountService:AccountService,
  ) {
  }
  ngOnInit(): void {
    const path = this.router.url.split('/');
    this.path = path[2];
    setInterval(()=>{
      if(this.address && this.planetSelected !== localStorage.getItem(this.address+'planetSelected')){
        const planetName = localStorage.getItem(this.address+'planetSelected')
        if(this.planetAttacked !== undefined){
          this.planetSelected = this.planetAttacked.filter((data:any)=>data.name === planetName)
        }
      }
    },1000)
  }

  ngDoCheck(): void {
    const path = this.router.url.split('/');
    this.path = path[2];
    this._accountService.getWalletAddress().subscribe({
      next: (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getBalance().subscribe({
            next: (balance) => {
              if(balance && this.fdc === undefined){
                this.fdc = balance.balanceFDC
                this.hydro = balance.balanceHYD
              }
            }
          })
        }
      }
    })
  }

  goToPage(url:string){
    this.router.navigate([url])
    if(window.innerWidth < 1024){
      this.drawer.close()
    }
  }

  disconnectWallet(): void {
    if (this.getGlobalVariables().type === 'walletconnect') {
      this._walletService
        .disconnectWallet()
        .then(() => window.localStorage.setItem('connected', String(false)));
    } else {
      window.localStorage.setItem('connected', String(false));
      window.location.reload();
    }
  }

  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }
}
