import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-info-name',
  templateUrl: './dialog-info-name.component.html',
  styleUrls: ['./dialog-info-name.component.scss']
})
export class DialogInfoNameComponent implements OnInit{
  constructor(
    public _dialog: MatDialog,
  ) {}

  ngOnInit(){
  }
}
