import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';

@Pipe({
  name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
  transform(array: any,type: string, order: "asc" | "desc" = "asc"): any[] {
    return array.sort((a:any, b:any) => {
      if (order === "asc") {
        return a[type] - b[type];
      } else if (order === "desc") {
        return b[type] - a[type];
      }
      return 0;
    });
  }
}
