<div class="flex flex-col justify-center gap-4 p-4 sm:p-5 text-center ">
  <form
    [formGroup]="nameForm"
    class="flex flex-col items-start gap-10 w-full"
  >
    <div class="flex flex-col items-start justify-start gap-2 w-full">
      <label class="subtitle text-light-gray">{{'dashboard.mint.title_input'|translate}}</label>
      <div class="flex flex-row items-center justify-start gap-2 w-full">
        <input class="bg-light-black text-white p-2 w-full h-8 max-w-xs outline-0" maxlength="15" placeholder="{{'dashboard.mint.name_planet'|translate}}" formControlName="name" />
        <img class="w-6 cursor-pointer" src="assets/images/icon/why.svg" alt=""  (click)="openInfoName()">
      </div>
    </div>
    <div class="flex flex-col items-start justify-start gap-2 w-full">
      <label class="subtitle text-light-gray">{{'dashboard.mint.title_faction'|translate}}</label>
      <div class="flex flex-row items-center justify-start gap-10 w-full">
        <div [class]="civilizationId === 1 ? 'container_images_selected':'container_images'">
          <img class="h-14 cursor-pointer" src="assets/images/factions/signarok.png" alt="" (click)="selectFaction(1)">
        </div>
        <div [class]="civilizationId === 2 ? 'container_images_selected':'container_images'">
          <img class="h-14 cursor-pointer" src="assets/images/factions/numaya.png" alt="" (click)="selectFaction(2)">
        </div>
        <div [class]="civilizationId === 3 ? 'container_images_selected':'container_images'">
          <img class="h-14 cursor-pointer" src="assets/images/factions/afarit.png" alt="" (click)="selectFaction(3)">
        </div>
      </div>
    </div>
  </form>
  <div class="flex flex-col w-full px-2 sm:px-4" >
    <div class="flex flex-col w-full gap-3 overflow-x-auto h-52">
      <table class="w-full max-w-7xl py-4 text-sm text-left">
        <thead class="uppercase bg-transparent">
        <tr>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.claim_plan_myst.id" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.claim_plan_myst.select" | translate}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let planet of oldPlanet; let i = index" [class]="planetSelected === i ? 'background_selected border-b-white border-b-2' : 'bg-transparent border-b-white border-b-2'">
          <th class="p-2">
            <img src="assets/images/no_planet.png" alt="" class="w-10">
          </th>
          <td class="p-2 subtitle text-white">
           <label>Id: {{planet.id}}</label>
          </td>
          <td class="p-2">
            <div class="w-6 h-6 rounded-full p-1 bg-purple" *ngIf="planetSelected === i">
              <div class="w-full h-full rounded-full bg-light-purple">

              </div>
            </div>
            <div class="w-6 h-6 rounded-full p-1 bg-purple cursor-pointer" *ngIf="planetSelected !== i" (click)="selectPlanet(i)">
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="flex flex-col w-full p-2 sm:p-4" >
    <div class="flex flex-row w-full items-center justify-center gap-8 mt-5">
      <button class="button !mx-auto text-white bg-purple" (click)="_dialog.closeAll()">{{"dialog.mint_ships.cancel" | translate}}</button>
      <button *ngIf="!loading" [class]="nameForm.invalid || this.planetSelected === undefined ? 'button text-white bg-dark-black opacity-60 !mx-auto' :'button text-white bg-purple !mx-auto'"
              [disabled]="nameForm.invalid || this.planetSelected === undefined " (click)="confirmMint()">{{"dashboard.mint.claim" | translate}}</button>
      <button *ngIf="loading" class="button text-white bg-purple flex items-center justify-center  !mx-auto">
        <mat-spinner [diameter]="20"></mat-spinner>
      </button>
    </div>
  </div>
</div>
