import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  lang:string = 'it'
  constructor(
    private _translateService:TranslateService,
  ) {
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang')
    if(lang){
      this.lang = lang
    }
  }

  setLang(lang:string) {
    localStorage.setItem('lang',lang)
    this.lang = lang
    this._translateService.setDefaultLang(lang)
    this._translateService.use(lang)
  }

  openLink(url:string){
    window.open(url)
  }

  openTelegram(){
    const lang = this._translateService.getBrowserLang()
    if(lang === 'it'){
      window.open('https://t.me/+vtYmeCo798E5Y2Nk')
    } else {
      window.open('https://t.me/+ViLl2xiNAGI3MDM0')
    }
  }
}
