import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ethers} from "ethers";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-dialog-mint-ships',
  templateUrl: './dialog-mint-ships.component.html',
  styleUrls: ['./dialog-mint-ships.component.scss']
})
export class DialogMintShipsComponent implements OnInit{
  amountForm: FormGroup = new FormGroup({});
  ship:any;
  fdcTotal:any;
  timeUpdateTotal:any;
  label:any='';
  totalTime:any='0h : 0m : 0s';
  totalTimeSec= 0;
  balance= 0;
  sumShips=0;
  constructor(
    public _dialog: MatDialog,
    public _formBuilder: FormBuilder,
    private _dialogRef: MatDialogRef<DialogMintShipsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogMintShipsComponent
  ) {}

  ngOnInit(){
    this.amountForm = this._formBuilder.group({
      amount: [0, Validators.required],
    });
    this.ship = this.data.ship
    this.balance = this.data.balance
    this.label = this.data.label
    this.fdcTotal = ethers.BigNumber.from(0);
    this.timeUpdateTotal = ethers.BigNumber.from(0);
  }

  addShipsToFleet(type:string,id:any){
    if(type === '+'){
      if(this.ship.maxSupply === 0){
        this.sumShips = this.sumShips + this.ship.priceFDC
        this.ship.size = this.ship.size + 1
        this.amountForm.get('amount')?.setValue(this.ship.size)
        const timeRemainingInSeconds = (this.ship.size) * this.ship.timeBuild ;
        this.ship.approximateTime = Math.floor(timeRemainingInSeconds / 3600) + 'h : ' + Math.floor((timeRemainingInSeconds % 3600) / 60) + 'm : ' + timeRemainingInSeconds % 60 + 's'
        this.timeTotalUpdate()
      } else if((this.ship.size + this.ship.quantity) < this.ship.maxSupply){
        this.sumShips = this.sumShips + this.ship.priceFDC
        this.ship.size = this.ship.size + 1
        this.amountForm.get('amount')?.setValue(this.ship.size)
        const timeRemainingInSeconds = (this.ship.size) * this.ship.timeBuild ;
        this.ship.approximateTime = Math.floor(timeRemainingInSeconds / 3600) + 'h : ' + Math.floor((timeRemainingInSeconds % 3600) / 60) + 'm : ' + timeRemainingInSeconds % 60 + 's'
        this.timeTotalUpdate()
      }
    }else {
      if(this.ship.size - 1 >= 0){
        this.sumShips = this.sumShips - this.ship.priceFDC
        this.ship.size = this.ship.size-1
        this.amountForm.get('amount')?.setValue(this.ship.size)
        const timeRemainingInSeconds = (this.ship.size) * this.ship.timeBuild ;
        this.ship.approximateTime = Math.floor(timeRemainingInSeconds / 3600) + 'h : ' + Math.floor((timeRemainingInSeconds % 3600) / 60) + 'm : ' + timeRemainingInSeconds % 60 + 's'
        this.timeTotalUpdate()
      }
    }
  }

  addShipsWithAmountToFleet(e:Event){
    const value = Number((e.target as HTMLInputElement).value);
    this.sumShips = (value * this.ship.priceFDC)
    this.ship.size = value
    const timeRemainingInSeconds = (this.ship.size) * this.ship.timeBuild ;
    this.ship.approximateTime = Math.floor(timeRemainingInSeconds / 3600) + 'h : ' + Math.floor((timeRemainingInSeconds % 3600) / 60) + 'm : ' + timeRemainingInSeconds % 60 + 's'
    this.timeTotalUpdate()
  }

  timeTotalUpdate(){
    let totalTime = 0;
    totalTime = this.ship.size *  this.ship.timeBuild
    this.totalTimeSec = totalTime;
    this.totalTime = Math.floor(totalTime / 3600) + 'h : ' + Math.floor((totalTime % 3600) / 60) + 'm : ' + totalTime % 60 + 's';
  }

  confirmShips(type:string) {
    if(this.ship.size > 0){
      let shipsId: any[] = [];
      let shipsAmount: any[] = [];
      let shipsTotalFDC = 0;
      let shipsTimeToNext = 0;
      let shipsTime: any[] = [];
      shipsId.push(this.ship.id)
      shipsAmount.push(this.ship.size)
      shipsTotalFDC = this.ship.priceFDC * this.ship.size
      shipsTimeToNext = this.ship.timeBuild * this.ship.size
      shipsTime.push({time:shipsTimeToNext})
      this._dialogRef.close({data:{type:type,fdcTotal:shipsTotalFDC,typeShip:shipsId,amounts:shipsAmount,timeUpdateTotal:this.totalTimeSec,arrayTimeShips:shipsTime,ship:this.ship}})
      this.amountForm.get('amount')?.setValue(0)
    }
  }
}
