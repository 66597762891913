<div class="flex flex-col justify-center gap-6 p-4 sm:p-8 text-center ">
  <div class="flex flex-col container_new_fleet items-start">
    <div class="flex flex-row w-full items-center justify-between">
      <label class="subtitle md:title text-white">{{"dashboard.fleet.add_new_fleet" | translate}}</label>
      <div class="flex flex-row items-center gap-4 sm:gap-8">
        <div class="flex flex-row items-center justify-start gap-2 md:gap-4">
          <span [class]="type === 'off' ? 'desc-h md:subtitle text-light-purple !font-bold' :'text-sm desc-h md:subtitle text-light-gray'">{{"dashboard.fleet.attack" | translate}}</span>
          <label class="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" [value]="type" [checked]="type==='def'" class="sr-only peer outline-0" (click)="setType(type === 'off' ? 'def': 'off')">
            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-light-purple peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-light-purple"></div>
          </label>
          <span [class]="type === 'def' ? 'desc-h md:subtitle text-light-purple !font-bold' :'text-sm desc-h md:subtitle text-light-gray'">{{"dashboard.fleet.defense" | translate}}</span>
        </div>
        <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
      </div>
    </div>
    <div class="flex flex-col w-full sm:px-4 mt-2 sm:mt-4" >
      <div class="flex flex-col w-full gap-3 mt-5 overflow-x-auto h-52">
        <table class="table-responsive w-full max-w-7xl text-sm text-left bg-transparent shadow-none text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-light-gray uppercase bg-transparent">
          <tr>
            <th scope="col" class="p-2 cursor-default">
              {{"dashboard.fleet.NAME" | translate}}
            </th>
            <th scope="col" class="p-2 cursor-default">
              {{"dashboard.fleet.SIZE" | translate}}
            </th>
            <th scope="col" class="p-2 cursor-default">
              {{"dashboard.fleet.SELECT" | translate}}
            </th>
            <th scope="col" class="p-2 cursor-default">
              {{"dashboard.fleet.TOTAL_SHIPS" | translate}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let ship of ships| orderBy: 'sizeValue':'asc'; let index = index"  [class]="index % 2 == 0 ? 'bg-tab' : 'bg-transparent'">
            <td [attr.aria-label]="'dashboard.fleet.NAME' | translate" class="p-2 desc-h md:subtitle text-light-purple md:!w-36">
              <div class="flex flex-row items-center gap-2">
                <div class="flex flex-col items-center !w-11">
                  <img *ngIf="ship.civId === 1" class="h-5" src="assets/images/factions/signarok.png" alt="">
                  <img *ngIf="ship.civId === 2" class="h-5" src="assets/images/factions/numaya.png" alt="">
                  <img *ngIf="ship.civId === 3" class="h-5" src="assets/images/factions/afarit.png" alt="">
                </div>
                <label class="text-white">{{ship.name}}</label>
              </div>
            </td>
            <td [attr.aria-label]="'dashboard.fleet.SIZE' | translate" class="p-2 desc-h md:subtitle text-light-purple">
              {{ship.valueOfSize}}
            </td>
            <td [attr.aria-label]="'dashboard.fleet.SELECT' | translate" class="p-2 desc-h md:subtitle text-light-purple md:!w-32">
              <div class="flex flex-row w-20 items-center gap-2 justify-between text-center ">
                <img src="assets/images/icon/remove.svg" class="cursor-pointer w-4" alt="" (click)="addShipsToFleet('-',ship)">
                <input [value]="ship.size" type="number" class="w-10 bg-dark-black outline-0 !rounded px-2" (input)="onChangeInputQuantity($event,ship)"/>
                <img [src]="ship.quantity > 0  ? 'assets/images/icon/add.svg':'assets/images/icon/add_disabled.svg'" class="cursor-pointer w-4" alt="" (click)="(ship.quantity > 0) && addShipsToFleet('+',ship)">
              </div>
            </td>
            <td [attr.aria-label]="'dashboard.fleet.TOTAL_SHIPS' | translate" class="p-2 desc-h md:subtitle text-light-purple">
              {{ship.quantity}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="fleet" class="flex flex-col sm:flex-row  items-center mx-auto gap-2">
      <label *ngIf="this.type === 'off'" class="desc-h text-light-purple">{{"dashboard.fleet.attack" | translate}}: <label class="text-white">{{fleet.totalAtt}}</label></label>
      <label *ngIf="this.type === 'def'" class="desc-h text-light-purple">{{"dashboard.fleet.defense" | translate}}: <label class="text-white">{{fleet.totalDef}}</label></label>
      <label class="desc-h text-light-purple">{{"dashboard.fleet.resistance" | translate}}: <label class="text-white">{{fleet.resistance| currency: '':'':'1.0'}} %</label></label>
      <label *ngIf="this.type === 'off'" class="desc-h text-light-purple">{{"dashboard.fleet.velocity" | translate}}: <label class="text-white">{{fleet.speedMin}} {{"dashboard.war.mil_km/h" | translate}}</label></label>
      <label *ngIf="this.type === 'off'" class="desc-h text-light-purple">{{"dashboard.fleet.fuel" | translate}}: <label class="text-white">~{{fleet.totalFuelCons | currency: '':'':'1.2'}} {{"dashboard.war.mil_km/hydro" | translate}}</label></label>
      <label *ngIf="this.type === 'off'" class="desc-h text-light-purple">{{"dashboard.fleet.hold" | translate}}: <label class="text-white">{{fleet.totalHold  | currency: '':'':'1.2'}} {{"dashboard.ships.fdc" | translate}}</label></label>
    </div>
    <div class="flex flex-col w-full py-2 sm:p-4" >
      <div *ngIf="maxSizeFleet" class="flex flex-col w-full gap-3">
        <div class="flex flex-row w-full items-center justify-between">
          <label class="desc-h text-white">{{"dashboard.fleet.size" | translate}}</label>
          <label class="desc-h text-white">{{sumShips}}/{{maxSizeFleet}}</label>
        </div>
        <mat-progress-bar color="primary" mode="determinate" value="{{sumShips*2}}" class="!w-full ml-0 mr-auto"></mat-progress-bar>
      </div>
      <label *ngIf="type === 'def' && isPlanetInPhaseReveal" class="desc-h text-red my-2">{{"notification.no_edit_battle_reveal" | translate}}</label>
      <label *ngIf="type === 'off' && !buildFleetAtt" class="desc-h text-red my-2">{{"dashboard.fleet.no_build_att" | translate}}</label>
      <label *ngIf="type === 'def' && !buildFleetDef" class="desc-h text-red my-2">{{"dashboard.fleet.no_build_def" | translate}}</label>
      <div class="flex flex-row w-full items-center justify-center gap-8 mt-5">
        <button  class="button !m-0 text-white bg-purple" (click)="cancelUpdateFleet()">{{"dashboard.fleet.cancel" | translate}}</button>
        <button *ngIf="!loadingBuild && type === 'off'" [disabled]="sumShips <=0 || sumShips > maxSizeFleet || !buildFleetAtt"  [class]="sumShips <=0 || sumShips > maxSizeFleet || !buildFleetAtt ? 'button !m-0 text-white bg-dark-black opacity-60' :'button !m-0 text-white bg-purple'" (click)="buildFleet('buildFleetOff')">{{"dashboard.fleet.confirm" | translate}}</button>
        <button *ngIf="!loadingBuild && type === 'def'" [disabled]="sumShips <=0 || sumShips > maxSizeFleet || !buildFleetDef || isPlanetInPhaseReveal"  [class]="sumShips <=0 || sumShips > maxSizeFleet || !buildFleetDef || isPlanetInPhaseReveal ? 'button !m-0 text-white bg-dark-black opacity-60' :'button !m-0 text-white bg-purple'" (click)="buildFleet('buildFleetDef')">{{"dashboard.fleet.confirm" | translate}}</button>
        <button *ngIf="loadingBuild" class="button !m-0 text-white bg-purple flex items-center justify-center" ><mat-spinner [diameter]="20"></mat-spinner></button>
      </div>
    </div>
  </div>
</div>
