import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {NotifierService} from "../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {PlanetService} from "../../../../../core/services/planet.service";
const nameABI = require('src/app/core/abi/AstraPlanetName.json');

@Component({
  selector: 'app-dialog-change-name',
  templateUrl: './dialog-change-name.component.html',
  styleUrls: ['./dialog-change-name.component.scss']
})
export class DialogChangeNameComponent implements OnInit{
  nameForm: FormGroup = new FormGroup({});
  planet:any;
  id:any;
  loadChangeName:boolean=false;
  civilizationId:any;
  address:any;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogChangeNameComponent>,
    private _formBuilder: FormBuilder,
    private _contractService: ContractService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _planetService: PlanetService,
    @Inject(MAT_DIALOG_DATA) private data: DialogChangeNameComponent
  ) {}

  ngOnInit(){
    this.nameForm = this._formBuilder.group({
      name: ['', Validators.required],
    });
    this.planet = this.data.planet;
    this.id = this.data.id;
    this.nameForm.get('name')?.setValue(this.planet.name)
    this.civilizationId = this.planet.civilization;
  }
  async confirmShips() {
    const checkNetwork = await this._planetService.checkNetwork();
    if (checkNetwork) {
      return
    }
    this.loadChangeName = true;
    this._contractService.writeContract(environment.astraPlanetName, nameABI, 'setName', [this.id, this.nameForm.get('name')?.value])
      .then(async (chargeProject) => {
        const charge = await chargeProject.wait();
        if (charge) {
          this.loadChangeName = false;
          this._notifierService.showNotificationSuccess(this._translateService.instant('notification.name_changed'))
          this._dialogRef.close({data: this.nameForm.get('name')?.value})
        }
      })
      .catch((err) => {
        this.loadChangeName = false;
        const error = JSON.stringify(err)
        const data = JSON.parse(error)
        if (data.reason.includes('new name is the same of the old name')) {
          this._notifierService.showNotificationError(this._translateService.instant('notification.same_name'))
        } else if (data.reason.includes('name already set by this address')) {
          this._notifierService.showNotificationError(this._translateService.instant('notification.name_already_changed'))
        } else if (data.reason.includes('name already used')) {
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_mint_name'))
        } else {
          this._notifierService.showNotificationError(this._translateService.instant('notification.change_name'))
        }
      });
  }
}
