import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ethers} from "ethers";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import {PRIMARY_NETWORK} from "../utils/constant";
import {NetworkService} from "@scalingparrots/dapp-angular-lib";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class PlanetService {
  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _networkService: NetworkService,
    private _translateService: TranslateService
  ) {
  }
  private baseUrl = environment.baseUrl;
  private _allPlanet = new BehaviorSubject<any[]>([]);
  private _balance  = new BehaviorSubject<any>([]);
  private _globalInfo  = new BehaviorSubject<any>([]);

  setAllPlanet(planet: any) {
    this._allPlanet.next(planet);
  }
  setBalance(balance: any) {
    this._balance.next(balance);
  }
  setGlobalInfo(info: any) {
    this._globalInfo.next(info);
  }
  getPlanet() {
    return this._allPlanet.asObservable();
  }
  getBalance() {
    return this._balance.asObservable();
  }
  getGlobalInfo() {
    return this._globalInfo.asObservable();
  }
  public getUserInfo(user_address: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getUserInfo`, {
      params:new HttpParams().set('user_address', user_address)
    });
  }
  public readBalance(user_address: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/readBalance`, {
      params:new HttpParams().set('user_address', user_address)
    });
  }

  public getGlobalInfoPlanet(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getGlobalInfo`,{});
  }
  public getSummaryInfo(planet_id: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getSummaryInfo`, {
      params:new HttpParams().set('planet_id', planet_id)
    });
  }

  public getInfoPlanetStructure(planet_id: any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoPlanetStructure`, {
      params:new HttpParams().set('planet_id', planet_id)
    });
  }
  public getInfoPlanetShip(planet_id: any,user_address:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoPlanetShip`, {
      params:new HttpParams().set('planet_id', planet_id).set('user_address', user_address)
    });
  }
  public getInfoPlanetFleet(planet_id: any,user_address:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoPlanetFleet`, {
      params:new HttpParams().set('planet_id', planet_id).set('user_address', user_address)
    });
  }
  public getMintPlanetInfo(user_address:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getMintPlanetInfo`, {
      params:new HttpParams().set('user_address', user_address)
    });
  }
  public getInfoPlanetBattle(planet_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoPlanetBattle`, {
      params:new HttpParams().set('planet_id', planet_id)
    });
  }
  public getBattleListRevealedOldOff(planet_id:any,from:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getBattleListRevealedOldOff`, {
      params:new HttpParams().set('planet_id', planet_id).set('from', from)
    });
  }
  public getBattleListRevealedOldDef(planet_id:any,from:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getBattleListRevealedOldDef`, {
      params:new HttpParams().set('planet_id', planet_id).set('from', from)
    });
  }
  public getBattleListRevokedOldOff(planet_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getBattleListRevokedOldOff`, {
      params:new HttpParams().set('planet_id', planet_id)
    });
  }
  public getBattleListRevokedOldDef(planet_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getBattleListRevokedOldDef`, {
      params:new HttpParams().set('planet_id', planet_id)
    });
  }
  public getBattleOld(battle_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getBattleOld`, {
      params:new HttpParams().set('battle_id', battle_id)
    });
  }

  public getInfoPlanetToAttack(planet_def:any,planet_off:any,): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoPlanetToAttack`, {
      params:new HttpParams().set('planet_def', planet_def).set('planet_off', planet_off)
    });
  }


  public getPreviewBattleById(battle_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getPreviewBattleById`, {
      params:new HttpParams().set('battle_id', battle_id)
    });
  }

  public getInfoFarming(planet_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoFarming`, {
      params:new HttpParams().set('planet_id', planet_id)
    });
  }

  public getInfoPlanetFarming(planet_id:any,user_address:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoPlanetFarming`, {
      params:new HttpParams().set('planet_id', planet_id).set('user_address', user_address)
    });
  }

  public getInfoPlanetScore(planet_id:any,timestamp:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoPlanetScore`, {
      params:new HttpParams().set('planet_id', planet_id).set('timestamp', timestamp)
    });
  }

  public getRankingOff(round_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getRankingOff`, {
      params:new HttpParams().set('round_id', round_id)
    });
  }

  public getRankingDef(round_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getRankingDef`, {
      params:new HttpParams().set('round_id', round_id)
    });
  }

  public getRanking(round_id:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getRanking`, {
      params:new HttpParams().set('round_id', round_id)
    });
  }

  public getInfoMap(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getInfoMap`, {});
  }
  public getAllCoordinate(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getAllCoordinate`, {});
  }
  public getMintedCoordinate(): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getMintedCoordinate`, {});
  }
  public checkName(name:string): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/checkName`, {
      params:new HttpParams().set('name', name)
    });
  }

  public getPreviewBattle(planet_id_off:any,planet_id_def:any,index_fleet:any): Observable<any> {
    return this._http.get<any>(`${this.baseUrl}/getPreviewBattle`, {
      params:new HttpParams().set('planet_id_off', planet_id_off).set('planet_id_def', planet_id_def).set('index_fleet', index_fleet)
    });
  }

  async getPermitSignature(signer: any, nonces:any,addressToVerify:string,addressSpender:string, value: any, deadline: any, tokenName: any,) {
    const [nonce, name, version, chainId] = await Promise.all([nonces, tokenName, '1', await signer.getChainId(),])

    return ethers.utils.splitSignature(
      await signer._signTypedData(
        {name, version, chainId, verifyingContract: addressToVerify,},
        {Permit: [{name: "owner", type: "address",}, {name: "spender", type: "address",}, {name: "value", type: "uint256",}, {name: "nonce", type: "uint256",}, {name: "deadline", type: "uint256",},],},
        {owner: signer._address, spender: addressSpender, value, nonce, deadline,}
      )
    )
  }
  async updateGlobalInfo() {
    this.getGlobalInfoPlanet().subscribe({
      next:(res)=>{
        const global = {
          initialShipMint: res.initialShipMint,
          costUpdateBuilding: res.costUpdateBuilding,
          maxSizeFleet: res.maxSizeFleet,
          maxLevelPlanet: res.maxLevelPlanet,
          powerLevelBuilding: res.powerLevelBuilding,
          mintPrice: res.mintPrice / 10 ** 18,
        }
        this.setGlobalInfo(global)
      }
    })
  }
  async updatePlanet(address:string,name:string) {
    this.getUserInfo(address).subscribe({
      next:(info)=>{
        if(name !== ''){
          localStorage.setItem(address+'planetSelected',name)
          this.setAllPlanet(info.infoPlanets);
        }
        const planetSelected =  localStorage.getItem(address+'planetSelected')
        if(planetSelected !== ''){
          this.setAllPlanet(info.infoPlanets);
        }else{
          this.setAllPlanet(info.infoPlanets);
          localStorage.setItem(address+'planetSelected',info.infoPlanets[0].name)
        }
        this.readBalance(address).subscribe({
          next:(res)=>{
            const balance = {
              balanceHYD: res.balanceHYD / 10 ** res.decimalsHYD,
              balanceFDC: res.balanceFDC / 10 ** res.decimalsFDC,
              decimalsFDC: res.decimalsFDC,
              decimalsHYD: res.decimalsHYD,
            }
            this.setBalance(balance)
          }
        })
        const path = this._router.url.split('/');
        if(path[0] === '' && path[1] !== 'dashboard'){
          this._router.navigate(['/dashboard'])
        }
      },
      error:(err)=>{
      }
    })
  }
  async checkNetwork() {
    await this._networkService.checkNetwork(PRIMARY_NETWORK)
    const chainId = await window.ethereum.request({method: 'eth_chainId'});
    const binanceTestChainId = this._networkService._globalVariables.requiredNetwork.chainId
    return chainId !== binanceTestChainId
  }

  createShips(ships:any){
    let createShips = []
    for(let i =0; i < ships.length; i ++){
      const infoShips = {
        imageURL : 'assets/images/ships/'+i+'.png',
        desc : this._translateService.instant('dashboard.ships.'+ ships[i].metadata.name.toLowerCase()),
        metadata:{
          att:ships[i].metadata.att,
          def:ships[i].metadata.def,
          kmHydro:ships[i].metadata.kmHydro,
          hold:ships[i].metadata.hold,
          name:ships[i].metadata.name,
          resistance:ships[i].metadata.resistance,
          size:ships[i].metadata.size,
          speed:ships[i].metadata.speed,
        },
        mintInfo:{
          civId:ships[i].mintInfo.civId,
          fdcPrice:ships[i].mintInfo.fdcPrice,
          level:ships[i].mintInfo.level,
          maxSupply:ships[i].mintInfo.maxSupply,
          timeBuild:ships[i].mintInfo.timeBuild,
          timeBuildPlanet:ships[i].mintInfo.timeBuildPlanet,
          uri:ships[i].mintInfo.uri,
        }
      }
      createShips.push(infoShips)
    }
    return createShips
  }

  formatBigNumber(number:any){
    let fuelConsTravelBigN = ethers.utils.formatEther(number.toLocaleString("fullwide", {useGrouping:false}))
    return Number(fuelConsTravelBigN)
  }
}
