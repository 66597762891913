<div class="flex flex-col justify-center gap-4 p-4 sm:p-5 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label *ngIf="!mysteryToReveal" class="subtitle text-light-gray">{{'dialog.claim_plan_myst.title_mystery'|translate}}</label>
    <label *ngIf="mysteryToReveal" class="subtitle text-light-gray">{{'dialog.claim_plan_myst.title_reveal'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <div class="flex flex-col w-full px-2 sm:px-4" >
    <div *ngIf="!mysteryToReveal" class="flex flex-col w-full gap-3 overflow-x-auto h-52">
      <table class="w-full max-w-7xl py-4 text-sm text-left">
        <thead class="uppercase bg-transparent">
        <tr>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.claim_plan_myst.type" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.claim_plan_myst.id" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.claim_plan_myst.select" | translate}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let mystery of oldMystery; let i = index" [class]="'bg-transparent border-b-white border-b-2'">
          <th class="p-2">
            <img *ngIf="mystery.rarity === 0" src="assets/images/mystery_box/epic.png" alt="" class="w-16"/>
            <img *ngIf="mystery.rarity === 1" src="assets/images/mystery_box/rare.png" alt="" class="w-16"/>
            <img *ngIf="mystery.rarity === 2" src="assets/images/mystery_box/common.png" alt="" class="w-16"/>
          </th>
          <td class="p-2 subtitle text-white">
            <label *ngIf="mystery.rarity === 0 ">Epic</label>
            <label *ngIf="mystery.rarity === 1 ">Rare</label>
            <label *ngIf="mystery.rarity === 2 ">Common</label>
          </td>
          <td class="p-2 subtitle text-white">
            <label>Id: {{mystery.id}}</label>
          </td>
          <td class="p-2">
            <button *ngIf="!loadingClaim || mysteryId !== mystery.id" [disabled]="loadingClaim" [class]="loadingClaim && mysteryId !== mystery.id ? 'text-white bg-dark-black opacity-6 w-24 rounded-lg py-1 px-6 subtitle outline-none' : 'bg-purple w-24 text-white rounded-lg py-1 px-6 subtitle outline-none'" (click)="claimMysteryBox(mystery)">{{"dialog.claim_plan_myst.claim" | translate}}</button>
            <button *ngIf="loadingClaim && mysteryId === mystery.id" class="bg-purple w-24 text-white rounded-lg py-1 px-6 subtitle outline-none flex items-center justify-center ">
              <mat-spinner [diameter]="20"></mat-spinner>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="mysteryToReveal" class="flex flex-col w-full gap-3 items-center justify-center  h-52">
      <img src="assets/images/mystery_box/epic.png" alt="" class="w-36 grayscale"/>
      <button *ngIf="!loading" class="button bg-purple text-light-purple outline-none !m-0" (click)="revealMysteryBox()">{{"dialog.claim_plan_myst.reveal" | translate}}</button>
      <button *ngIf="loading" class="button bg-purple text-white flex items-center justify-center !m-0">
        <mat-spinner [diameter]="20"></mat-spinner>
      </button>
    </div>
  </div>
</div>
