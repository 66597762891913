<div class="flex flex-col gap-8 p-5 text-center ">
  <h2 class="text-2xl font-primary title text-white">{{"connect.title" | translate}}</h2>
  <div class="mb-6" *ngIf="getGlobalVariables().metaMaskExtProvider">
    <button (click)="connectWallet('metamask')" class="flex gap-4 w-1/2 px-8 mx-auto h-12 bg-secondary p-2 text-white rounded-md items-center outline-none">
      <app-image src="/wallet/metamask.png" class="w-8"></app-image>
      <span *ngIf="!loadMetamask" class="text-sm desc-h">{{"connect.metamask" | translate}}</span>
      <mat-spinner *ngIf="loadMetamask" [diameter]="20" class="!mx-auto"></mat-spinner>
    </button>
  </div>
  <div class="mb-6" *ngIf="getGlobalVariables().walletConnectProvider">
    <button (click)="connectWallet('walletConnect')"  class="flex gap-4 w-1/2 px-8 mx-auto h-12 bg-secondary p-2 text-white rounded-md items-center outline-none">
      <app-image src="/wallet/wallet-connect.svg" class="w-8"></app-image>
      <span *ngIf="!loadWalletConnect"  class="text-sm desc-h">{{"connect.wallet_connect" | translate}}</span>
      <mat-spinner *ngIf="loadWalletConnect" [diameter]="20" class="!mx-auto"></mat-spinner>
    </button>
  </div>
  <a href="https://metamask.io/" target="_blank" class="text-xs underline link text-white">{{"connect.install_metamask" | translate}}</a>
</div>
