<div class="flex flex-row container_background gap-4 xl:gap-8 p-8 !overflow-hidden !overscroll-none "  [ngStyle]="{background: 'url(../../../../assets/images/landing/background.png)', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center center' }">
  <div class="hidden md:flex flex-col w-44 lg:w-80 xl:w-full max-w-md" >
    <img @toLeft src="assets/images/landing/ships_left_1.png" class="absolute w-full md:max-w-xs 2xl:max-w-md image_left mb-32" alt=""/>
  </div>
  <div class="flex flex-col items-center gap-10 sm:gap-8 w-full md:max-w-4xl !mx-auto" >
    <div class="flex flex-col items-center mt-72 mb-12 text-center gap-4 sm:gap-8">
      <label class="title_page text-red mb-10">{{"landing.under_maintenance" | translate}}</label>
      <label class="subtitle text-white">{{"landing.title" | translate}}</label>
      <div class="flex flex-col w-full gap-6">
        <div class="flex flex-row items-center justify-center gap-5">
          <button (click)="openForm()" class="connect-btn bg-light-purple text-white hover:scale-110 transition-transform duration-700">
            <span>{{"landing.compile" | translate}}</span>
          </button>
          <button class="!cursor-auto connect-btn bg-dark-black opacity-80 text-white hover:scale-110 transition-transform duration-700">
            <span>{{"landing.beta" | translate}}</span>
          </button>
        </div>
        <a *ngIf="lang === 'it'" class="subtitle text-white underline cursor-pointer" [href]="'assets/pdf/whitePaperIT.pdf'" target="_blank" >{{"landing.whitePaper" | translate}}</a>
        <a *ngIf="lang === 'en'" class="subtitle text-white underline cursor-pointer" [href]="'assets/pdf/whitePaperEN.pdf'" target="_blank" >{{"landing.whitePaper" | translate}}</a>
        <button [matMenuTriggerFor]="menu" class="styling_select !mx-auto">
          <label class="desc_planet mr-auto ml-0">{{"footer." + lang| translate}}</label>
        </button>
        <mat-menu #menu="matMenu" class="flex flex-col !bg-light-purple !rounded-none !w-32 !mt-2">
          <div mat-menu-item (click)="setLang('it')">
            <div class="flex !flex-row items-center gap-2" >
              <span class="desc_planet">{{"footer.it" | translate}}</span>
            </div>
          </div>
          <div mat-menu-item (click)="setLang('en')">
            <div class="flex !flex-row items-center gap-2" >
              <span class="desc_planet">{{"footer.en" | translate}}</span>
            </div>
          </div>
        </mat-menu>
        <div class="flex flex-row items-center justify-center gap-5">
          <app-image src="/social/x.png" class="w-5 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openLink('https://twitter.com/AstraFI_')"></app-image>
          <app-image src="/social/instagram.svg" class="w-5 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openLink('https://instagram.com/astrafinance_')"></app-image>
          <app-image src="/social/telegram.svg" class="w-5 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openTelegram()"></app-image>
          <app-image src="/social/discord.svg" class="w-5 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openLink('https://discord.gg/eXkD3ZsyyZ')"></app-image>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center">
        <label class="subtitle text-white"> <label class="title text-light-purple">{{"landing.2000$" | translate}} </label> {{"landing.prize" | translate}}<br>
          {{"landing.coast" | translate}}</label>
      </div>
      <div class="flex flex-row items-center justify-center gap-5">
        <img src="assets/images/icon_token/polygon-complete-logo.png" alt="" class="w-24 cursor-pointer" (click)="openLink('https://polygon.technology/')">
        <img src="assets/images/icon_token/chainlink-complete-logo.svg" alt="" class="w-24 cursor-pointer" (click)="openLink('https://chain.link/')">
      </div>
    </div>
  </div>
  <div class="hidden md:flex flex-col w-44 lg:w-80 xl:w-full  max-w-md" >
    <img @toRight src="assets/images/landing/ships_right_1.png" class="absolute w-full md:max-w-xs 2xl:max-w-md image_right" alt=""/>
  </div>
</div>
