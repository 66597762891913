<div class="flex flex-col justify-center gap-6 p-4 sm:p-8 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="title text-white">{{'dialog.withdraw.title'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <label class="subtitle text-center text-light-gray">{{'dialog.withdraw.desc'|translate}}</label>
  <form
    [formGroup]="amountForm"
    (ngSubmit)="confirmShips()"
    class="flex flex-col w-full items-start gap-2"
  >
    <div class="flex flex-row w-full max-w-sm mx-auto items-center justify-start gap-2 bg-light-black text-white p-2  h-9">
      <input class="bg-transparent p-2 w-full outline-0" type="number" [placeholder]="'FDC'" formControlName="amount"/>
      <div class="w-10 bg-light-purple px-2 py-1 text-white desc-s cursor-pointer" (click)="setMax()">Max</div>
    </div>
    <div class="flex flex-row w-full max-w-md gap-2 items-center justify-around mt-5 !mx-auto">
      <button class="button !m-0 text-white bg-dark-black cursor-pointer"  type="reset" (click)="_dialog.closeAll()">{{'dialog.withdraw.cancel'|translate}}</button>
      <button [disabled]="this.amountForm.get('amount')?.value === 0 || this.amountForm.get('amount')?.value > max" [class]="this.amountForm.get('amount')?.value > 0 && this.amountForm.get('amount')?.value <= max ? 'button !m-0 text-white bg-purple cursor-pointer':'button !m-0 text-white bg-dark-black opacity-60 '" type="submit">{{'dialog.withdraw.confirm'|translate}}</button>
    </div>
  </form>
</div>
