import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-timer-countdown',
  templateUrl: './timer-countdown.component.html',
  styleUrls: ['./timer-countdown.component.scss']
})
export class TimerCountdownComponent implements OnInit{
  @Input() time:string='';
  @Input() class:string='';
  timer:string='';

  constructor() {
  }

  ngOnInit(): void {
    setInterval(async () => {
      this.timer = await this.getBlockTime(this.time)
    },1000)
  }

  async getBlockTime(timestampToEnd: any) {
    const timestamp = Math.floor(Date.now()/1000);
    const timeRemainingInSeconds = timestamp - timestampToEnd;
    let approximateTime :any;
    if(timeRemainingInSeconds > 0){
      let daysRemaining :any;
      let hoursRemaining :any;
      let minutesRemaining:any;
      let secondsRemaining :any;
      // Calculate the time remaining in seconds
      daysRemaining = Math.floor(timeRemainingInSeconds / (3600 * 24));
      hoursRemaining = Math.floor(timeRemainingInSeconds % (3600 * 24) / 3600);
      minutesRemaining = Math.floor((timeRemainingInSeconds % 3600) / 60);
      secondsRemaining = timeRemainingInSeconds % 60;
      if(daysRemaining > 0 ){
        approximateTime = daysRemaining + 'd : ' + hoursRemaining + 'h : ' + minutesRemaining + 'm : ' + secondsRemaining + 's'
      } else if(hoursRemaining > 0){
        approximateTime = hoursRemaining + 'h : ' + minutesRemaining + 'm : ' + secondsRemaining + 's'
      } else {
        approximateTime =  minutesRemaining + 'm : ' + secondsRemaining + 's'
      }
    }
    return  approximateTime
  }
}
