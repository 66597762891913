import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {GlobalVariables, WalletService} from "@scalingparrots/dapp-angular-lib";
import {AccountService} from "../../core/services/account.service";
import {Router} from "@angular/router";
import {PlanetService} from "../../core/services/planet.service";
import {NotifierService} from "../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  walletAddress:string = '';
  isOpened:boolean = false;
  loading:boolean = false;
  attackUpComing:boolean = false;
  path:string='';
  pathForBg:string='';
  addressHeader:string='';
  address:string='';
  newAddress:string='';
  balance:any;
  planets:any;
  planetSelected:any;
  planetAttacked:any;
  arrayStructure:any;
  arrayShips:any;
  @ViewChild('sidenavMain') sidenavMain: ElementRef | any;
  constructor(
    public dialog: MatDialog,
    private _walletService: WalletService,
    private _accountService: AccountService,
    private _planetService: PlanetService,
    private _router: Router,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
  ) {}
  ngOnInit(): void {
    this.loading = true;
    this.isOpened = window.innerWidth >= 1024
    setInterval(() => {
      const path = this._router.url.split('/');
      if(this.pathForBg !== path[2]){
        this.pathForBg = path[2]
      }
    },100);
    setInterval(() => {
      this.getProvider();
      this.walletAddress = this.getGlobalVariables().wallet.addressShort;
      this.addressHeader = this.getGlobalVariables().wallet.address;
      this._accountService.setWalletAddress(this.getGlobalVariables().wallet);
      this.getData()
    }, 2000);
  }

  setPlanet(e:Event){
    this.planetSelected = e;
  }

  checkPlanetsAttack(planets:any){
    if(planets && planets.length >0){
      const plan = planets.filter((data:any)=>data.attackUpComing)
      this.attackUpComing = !!(plan && plan.length > 0);
      this.planetAttacked = plan
    }
  }

  getData(){
    this._accountService.getWalletAddress().subscribe({
      next:async (account) => {
        const path = this._router.url.split('/');
        if (account  && (this.newAddress === '' || account.address !== this.newAddress)) {
          this.newAddress = account.address;
          this._planetService.getUserInfo(account.address).subscribe({
             next:async (res) => {
               if(res){
                 if (this.address !== '' && this.newAddress !== this.address) {
                   if (res && res.infoPlanets !== null) {
                     this._router.navigate(['/dashboard/summary'])
                   } else {
                     this._router.navigate(['/dashboard/planet'])
                   }
                 }
                 this.updateData(res,account)
                 this.checkPlanetsAttack(res.infoPlanets)
               }
             },
             error:(err)=>{
             }
           })
        } else if(this.path !== path[2]){
          this.path = path[2];
          if(account && account.address !== ''){
            this._planetService.getUserInfo(account.address).subscribe({
              next:(res)=>{
                this.checkPlanetsAttack(res.infoPlanets)
              },
              error:(err)=>{
              }
            })
          }
        }
      }
    })
  }

  updateData(res:any,account:any){
    this.address = account.address;
    let planetSelected = localStorage.getItem(this.address + 'planetSelected')
    if (res.infoPlanets !== null) {
      if (planetSelected !== '' && planetSelected !== null) {
        this._planetService.setAllPlanet(res.infoPlanets);
        this.planets = res.infoPlanets;
        const planet = this.planets.filter((data:any) => data.name === planetSelected)
        if (planet && planet.length > 0) {
          this.planetSelected = planet[0]
        } else {
          localStorage.setItem(this.address + 'planetSelected', res.infoPlanets[0].name)
          this.planetSelected = res.infoPlanets[0]
        }
      } else {
        this._planetService.setAllPlanet(res.infoPlanets);
        this.planets = res.infoPlanets;
        localStorage.setItem(this.address + 'planetSelected', res.infoPlanets[0].name)
        this.planetSelected = res.infoPlanets[0]
      }
    } else {
      this._planetService.setAllPlanet(res.infoPlanets);
      this.planets = [];
      this.planetSelected = []
    }
    this.checkUpdate()
    const id = this.planetSelected && this.planetSelected.id ? this.planetSelected.id : 0
    this._planetService.getSummaryInfo(id).subscribe({
      next: (info) => {
        if (info) {
          this.getStructureInUpdate(info.availabilityBuilding);
          this.getShipsInUpdate(info.buildShip);
        }
      }
    })
    this._planetService.readBalance(account.address).subscribe({
      next:(info)=>{
        this.loading = false;
        const balance = {
          balanceHYD: info.balanceHYD / 10 ** info.decimalsHYD,
          balanceFDC: info.balanceFDC / 10 ** info.decimalsFDC,
          decimalsFDC: info.decimalsFDC,
          decimalsHYD: info.decimalsHYD,
        }
        this._planetService.setBalance(balance)
      }
    })
  }
  async getStructureInUpdate(info:any) {
    const allStructures = []
    const timestamp = Math.floor(Date.now()/1000);
    if (timestamp < info.laboratory) {
      const structure = {name: 'laboratory', time:info.laboratory}
      allStructures.push(structure)
    }
    if (timestamp < info.hangar) {
      const structure = {name: 'hangar', time:info.hangar}
      allStructures.push(structure)
    }
    if (timestamp < info.armory) {
      const structure = {name: 'armory', time:info.armory}
      allStructures.push(structure)
    }
    if (timestamp < info.shield) {
      const structure = {name: 'shield', time:info.shield}
      allStructures.push(structure)
    }
    if(allStructures.length > 0){
      this.arrayStructure = allStructures
    } else {
      this.arrayStructure = []
    }
  }

  async getShipsInUpdate(info: any) {
    const shipsQueue: any[] = [];
    const arrayOfShips: any[] = [];
    if(info && info.length > 0){
      for (let i = 0 ; i < info.length; i++) {
        const data = {
          listAmount:info[i].listAmount,
          availability:info[i].availability,
          claimed:info[i].claimed,
          planetId:info[i].planetId,
          listType:info[i].listType,
          index:info[i].index,
        }
        shipsQueue.push(data)
      }
      if(shipsQueue && shipsQueue.length >0){
        for(let i = 0; i < shipsQueue.length; i ++){
          let sum = 0;
          for(let amount of shipsQueue[i].listAmount){
            sum = sum + Number(amount)
          }
          const allShips = {
            amounts: shipsQueue[i].listAmount,
            type: shipsQueue[i].listType,
          }
          arrayOfShips.push({index:+shipsQueue[i].index,amount: sum,endUpdateAllShips:+shipsQueue[i].availability,endUpdateShips:+shipsQueue[i].availability,ships:allShips})
        }
        this.arrayShips = arrayOfShips
      } else {
        this.arrayShips = arrayOfShips
      }
    }else {
      this.arrayShips = arrayOfShips
    }
  }

  checkUpdate(){
    setInterval(async () => {
      if((this.arrayStructure && this.arrayStructure.length >0) || (this.arrayShips && this.arrayShips.length >0)){
        const dateNow = Math.floor(Date.now()/1000);
        for(let i = 0; i < this.arrayShips.length; i++){
          if(dateNow > this.arrayShips[i].endUpdateShips){
            this.arrayShips.splice(i,1)
          }
        }
        for(let i = 0; i < this.arrayStructure.length; i++){
          if(dateNow > this.arrayStructure[i].time){
            if(this.arrayStructure[i].name === 'armory'){
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.finish_armory'))
            } else if(this.arrayStructure[i].name === 'laboratory'){
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.finish_laboratory'))
            } else if(this.arrayStructure[i].name === 'shield'){
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.finish_shield'))
            } else if(this.arrayStructure[i].name === 'hangar'){
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.finish_hangar'))
            }
            this.arrayStructure.splice(i,1)
          }
        }
      }
    },500)
  }

  async getProvider(): Promise<void> {
    await this._walletService.getWebProvider();
  }

  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }

}
