<div class="flex flex-col justify-center gap-6 p-4 sm:p-8 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="title text-white">{{'dashboard.mint.title_confirm_civilization'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <label class="subtitle text-start text-light-gray">{{'dashboard.mint.desc_end'|translate}}</label>
  <div class="flex flex-row w-full max-w-md gap-2 items-center justify-around mt-5 !mx-auto">
    <button class="button !m-0 text-white bg-dark-black cursor-pointer"  type="reset" (click)="_dialog.closeAll()">{{'dialog.buy_ships.cancel'|translate}}</button>
    <button [class]="'button !m-0 text-white bg-purple cursor-pointer'" (click)="confirmShips()">{{'dialog.buy_ships.confirm'|translate}}</button>
  </div>
</div>
