<div class="flex flex-col justify-center gap-4 p-4 sm:p-5 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="subtitle md:title text-white">{{'dialog.mint_ships.title'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <div class="flex flex-col w-full sm:px-4 mt-4" >
    <div class="flex flex-col w-full gap-3 mt-5 overflow-x-auto h-52">
      <table class="table-responsive w-full max-w-7xl text-sm text-left shadow-none">
        <thead class="uppercase bg-transparent">
        <tr>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.NAME" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.FDC" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.TIME" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.SELECT" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.TOTAL_SHIPS" | translate}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr class="bg-transparent">
          <td [attr.aria-label]="'dialog.mint_ships.NAME' | translate" class="p-2 subtitle text-light-purple">
            <label class="text-white">{{ship.name}}</label>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.FDC' | translate" class="p-2 subtitle text-light-purple">
            <label class="text-white">{{ship.priceFDC}}</label>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.TIME' | translate" class="p-2 subtitle text-light-purple w-36">
            <label class="text-white">{{ship.time}}</label>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.SELECT' | translate" class="p-2 subtitle text-light-purple md:!w-32">
            <form [formGroup]="amountForm"
                  class="p-2subtitle text-white flex flex-row items-center gap-2 w-24 justify-between">
              <img src="assets/images/icon/remove.svg" class="cursor-pointer w-4" alt="" (click)="addShipsToFleet('-',ship.id)">
              <input class=" bg-dark-black text-white text-center px-1 !rounded w-10 outline-0" type="number" formControlName="amount" (input)="addShipsWithAmountToFleet($event)"/>
              <img src="assets/images/icon/add.svg" class="cursor-pointer w-4" alt="" (click)="addShipsToFleet('+',ship.id)">
            </form>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.TOTAL_SHIPS' | translate" class="p-2 subtitle text-light-purple">
            <label class="text-white">{{ship.quantity}}</label>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="flex flex-col gap-2">
    <label class="subtitle text-white">{{"dialog.mint_ships.total" | translate}} {{sumShips | currency: 'FDC: ' }}</label>
    <label class="subtitle text-white">{{"dialog.mint_ships.total_time" | translate}}: {{totalTime}}</label>
    <label *ngIf="sumShips > balance" class="subtitle text-red">{{"dialog.mint_ships.no_balance" | translate}}</label>
  </div>
  <div class="flex flex-col w-full p-2 sm:p-4 gap-4" >
    <div class="flex flex-row w-full items-center justify-center gap-8 mt-5">
      <button class="button !m-0 text-white bg-purple" (click)="_dialog.closeAll()">{{"dialog.mint_ships.cancel" | translate}}</button>
      <button [disabled]="sumShips > balance || ship.size === 0" [class]="sumShips > balance  || ship.size === 0 ? 'button !m-0 text-white opacity-60 bg-dark-black' : 'button !m-0 text-white bg-purple'" (click)="confirmShips('')">{{"dialog.mint_ships."+label | translate}}</button>
    </div>
    <label *ngIf="label === 'buy'" class="subtitle cursor-pointer text-light-purple !mx-auto underline"  (click)=" (sumShips <= balance && ship.size > 0) && confirmShips('addToCart')">{{"dashboard.ships.add_to_cart" | translate}}</label>
  </div>
</div>
