<div class="flex flex-col sm:flex-row w-full footer p-5 mt-10 items-center justify-between gap-8 md:gap-0">
  <div class="flex flex-row w-full  max-w-md items-center justify-around">
    <app-image src="/social/x.png" class="w-4 md:w-4 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openLink('https://twitter.com/AstraFI_')"></app-image>
    <app-image src="/social/instagram.svg" class="w-4 md:w-4 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openLink('https://instagram.com/astrafinance_')"></app-image>
    <app-image src="/social/telegram.svg" class="w-4 md:w-4 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openTelegram()"></app-image>
    <app-image src="/social/discord.svg" class="w-4 md:w-4 cursor-pointer hover:scale-110 transition-transform duration-500" (click)="openLink('https://discord.gg/eXkD3ZsyyZ')"></app-image>
    <label class="subtitle text-white cursor-pointer" [routerLink]="'/dashboard/support'">{{"footer.support" | translate}}</label>
  </div>
  <div class="flex flex-row items-center justify-end gap-8">
    <app-image src="/logo/logo-o-viola-chiaro.png" class="w-28"></app-image>
    <div class="flex flex-col gap-4">
      <button [matMenuTriggerFor]="menu" class="styling_select">
        <label class="desc_planet mr-auto ml-0">{{"footer." + lang| translate}}</label>
      </button>
      <mat-menu #menu="matMenu" yPosition="above" class="flex flex-col !bg-light-purple !rounded-none !w-32 !mb-2">
        <div mat-menu-item (click)="setLang('it')">
          <div class="flex !flex-row items-center gap-2" >
            <span class="desc_planet">{{"footer.it" | translate}}</span>
          </div>
        </div>
        <div mat-menu-item (click)="setLang('en')">
          <div class="flex !flex-row items-center gap-2" >
            <span class="desc_planet">{{"footer.en" | translate}}</span>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
</div>
