import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ethers} from "ethers";

@Component({
  selector: 'app-dialog-confirm-mint-ships',
  templateUrl: './dialog-confirm-mint-ships.component.html',
  styleUrls: ['./dialog-confirm-mint-ships.component.scss']
})
export class DialogConfirmMintShipsComponent implements OnInit{
  ships:any[]=[];
  shipsCart:any;
  fdcTotal:any;
  timeUpdateTotal:any;
  totalTime:any='0h : 0m : 0s';
  totalTimeSec= 0;
  balance= 0;
  sumShips=0;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogConfirmMintShipsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogConfirmMintShipsComponent
  ) {}

  ngOnInit(){
    this.shipsCart = this.data.shipsCart
    for(let i = 0; i < this.shipsCart.length; i++){
      const ship ={
        approximateTime: this.shipsCart[i].ship.approximateTime,
        attack: this.shipsCart[i].ship.attack,
        civId: this.shipsCart[i].ship.civId,
        defense: this.shipsCart[i].ship.defense,
        description: this.shipsCart[i].ship.description,
        fuel: this.shipsCart[i].ship.fuel,
        hold: this.shipsCart[i].ship.hold,
        id: this.shipsCart[i].ship.id,
        level:this.shipsCart[i].ship.level,
        maxSupply:this.shipsCart[i].ship.maxSupply,
        name:this.shipsCart[i].ship.name,
        priceFDC:this.shipsCart[i].ship.priceFDC,
        quantity:this.shipsCart[i].ship.quantity,
        resistance:this.shipsCart[i].ship.resistance,
        size:this.shipsCart[i].amounts[0],
        sizeValue:this.shipsCart[i].ship.sizeValue,
        speed:this.shipsCart[i].ship.speed,
        src:this.shipsCart[i].ship.src,
        srcDisabled:this.shipsCart[i].ship.srcDisabled,
        time:this.shipsCart[i].ship.time,
        timeBuild:this.shipsCart[i].ship.timeBuild,
      }
      this.ships.push(ship)
    }
    this.balance = this.data.balance
    this.fdcTotal = ethers.BigNumber.from(0);
    this.timeUpdateTotal = ethers.BigNumber.from(0);
    this.updateCart()
  }

  addShipsToFleet(type:string,index:any){
    if(type === '+'){
      if(this.ships[index].maxSupply === 0){
        this.sumShips = this.sumShips + this.ships[index].priceFDC
        this.ships[index].size = this.ships[index].size + 1
        const timeRemainingInSeconds = (this.ships[index].size) * this.ships[index].timeBuild ;
        this.ships[index].approximateTime = Math.floor(timeRemainingInSeconds / 3600) + 'h : ' + Math.floor((timeRemainingInSeconds % 3600) / 60) + 'm : ' + timeRemainingInSeconds % 60 + 's'
        this.timeTotalUpdate()
      } else if((this.ships[index].size + this.ships[index].quantity) < this.ships[index].maxSupply){
        this.sumShips = this.sumShips + this.ships[index].priceFDC
        this.ships[index].size = this.ships[index].size + 1
        const timeRemainingInSeconds = (this.ships[index].size) * this.ships[index].timeBuild ;
        this.ships[index].approximateTime = Math.floor(timeRemainingInSeconds / 3600) + 'h : ' + Math.floor((timeRemainingInSeconds % 3600) / 60) + 'm : ' + timeRemainingInSeconds % 60 + 's'
        this.timeTotalUpdate()
      }
    }else {
      if(this.ships[index].size - 1 >= 0){
        this.sumShips = this.sumShips - this.ships[index].priceFDC
        this.ships[index].size = this.ships[index].size-1
        const timeRemainingInSeconds = (this.ships[index].size) * this.ships[index].timeBuild ;
        this.ships[index].approximateTime = Math.floor(timeRemainingInSeconds / 3600) + 'h : ' + Math.floor((timeRemainingInSeconds % 3600) / 60) + 'm : ' + timeRemainingInSeconds % 60 + 's'
        this.timeTotalUpdate()
      }
    }
  }

  removeShips(ships:any, index:any){
    ships.splice(index,1)
  }

  timeTotalUpdate(){
    let totalTime = 0;
    for(let ship of this.ships){
      totalTime = totalTime + (ship.size * ship.timeBuild)
    }
    this.totalTimeSec = totalTime;
    this.totalTime = Math.floor(totalTime / 3600) + 'h : ' + Math.floor((totalTime % 3600) / 60) + 'm : ' + totalTime % 60 + 's';
  }

  updateCart(){
    let totalTime = 0;
    let sumFDC = 0;
    for(let ship of this.ships){
      totalTime = totalTime + (ship.size * ship.timeBuild)
      sumFDC = sumFDC + (ship.size * ship.priceFDC)
    }
    this.sumShips = sumFDC
    this.totalTimeSec = totalTime;
    this.totalTime = Math.floor(totalTime / 3600) + 'h : ' + Math.floor((totalTime % 3600) / 60) + 'm : ' + totalTime % 60 + 's';
  }

  confirmShips() {
    let shipsId: any[] = [];
    let shipsAmount: any[] = [];
    let shipsTotalFDC = 0;
    let shipsTimeToNext = 0;
    let shipsTime: any[] = [];
    for(let ship of this.ships){
      if(ship.size > 0){
        shipsId.push(ship.id)
        shipsAmount.push(ship.size)
        shipsTotalFDC = shipsTotalFDC + (ship.priceFDC * ship.size)
        shipsTimeToNext = shipsTimeToNext + (ship.timeBuild * ship.size)
        shipsTime.push({time:shipsTimeToNext})
      }
    }
    if(shipsId.length >0){
      this._dialogRef.close({data:{fdcTotal:shipsTotalFDC,typeShip:shipsId,amounts:shipsAmount,timeUpdateTotal:this.totalTimeSec,arrayTimeShips:shipsTime}})
    }
  }

  closeCart(){
    this._dialogRef.close({data:'edit',ships:this.ships})
  }

  deleteShips(){
    this._dialogRef.close({data:'cancel'})
  }
}
