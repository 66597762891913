import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GlobalVariables, WalletService} from "@scalingparrots/dapp-angular-lib";
import {PlanetService} from "../../../../core/services/planet.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  @Input() drawer:any;
  @Input() walletAddress:string='';
  @Input() address:string='';
  @Input() planets:any;
  @Input() attackUpComing:any;
  @Output() PlanetSelected = new EventEmitter<any>();
  planetSelectedName:any;
  account:string='';
  path:string='';
  loading:boolean=true;
  isAttack:boolean=false;
  fdc:number=0;
  hydro:number=0;
  constructor(
    private _walletService:WalletService,
    private _planetService:PlanetService,
    private _router:Router,
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    setInterval(async () => {
      this.getAccount();
    },1000)
  }

  async getAccount() {
    if (this.account !== this.address) {
      this.updateTokenValue()
    }
    if(this.isAttack !== this.attackUpComing){
      this.isAttack = this.attackUpComing;
    }
  }

  checkPlanetsAttack(planets:any){
    const planet = planets.filter((data:any)=> data.name === this.planetSelectedName)
    const plan = planet.filter((data:any)=> data.attackUpComing)
    this.attackUpComing = !!(plan && plan.length > 0);
  }

  async updateTokenValue() {
    this.account = this.address;
    this._planetService.getBalance().subscribe({
      next:async (balance) => {
        if(balance && balance.balanceFDC >= 0){
          this.fdc = balance.balanceFDC
          this.hydro = balance.balanceHYD
        }
      }
    })
    if(localStorage.getItem(this.address + 'planetSelected')){
      this.planetSelectedName = localStorage.getItem(this.address + 'planetSelected')
      if(this.planets && this.planets[0]){
        this.checkPlanetsAttack(this.planets)
      }
    } else {
      if(this.planets && this.planets[0]){
        this.planetSelectedName = this.planets[0].name
        this.checkPlanetsAttack(this.planets)
      } else {
        this.attackUpComing = false
      }
    }
  }
  setSelect(planet:any) {
    localStorage.setItem(this.address+'planetSelected',planet.name)
    this.planetSelectedName = planet.name
    this.PlanetSelected.emit(planet);
    this.checkPlanetsAttack(this.planets)
    this._router.navigate(['/dashboard/summary'])
  }
  disconnectWallet(): void {
    if (this.getGlobalVariables().type === 'walletconnect') {
      this._walletService
        .disconnectWallet()
        .then(() => window.localStorage.setItem('connected', String(false)));
    } else {
      window.localStorage.setItem('connected', String(false));
      window.location.reload();
    }
  }


  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }

}
