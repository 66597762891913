import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../../../../../core/services/account.service";
import {PlanetService} from "../../../../../../core/services/planet.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogViewShipsMintedComponent} from "../../../../components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogDescriptionShipsComponent} from "../../../../components/dialog/dialog-description-ships/dialog-description-ships.component";
import {environment} from "../../../../../../../environments/environment";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {NotifierService} from "../../../../../../core/services/notifier.service";
const battleABI = require('src/app/core/abi/AstraBattle.json');

@Component({
  selector: 'app-war-info-def',
  templateUrl: './war-info-def.component.html',
  styleUrls: ['./war-info-def.component.scss']
})
export class WarInfoDefComponent implements OnInit{
  loading:boolean=false;
  loadReveal:boolean=false;
  loadEmergencyRevokeDef:boolean=false;
  battleOffOncoming:any[]=[];
  battleOffOld:any[]=[];
  battleDefOncoming:any[]=[];
  battleDefOld:any[]=[];
  dateNow:number = 0;
  type:string | null='def';
  sortBattle:string | null='';
  viewInfoBattle:any;
  viewAllShips:any;
  maxSizeFleet:any;
  battleID:any;
  address:any;
  myPlanets:any
  infoPlanetToAttack:any
  HYD:any;
  noPlanet:any;
  planetSelected:any;
  innerWidth:any;
  constructor(
    private _dialog: MatDialog,
    private _accountService: AccountService,
    private _planetService: PlanetService,
    private _translateService: TranslateService,
    private _contractService: ContractService,
    private _route: ActivatedRoute,
    private _notifierService: NotifierService,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.innerWidth = window.innerWidth;
    this.dateNow = Math.floor(Date.now()/1000)
    this.loading = true;
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getBalance().subscribe({
            next: (balance) => {
              if(this.HYD === undefined){
                this.HYD = balance.balanceHYD
                this.updatePlanetFleet()
                setInterval(()=>{
                  this.dateNow = Math.floor(Date.now()/1000)
                },1000)
              }
            }
          })
        }
      }
    })
  }

  updatePlanetFleet(){
    let planetSelected = localStorage.getItem(this.address+'planetSelected')
    this._planetService.getPlanet().subscribe({
      next: async (planets) => {
        if (planets && planets.length > 0) {
          this.myPlanets = planets
          const planet = planets.filter((data) => data.name === planetSelected)
          if(planet && planet.length >0){
            this.planetSelected = planet[0]
            this.type = 'def'
            this.sortBattle = this._route.snapshot.paramMap.get('sort')
            const id = this._route.snapshot.paramMap.get('id')
            this._planetService.getInfoPlanetBattle(this.planetSelected.id).subscribe({
              next: async (info) => {
                this.viewAllShips = this._planetService.createShips(info.infoShip)
                this.battleDefOncoming = this.getPlanetBattle(info.battleDefOncoming)
                this.searchBattle(id)
              }
            })
          }
        } else {
          this.noPlanet = true;
          this.loading = false;
        }
      }
    });
  }

  getPlanetBattle(battle:any){
    const battleStatus:any[] = []
    for(let i = 0; i < battle.length; i ++){
      const infoBattle = battle[i]
      let amountBurnDef = 0;
      let amountBurnOff = 0;
      if(infoBattle.fleetBurnDef !== null){
        for(let data of infoBattle.fleetBurnDef){
          for(let amounts of data.amountsBurn){
            amountBurnDef = amountBurnDef + amounts
          }
        }
      }
      if(infoBattle.fleetBurnOff !== null){
        for(let data of infoBattle.fleetBurnOff){
          for(let amounts of data.amountsBurn){
            amountBurnOff = amountBurnOff + amounts
          }
        }
      }
      const data = {
        index:i,
        id:infoBattle.id,
        speedMin:infoBattle.stats.speedMin,
        totAtt:infoBattle.stats.totAtt,
        totDef:infoBattle.stats.totDef,
        resistanceOff:infoBattle.stats.resistanceOff,
        resistanceDef:infoBattle.stats.resistanceDef,
        totHold: this._planetService.formatBigNumber(infoBattle.stats.totHold),
        fleetBurnDef: infoBattle.fleetBurnDef,
        amountsBurnDef: amountBurnDef,
        fleetBurnOff: infoBattle.fleetBurnOff,
        amountsBurnOff: amountBurnOff,
        fleetOff: infoBattle.fleet.fleetOff,
        fleetDef: infoBattle.fleet.fleetDef,
        planetIdOff:infoBattle.planetIdOff,
        planetIdDef:infoBattle.planetIdDef,
        kmHydro:infoBattle.stats.kmHydro / 10**2,
        confirmed:infoBattle.status.confirmed,
        revealed:infoBattle.status.revealed,
        revoked:infoBattle.status.revoked,
        canDefRevoke:infoBattle.canDefRevoke,
        rewardStolen: this._planetService.formatBigNumber(infoBattle.status.rewardStolen),
        startBattle:infoBattle.status.startBattle,
        returnTime:infoBattle.returnTime,
        randomNumber:infoBattle.randomNumber,
        winProbability:infoBattle.winProbability,
        battleDelay:infoBattle.battleDelay,
        isDefInPhaseReveal:infoBattle.isDefInPhaseReveal,
        infoReveal:infoBattle.infoReveal,
        planetNameDef:infoBattle.planetNameDef,
        planetNameOff:infoBattle.planetNameOff,
        timeRevealed:infoBattle.timeRevealed,
        scoreBattle:infoBattle.scoreBattle,
        deltaWin:infoBattle.deltaWin,
        offWin:infoBattle.winProbability >= infoBattle.randomNumber,
        defWin:infoBattle.winProbability < infoBattle.randomNumber,
      }
      battleStatus.push(data)
    }
    return battleStatus
  }

  searchBattle(id:any){
    const battle = this.battleDefOncoming.filter((battle)=> battle.id === Number(id))
    this.viewBattleInfo(battle[0].planetIdOff,battle[0])
  }
  cancelAttack(){
    this._router.navigate(['/dashboard/war'])
  }

  getApproximateTime(time:any){
    let approximateTime = '';
    let hoursRemaining = Math.floor(time/ 3600);
    let minutesRemaining = Math.floor((time % 3600) / 60);
    let secondsRemaining = time % 60;
    if (hoursRemaining > 0) {
      approximateTime = hoursRemaining + 'h : ' + minutesRemaining + 'm : ' + secondsRemaining + 's'
    } else {
      approximateTime = minutesRemaining + 'm : ' + secondsRemaining + 's'
    }
    return approximateTime;
  }

  viewInfoFleet(fleet:any){
    let ships :any;
    if(this.viewInfoBattle.battle.revealed){
      ships = {
        amounts: fleet.amountsRemaining, type: fleet.shipsType
      }
    }else {
      ships = {
        amounts: fleet.amounts, type: fleet.shipsType
      }
    }
    this._dialog.open(DialogViewShipsMintedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.viewAllShips},
    })
  }

  viewBattleInfo(enemyPlanetId:any,battle:any){
    let planet_def = 0;
    let planet_off = 0;
    if(this.planetSelected.id === battle.planetIdOff){
      planet_def = enemyPlanetId;
      planet_off = this.planetSelected.id;
    } else {
      planet_def = this.planetSelected.id;
      planet_off = enemyPlanetId;
    }
    this._planetService.getInfoPlanetToAttack(planet_def,planet_off).subscribe({
      next:(res)=>{
        this._planetService.getPreviewBattleById(battle.id).subscribe({
          next:(PrBatId)=>{
            this.viewInfoBattle = {
              battle : battle,
              planetIdOff : battle.planetIdOff,
              fleetOff : battle.fleetOff,
              amountHydroToConfirm : battle.battleDelay !== null ? this._planetService.formatBigNumber(battle.battleDelay.amountHydroToConfirm):0,
              planetNameOff : battle.planetNameOff,
              resistanceDef : PrBatId.battleStats.resistanceDef,
              resistanceOff : battle.resistanceOff,
              speedMin : battle.speedMin,
              maxRewardToStole : this._planetService.formatBigNumber(PrBatId.maxRewardToStole),
              deltaLevelPlanet : PrBatId.deltaLevelPlanet,
              probabilitiesToWin : battle.winProbability,
              totalAtt : battle.totAtt,
              totalDef : PrBatId.battleStats.totDef,
              totalHold : battle.totHold,
              kmHydro :  battle.kmHydro,
              estimatedTime : battle.kmHydro > 0 ? this.getApproximateTime(PrBatId.infoTravel.timeTravel) : '0h: 0m: 0s',
              totalFuelCons : battle.kmHydro > 0 ? this._planetService.formatBigNumber(PrBatId.infoTravel.fuelConsTravel) : 0,
            };
            this.dateNow = battle.kmHydro > 0 ? battle.startBattle : Math.floor(Date.now() / 1000);
            this.infoPlanetToAttack = {
              coordinate: res.coordinate,
              def: res.totalDef,
              fleetDef: res.fleetDef,
              levelShield: res.levelShield,
              percentageFeeWithdraw: res.percentageFeeWithdraw,
              deltaLevelPlanet: res.deltaLevelPlanet,
              maxRewardToStole: this._planetService.formatBigNumber(res.maxRewardToStole),
              distance: res.distance,
              name: res.planetName,
              power: res.power,
              rewardFarming: this._planetService.formatBigNumber(res.rewardFarming),
              planetsCivilizationId: res.civ,
              isProtected: res.isPlanetProtected,
            }
            this.loading = false
          }
        })
      }
    })
  }

  async revealAttack(battle:any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadReveal = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
      next: async (info) => {
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = indexBattle
        const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
        this._contractService.writeContract(environment.astraBattle, battleABI, 'revealAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadReveal = false;
              this.battleID = undefined;
              this.loading = true;
              this.infoPlanetToAttack = undefined
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reveal_confirmed'))
              setTimeout(()=>{
                this._router.navigate(['/dashboard/war'])
              },1000)
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this._notifierService.showNotificationError(this._translateService.instant('notification.wait_for_reveal'))
            this.loadReveal = false;
            this.battleID = undefined;
          });
      },
      error:()=>{
        this.loadReveal = false;
        this.battleID = undefined;
      }
    })
  }

  async emergencyRevokeAttackDef(battle: any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadEmergencyRevokeDef = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdOff).subscribe({
      next: async (info) => {
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = info.battleOffOncoming.findIndex((data: any) => data.id === battle.id)
        const indexBattleDef = indexBattle
        this._contractService.writeContract(environment.astraBattle, battleABI, 'emergencyRevokeAttack', [indexBattleOff, planetIdOff, indexBattleDef, planetIdDef])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadEmergencyRevokeDef = false;
              this.battleID = undefined;
              this.loading = true;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.revoke_confirmed'))
              setTimeout(() => {
                this.updatePlanetFleet();
              }, 1000)
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this.loadEmergencyRevokeDef = false;
            this.battleID = undefined;
          });
      },
      error: () => {
        this.loadEmergencyRevokeDef = false;
        this.battleID = undefined;
      }
    })
  }
  getDef(fleets:any){
    let defence=0;
    if(fleets){
      for(let fleet of fleets){
        defence = defence + fleet.totDef
      }
    }
    return defence
  }

  getDataTimer(timeToEnd:any):any{
    const today = new Date(timeToEnd *1000);
    let hh = today.getHours();
    let mm = today.getMinutes();
    const amOrPm = hh >= 12 ? 'PM' : 'AM';
    const formattedHours = hh % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${this.padZero(mm)} ${amOrPm}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  openDescription(key:string){
    this._dialog.open(DialogDescriptionShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:this._translateService.instant(key),
    })
  }
}
