<div class="flex flex-col-reverse md:flex-row gap-12 px-0 sm:px-2 md:pr-20 md:pl-0">
  <div class="flex flex-col w-full md:w-2/4 gap-6">
    <label class="title text-white">{{"dashboard.support.game_manual" | translate}}</label>
    <button [class]="'button !m-0 text-white bg-purple'" (click)="openGitbook('https://astra-finance-1.gitbook.io/game-rules/')">{{"dashboard.support.view" | translate}}</button>
    <label class="title text-white">{{"dashboard.support.faq" | translate}}</label>
    <div class="flex flex-col gap-6">
      <mat-expansion-panel class="!bg-transparent !shadow-none !rounded-none pb-2 border-bottom !outline-0" hideToggle="true" [expanded]="this.selected_faq === '1'">
        <mat-expansion-panel-header (click)="setFAQSelected('1')" class="!bg-transparent !outline-0">
          <mat-panel-title class="flex justify-between gap-5">
            <label class="subtitle text-white">{{"dashboard.support.title-faq-1" | translate}}</label>
            <span class="material-icons"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <label class="desc-h text-white">{{"dashboard.support.subtitle-faq-1" | translate}}</label>
      </mat-expansion-panel>
      <mat-expansion-panel class="!bg-transparent !shadow-none !rounded-none pb-2 border-bottom !outline-0" hideToggle="true" [expanded]="this.selected_faq === '2'">
        <mat-expansion-panel-header (click)="setFAQSelected('2')" class="!bg-transparent !outline-0">
          <mat-panel-title class="flex justify-between gap-5">
            <label class="subtitle text-white">{{"dashboard.support.title-faq-2" | translate}}</label>
            <span class="material-icons"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <label class="desc-h text-white">{{"dashboard.support.subtitle-faq-2" | translate}}</label>
      </mat-expansion-panel>
      <mat-expansion-panel class="!bg-transparent !shadow-none !rounded-none pb-2 border-bottom !outline-0" hideToggle="true" [expanded]="this.selected_faq === '3'">
        <mat-expansion-panel-header (click)="setFAQSelected('3')" class="!bg-transparent !outline-0">
          <mat-panel-title class="flex justify-between gap-5">
            <label class="subtitle text-white">{{"dashboard.support.title-faq-3" | translate}}</label>
            <span class="material-icons"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <label class="desc-h text-white">{{"dashboard.support.subtitle-faq-3" | translate}}</label>
      </mat-expansion-panel>
      <mat-expansion-panel class="!bg-transparent !shadow-none !rounded-none pb-2 border-bottom !outline-0" hideToggle="true" [expanded]="this.selected_faq === '4'">
        <mat-expansion-panel-header (click)="setFAQSelected('4')" class="!bg-transparent !outline-0">
          <mat-panel-title class="flex justify-between gap-5">
            <label class="subtitle text-white">{{"dashboard.support.title-faq-4" | translate}}</label>
            <span class="material-icons"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <label class="desc-h text-white">{{"dashboard.support.subtitle-faq-4" | translate}}</label>
      </mat-expansion-panel>
      <mat-expansion-panel class="!bg-transparent !shadow-none !rounded-none pb-2 border-bottom !outline-0" hideToggle="true" [expanded]="this.selected_faq === '5'">
        <mat-expansion-panel-header (click)="setFAQSelected('5')" class="!bg-transparent !outline-0">
          <mat-panel-title class="flex justify-between gap-5">
            <label class="subtitle text-white">{{"dashboard.support.title-faq-5" | translate}}</label>
            <span class="material-icons"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <label class="desc-h text-white">{{"dashboard.support.subtitle-faq-5" | translate}}</label>
      </mat-expansion-panel>
      <mat-expansion-panel class="!bg-transparent !shadow-none !rounded-none pb-2 border-bottom !outline-0" hideToggle="true" [expanded]="this.selected_faq === '6'">
        <mat-expansion-panel-header (click)="setFAQSelected('6')" class="!bg-transparent !outline-0">
          <mat-panel-title class="flex justify-between gap-5">
            <label class="subtitle text-white">{{"dashboard.support.title-faq-6" | translate}}</label>
            <span class="material-icons"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <label class="desc-h text-white">{{"dashboard.support.subtitle-faq-6" | translate}}</label>
      </mat-expansion-panel>
      <mat-expansion-panel class="!bg-transparent !shadow-none !rounded-none pb-2 border-bottom !outline-0" hideToggle="true" [expanded]="this.selected_faq === '7'">
        <mat-expansion-panel-header (click)="setFAQSelected('7')" class="!bg-transparent !outline-0">
          <mat-panel-title class="flex justify-between gap-5">
            <label class="subtitle text-white">{{"dashboard.support.title-faq-7" | translate}}</label>
            <span class="material-icons"><img src="../../../../../assets/images/icon/polygon.svg" alt=""/></span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <label class="desc-h text-white">{{"dashboard.support.subtitle-faq-7" | translate}}</label>
      </mat-expansion-panel>
    </div>
  </div>
  <div class="flex flex-col w-full md:w-2/4 gap-7">
    <label class="title text-white">{{"dashboard.support.contact" | translate}}</label>
    <label class="subtitle text-light-gray">{{"dashboard.support.mail" | translate}}</label>
  </div>
</div>
