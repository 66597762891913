import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly _router: Router,
  ) {}

  canActivate(): any{
    if (localStorage.getItem('connected') === 'false') {
      return this._router.createUrlTree(['/']);
    }
  }
}
