import {Component, OnInit} from '@angular/core';
import * as L from "leaflet";
import {PlanetService} from "../../../../core/services/planet.service";
import {AccountService} from "../../../../core/services/account.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {NotifierService} from "../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {
  DialogDescriptionShipsComponent
} from "../../components/dialog/dialog-description-ships/dialog-description-ships.component";
import {MatDialog} from "@angular/material/dialog";
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit{
  address = ''
  planetForm: FormGroup = new FormGroup({});
  myPlanetSelected:any;
  planetSelected:any;
  dateNow:number = 0;
  map:any;
  myPlanets:any;
  mapPlanets:any;
  isMyPlanet:boolean = false;

  constructor(
    private _accountService: AccountService,
    private _planetService: PlanetService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.dateNow = Math.floor(Date.now()/1000)
    this.planetForm = this._formBuilder.group({
      value: ['', Validators.required],
    });
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getPlanet().subscribe({
            next:(planets) => {
              if (planets && planets.length > 0 && this.myPlanets === undefined) {
                this.myPlanets = planets
                const planetSelectedName = localStorage.getItem(this.address+'planetSelected')
                const planet = planets.filter((data) => data.name === planetSelectedName)
                if(planet && planet.length > 0){
                  this.myPlanetSelected = planet[0]
                  this.createMap(planets)
                }
              } else {
                this.createMap([])
              }
            }
          })
        }
      }
    });
    setInterval(()=>{
      this.dateNow = Math.floor(Date.now()/1000)
    },1000)
  }

  createMap(planets:any){
    this._planetService.getMintedCoordinate().subscribe({
      next:async (mapPlanets) => {
        this.mapPlanets = mapPlanets.coordinate
        var southWest = L.latLng(-4300, 4300),
          northEast = L.latLng(4300, -4300),
          bounds = L.latLngBounds(southWest, northEast);
        var map = L.map('map', {
          attributionControl: false,
          crs: L.CRS.Simple,
          minZoom: -4,
          maxZoom: 1,
          maxBounds: bounds,
          dragging: !L.Browser.mobile,
          tap: !L.Browser.mobile
        });
        this.map = map;
        const myPlanet = L.icon({
          iconUrl: '../../../../../assets/images/map_planet/my_planet.svg',
          iconSize: [25, 25],
        });
        const enemyPlanet = L.icon({
          iconUrl: '../../../../../assets/images/map_planet/enemy_planet.svg',
          iconSize: [25, 25],
        });
        const freePlanet = L.icon({
          iconUrl: '../../../../../assets/images/map_planet/free_planet.svg',
          iconSize: [25, 25],
        });
        this.checkCoordinate(planets,freePlanet,myPlanet,enemyPlanet,map,mapPlanets.coordinate)
        this.goToMyPlanet()
      }
    })
  }

  checkCoordinate(planets:any,freePlanet:any,myPlanet:any,enemyPlanet:any,map:any,allPlanet:any){
    for (let coordinate of allPlanet) {
      let icon: any;
      if (coordinate.id === 0) {
        icon = freePlanet
      } else if (coordinate.id > 0 && planets.length > 0) {
        const isMyPlanet = planets.filter((planet: any) => planet.id === coordinate.id)
        if (isMyPlanet && isMyPlanet.length > 0) {
          icon = myPlanet
        } else {
          icon = enemyPlanet
        }
      }
      const marker = L.marker([coordinate.x, coordinate.y], {
        icon: icon,
        title: coordinate.name
      }).addTo(map).on('dblclick', (e) => {
        if (coordinate.id > 0) {
          map.setView([coordinate.x, coordinate.y],0);
          if (this.myPlanets !== undefined) {
            const planet = this.myPlanets.filter((planet: any) => planet.id === coordinate.id)
            this.isMyPlanet = !!(planet && planet.length > 0);
          }
          this.planetForm.get('value')?.setValue(coordinate.id)
          this._planetService.getInfoPlanetToAttack(coordinate.id, this.myPlanetSelected ? this.myPlanetSelected.id : 0).subscribe({
            next: (res) => {
              this.planetSelected = {
                id: coordinate.id,
                coordinate: res.coordinate,
                def: res.totalDef,
                fleetDef: res.fleetDef,
                levelShield: res.levelShield,
                percentageFeeWithdraw: res.percentageFeeWithdraw,
                name: res.planetName,
                power: res.power,
                rewardFarming: this._planetService.formatBigNumber(res.rewardFarming),
                planetsCivilizationId: res.civ,
                isProtected: res.isPlanetProtected,
                deltaLevelPlanet: res.deltaLevelPlanet,
                distance: res.distance,
                maxRewardToStole: this._planetService.formatBigNumber(res.maxRewardToStole),
                protectedTime: res.protectedTime,
              }
            }
          })
        }
      });
      map.on('dblclick', (e:any) => {
        map.setView([e.latlng.lat, e.latlng.lng],0);
      });
      marker.on('click', (e) => {
        if (coordinate.id > 0) {
          map.setView([coordinate.x, coordinate.y]);
          if (this.myPlanets !== undefined) {
            const planet = this.myPlanets.filter((planet: any) => planet.id === coordinate.id)
            this.isMyPlanet = !!(planet && planet.length > 0);
          }
          this.planetForm.get('value')?.setValue(coordinate.id)
          this._planetService.getInfoPlanetToAttack(coordinate.id, this.myPlanetSelected ? this.myPlanetSelected.id : 0).subscribe({
            next: (res) => {
              this.planetSelected = {
                id: coordinate.id,
                coordinate: res.coordinate,
                def: res.totalDef,
                fleetDef: res.fleetDef,
                levelShield: res.levelShield,
                percentageFeeWithdraw: res.percentageFeeWithdraw,
                name: res.planetName,
                power: res.power,
                rewardFarming: this._planetService.formatBigNumber(res.rewardFarming),
                planetsCivilizationId: res.civ,
                isProtected: res.isPlanetProtected,
                deltaLevelPlanet: res.deltaLevelPlanet,
                distance: res.distance,
                maxRewardToStole: this._planetService.formatBigNumber(res.maxRewardToStole),
                protectedTime: res.protectedTime,
              }
            }
          })
        }
      });
      marker.on('mouseover', () => {
        const popup = "<label class='desc-h'>" + this._translateService.instant('dashboard.map.name') + ": " + coordinate.name + "</label> <br> <label class='desc-h'>" + this._translateService.instant('dashboard.map.id') + ': ' + coordinate.id + "</label>";
        if (coordinate.name) {
          marker.bindPopup(popup)
          marker.openPopup();
        }
      });
      map.on('zoom', () => {
        const currentZoom = map.getZoom();
        let iconSize: any;
        if (currentZoom === 1) {
          iconSize = [50, 50];
        } else if (currentZoom === 0) {
          iconSize = [35, 35];
        } else if (currentZoom === -1) {
          iconSize = [25, 25];
        } else if (currentZoom === -2) {
          iconSize = [15, 15];
        } else {
          iconSize = [10, 10];
        }
        const myPlanet = L.icon({
          iconUrl: '../../../../../assets/images/map_planet/my_planet.svg',
          iconSize: iconSize,
        });
        const enemyPlanet = L.icon({
          iconUrl: '../../../../../assets/images/map_planet/enemy_planet.svg',
          iconSize: iconSize,
        });
        const freePlanet = L.icon({
          iconUrl: '../../../../../assets/images/map_planet/free_planet.svg',
          iconSize: iconSize,
        });
        if (coordinate.id === 0) {
          icon = freePlanet
        } else if (coordinate.id > 0) {
          const isMyPlanet = planets.filter((planet: any) => planet.id === coordinate.id)
          if (isMyPlanet && isMyPlanet.length > 0) {
            icon = myPlanet
          } else {
            icon = enemyPlanet
          }
        }
        marker.setIcon(icon);
      });
    }
  }

  goToMyPlanet(){
    const id = this.myPlanetSelected && this.myPlanetSelected.id ? this.myPlanetSelected.id : 0
    this.planetForm.get('value')?.setValue(id)
    this.searchPlanet()
  }

  searchPlanet(){
    let id = Number(this.planetForm.get('value')?.value)
    if(isNaN(id)){
      const planet = this.mapPlanets.filter((planet:any)=> planet.name === this.planetForm.get('value')?.value)
      if(planet.length === 0){
        this._notifierService.showNotificationError(this._translateService.instant('notification.planet_does_not_exist'))
        return
      }
      this.map.setView( [planet[0].x, planet[0].y], 0);
      id = planet[0].id
    }else {
      const planet = this.mapPlanets.filter((planet:any)=> planet.id === id)
      if(planet.length === 0){
        this._notifierService.showNotificationError(this._translateService.instant('notification.planet_does_not_exist'))
        return
      }
      this.map.setView( [planet[0].x, planet[0].y], 0);
      id = planet[0].id
    }
    if(this.myPlanets !== undefined){
      const planet = this.myPlanets.filter((planet:any)=> planet.id === id)
      this.isMyPlanet = !!(planet && planet.length > 0);
    }
    const myPlanetId = this.myPlanetSelected && this.myPlanetSelected.id ? this.myPlanetSelected.id : 0
    this._planetService.getInfoPlanetToAttack(id,myPlanetId).subscribe({
      next:(res)=>{
        this.planetSelected = {
          id: id,
          coordinate: res.coordinate,
          def: res.totalDef,
          fleetDef: res.fleetDef,
          levelShield: res.levelShield,
          percentageFeeWithdraw: res.percentageFeeWithdraw,
          name: res.planetName,
          power: res.power,
          rewardFarming: this._planetService.formatBigNumber(res.rewardFarming),
          planetsCivilizationId: res.civ,
          isProtected: res.isPlanetProtected,
          deltaLevelPlanet: res.deltaLevelPlanet,
          distance: res.distance,
          maxRewardToStole: this._planetService.formatBigNumber(res.maxRewardToStole),
          protectedTime: res.protectedTime,
        }
      }
    })
  }

  attackPlanet(id:any){
    const isMyPlanet = this.myPlanets.filter((planet:any)=> planet.id === id)
    if(isMyPlanet && isMyPlanet.length > 0){
      this._notifierService.showNotificationError(this._translateService.instant('notification.planet_owner_same'))
      return
    }
    this._router.navigate(['/dashboard/war/'+id])
  }

  openDescription(key:string){
    this._dialog.open(DialogDescriptionShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:this._translateService.instant(key),
    })
  }
}
