import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {NotifierService} from "../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {PlanetService} from "../../../../../core/services/planet.service";
const planetABI = require('src/app/core/abi/AstraPlanetManager.json');

@Component({
  selector: 'app-dialog-change-civilization',
  templateUrl: './dialog-change-civilization.component.html',
  styleUrls: ['./dialog-change-civilization.component.scss']
})
export class DialogChangeCivilizationComponent implements OnInit{
  planet:any;
  civilizationId:any;
  address:any;
  id:any;
  loadChangeCiv:boolean = false;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogChangeCivilizationComponent>,
    private _contractService: ContractService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _planetService: PlanetService,
    @Inject(MAT_DIALOG_DATA) private data: DialogChangeCivilizationComponent
  ) {}

  ngOnInit(){
    this.planet = this.data.planet;
    this.id = this.data.id;
    this.civilizationId = this.planet.civilization;
  }
  selectFaction(id:number){
    this.civilizationId = id;
  }
  async confirmShips() {
    const checkNetwork = await this._planetService.checkNetwork();
    if (checkNetwork) {
      return
    }
    this.loadChangeCiv = true;
    this._contractService.writeContract(environment.astraPlanet, planetABI, 'changeCivilization', [this.id, this.civilizationId])
      .then(async (chargeProject) => {
        const charge = await chargeProject.wait();
        if (charge) {
          this.loadChangeCiv = false;
          this._notifierService.showNotificationSuccess(this._translateService.instant('notification.civilization_changed'))
          this._dialogRef.close({data: this.planet.name})
        }
      })
      .catch((err) => {
        this.loadChangeCiv = false;
      });
  }
}
