import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {AccountService} from "../../../../core/services/account.service";
import {PlanetService} from "../../../../core/services/planet.service";
import {ethers} from "ethers";
import {environment} from "../../../../../environments/environment";
import { RPC_URL} from "../../../../core/utils/constant";
import {ContractService, NetworkService} from "@scalingparrots/dapp-angular-lib";
import {NotifierService} from "../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
const FDCAbi = require('src/app/core/abi/FederationCredits.json');
const swapABI = require('src/app/core/abi/AstraSwapHydro.json');

@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})
export class SwapComponent implements OnInit{
  swapForm: FormGroup = new FormGroup({});
  loadSwap:boolean=false;
  fdc:any;
  address:any;
  hyd:number = 0;
  constructor(
    public _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _accountService: AccountService,
    private _planetService: PlanetService,
    private _contractService: ContractService,
    private _networkService: NetworkService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(){
    this.swapForm = this._formBuilder.group({
      amount: [0, Validators.required],
    });
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getBalance().subscribe({
            next: (balance) => {
              if(balance && this.fdc === undefined){
                this.fdc = balance.balanceFDC
              }
            }
          })
        }
      }
    })
  }

  setHYD(e:Event){
    const value = Number((e.target as HTMLInputElement).value);
    this.hyd = value * 50;
  }

  async swapToken() {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    const chainId = await window.ethereum.request({ method:'eth_chainId' });
    const binanceTestChainId = this._networkService._globalVariables.requiredNetwork.chainId
    if(chainId !== binanceTestChainId){
      return
    }
    this.loadSwap = true;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner(this.address);
    let blockNumber = await provider.getBlockNumber();
    let block = await provider.getBlock(blockNumber);
    let deadline = block.timestamp + 60;
    let value = ethers.utils.parseEther(String(this.swapForm.get('amount')?.value))
    let name = await this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'name')
    let nonces = await this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'nonces', [signer._address])
    this._planetService.getPermitSignature(signer, nonces, environment.fdc, environment.astraSwapHydro, value, deadline, name)
      .then(async (signature) => {
        this._contractService.writeContract(environment.astraSwapHydro, swapABI, 'swapTokens',
          [value, deadline, signature.v, signature.r, signature.s])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadSwap = false;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.swap_success'))
              this.updateTokenValue()
            }
          })
          .catch((err) => {
            this.loadSwap = false;

          });
      }).catch((err) => {
      this.loadSwap = false;
    });
  }

  updateTokenValue(){
    this._planetService.readBalance(this.address).subscribe({
      next:(res)=>{
        if(res){
          const balance = {
            balanceHYD: res.balanceHYD / 10 ** res.decimalsHYD,
            balanceFDC: res.balanceFDC / 10 ** res.decimalsFDC,
            decimalsFDC: res.decimalsFDC,
            decimalsHYD: res.decimalsHYD,
          }
          this._planetService.setBalance(balance)
        }
      },
      error:(err)=>{
      }
    })
  }
}
