<div *ngIf="!this.loading" class="flex flex-col px-0 sm:px-2 md:flex-row lg:flex-col xl:flex-row gap-10 w-full">
  <form
    *ngIf="step1"
    [formGroup]="nameForm"
    class="flex flex-col items-start gap-2 w-full h-full"
  >
    <div class="flex flex-col items-start gap-8 w-full h-96">
      <label class="title text-white">{{'dashboard.mint.title'|translate}}</label>
      <div class="flex flex-col items-start justify-start gap-2 w-full">
        <label class="title_input text-light-gray">{{'dashboard.mint.title_input'|translate}}</label>
        <div class="flex flex-row items-center justify-start gap-2 w-full">
          <input class="bg-light-black text-white p-2 w-full h-8 max-w-xs outline-0" maxlength="15" placeholder="{{'dashboard.mint.name_planet'|translate}}" formControlName="name" />
          <img class="w-6 cursor-pointer" src="assets/images/icon/why.svg" alt="" (click)="openInfoName()">
        </div>
        <label class="desc-h text-light-gray">{{'dashboard.mint.minted'|translate}}: <label class="subtitle text-light-purple">{{planetSupply}}</label> <label class="subtitle text-light-gray"> / 1123</label></label>
      </div>
      <div class="flex flex-col items-start justify-start w-full max-w-xl gap-4">
        <label class="desc-h text-light-gray">{{'dashboard.mint.desc_start_pack_ship'|translate}}:</label>
        <div class="flex flex-row w-full items-center justify-start gap-2">
          <div *ngFor="let ship of initialShipMint" class="flex flex-col items-center justify-start gap-2">
            <div class="flex flex-row items-center justify-start gap-2">
              <img [src]="ship.uri" class="w-24 sm:w-32 grayscale" alt=""/>
              <label class="desc-h text-light-gray">{{ship.amount}} {{ship.name}}</label>
            </div>
          </div>
        </div>
        <label class="desc-h text-light-gray">{{'dashboard.mint.amount_start_pack_ship'|translate}}</label>
      </div>
    </div>
    <div class="flex flex-col w-48 gap-2 text-center mt-5">
      <label class="subtitle text-light-gray">{{'dashboard.mint.price'|translate}}: <label class="subtitle text-light-purple">{{2500 | currency: '':'':'1.0'}} FDC</label></label>
      <button *ngIf="!loadMint && !claimPlanet && !claimMysteryBox" [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="amountForPrice > fdc ? ('dashboard.mint.no_fdc'|translate): ''" [class]="(nameForm.invalid || amountForPrice > fdc)? 'button text-white bg-dark-black opacity-60 !mx-auto !my-0' :'button text-white bg-purple !mx-auto !my-0'"
              [disabled]="nameForm.invalid || amountForPrice > fdc" (click)="goToNextStep()">{{"dashboard.mint.continue" | translate}}</button>
      <button *ngIf="loadMint" class="button text-white bg-purple flex items-center justify-center">
        <mat-spinner [diameter]="20"></mat-spinner>
      </button>
      <div class="flex flex-row items-center w-full max-w-xs gap-2">
        <div class="w-3/4 h-1 bg-light-purple"></div>
        <div class="w-1/4 h-1 bg-dark-black"></div>
      </div>
      <label *ngIf="oldPlanet && viewClaimPlanet" class="desc-h text-light-purple cursor-pointer" (click)="setClaimPlanet()">{{'dashboard.mint.already_have_planet'|translate}}</label>
    </div>
  </form>
  <div *ngIf="step2" class="flex flex-col items-start gap-2 w-full h-full">
    <div class="flex flex-col items-start gap-8 w-full h-96">
      <div class="flex flex-row items-center justify-start gap-10">
        <label class="title text-white">{{'dashboard.mint.title'|translate}}</label>
        <img src="assets/images/icon/back.svg" class="w-6 cursor-pointer" (click)="goBack()"  alt="">
      </div>
      <div class="flex flex-col items-start gap-4">
        <div class="flex flex-row items-center justify-start gap-2 w-full">
          <label class="title_input text-light-gray">{{'dashboard.mint.title_faction'|translate}}</label>
          <img class="w-6 cursor-pointer" src="assets/images/icon/why.svg" alt="" (click)="openInfoCiv()">
        </div>
        <label class="desc-h text-light-gray">{{'dashboard.mint.desc_start'|translate}}</label>
      </div>
      <div class="flex flex-row items-center justify-start gap-10 w-full mt-5">
        <div [class]="civilizationId === 1 ? 'container_images_selected':'container_images'">
          <img class="h-20 cursor-pointer" src="assets/images/factions/signarok_logo.svg" alt="" (click)="selectFaction(1)">
        </div>
        <div [class]="civilizationId === 2 ? 'container_images_selected':'container_images'">
          <img class="h-20 cursor-pointer" src="assets/images/factions/numaya_logo.svg" alt="" (click)="selectFaction(2)">
        </div>
        <div [class]="civilizationId === 3 ? 'container_images_selected':'container_images'">
          <img class="h-20 cursor-pointer" src="assets/images/factions/afarit_logo.svg" alt="" (click)="selectFaction(3)">
        </div>
      </div>
    </div>
    <div class="flex flex-col w-48 gap-2 text-center mt-5">
      <label class="subtitle text-light-gray">{{'dashboard.mint.price'|translate}}: <label class="subtitle text-light-purple">{{2500 | currency: '':'':'1.0'}} FDC</label></label>
      <button *ngIf="!loadMint && !claimPlanet && !claimMysteryBox" [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="amountForPrice > fdc ? ('dashboard.mint.no_fdc'|translate): ''" [class]="(nameForm.invalid || amountForPrice > fdc)? 'button text-white bg-dark-black opacity-60 !mx-auto !my-0' :'button text-white bg-purple !mx-auto !my-0'"
              [disabled]="nameForm.invalid || amountForPrice > fdc" (click)="confirmMintPlanet()">{{"dashboard.mint.mint" | translate}}</button>
      <button *ngIf="loadMint" class="button text-white bg-purple flex items-center justify-center">
        <mat-spinner [diameter]="20"></mat-spinner>
      </button>
      <div class="flex flex-row items-center w-full max-w-xs gap-2">
        <div class="w-1/4 h-1 bg-dark-black"></div>
        <div class="w-3/4 h-1 bg-light-purple"></div>
      </div>
      <label *ngIf="oldPlanet && viewClaimPlanet" class="desc-h text-light-purple cursor-pointer" (click)="setClaimPlanet()">{{'dashboard.mint.already_have_planet'|translate}}</label>
    </div>
  </div>
  <div *ngIf="step2" class="flex flex-col w-full container_desc px-12 py-6 mr-4 xl:mr-10 2xl:mr-20 gap-6">
    <div class="flex flex-col items-center justify-center">
      <img *ngIf="civilizationId === 1" class="h-full max-h-40" src="assets/images/factions/signarok.png" alt="">
      <img *ngIf="civilizationId === 2" class="h-full max-h-40" src="assets/images/factions/numaya.png" alt="">
      <img *ngIf="civilizationId === 3" class="h-full max-h-40" src="assets/images/factions/afarit.png" alt="">
    </div>
    <div class="flex flex-col items-center justify-center">
      <label *ngIf="civilizationId === 1" class="title text-white">Signarock</label>
      <label *ngIf="civilizationId === 2" class="title text-white">Numaya</label>
      <label *ngIf="civilizationId === 3" class="title text-white">Afarit</label>
    </div>
    <label *ngIf="civilizationId === 1" class="subtitle text-light-gray">{{'dashboard.mint.desc_signarock'|translate}}</label>
    <label *ngIf="civilizationId === 2" class="subtitle text-light-gray">{{'dashboard.mint.desc_numaya'|translate}}</label>
    <label *ngIf="civilizationId === 3" class="subtitle text-light-gray">{{'dashboard.mint.desc_afarit'|translate}}</label>
  </div>
</div>
<div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
