import { StandardMerkleTree } from "@openzeppelin/merkle-tree";
const oldPlanet = require('src/app/core/markle/listHolderPlanet.json');
const oldMystery = require('src/app/core/markle/listHolderMisteryBox.json');

export function getClaimDataPlanetCalculate(myPlanets: any):any {
  // Create the Merkle tree
  const tree = StandardMerkleTree.of(oldPlanet, ["string", "uint256"]);
  // Array to hold all claim data for the given address
  const claims: any[] = [];
  // Find all indices of the leaf with the provided address
  for (let data of myPlanets) {
    const proof = tree.getProof(data);
    const id = data[1] as string;
    // Verify the proof
    const verified = StandardMerkleTree.verify(
      tree.root, ["string", "uint256"], data, proof
    );
    if (!verified) {
      process.exit(1);
    }
    // Push claim data to the claims array
    claims.push({
      id,
      proof,
    });
  }
  return claims;
}

export function getClaimDataMystery(myMystery: any):any {
  // Create the Merkle tree
  const tree = StandardMerkleTree.of(oldMystery, ["string", "uint256", "uint256"]);
  // Array to hold all claim data for the given address
  const claims: any[] = [];
  // Find all indices of the leaf with the provided address
  for (let data of myMystery) {
    const proof = tree.getProof(data);
    const id = data[1] as string;
    const rarity = data[2] as string;
    // Verify the proof
    const verified = StandardMerkleTree.verify(
      tree.root, ["string", "uint256", "uint256"], data, proof
    );
    if (!verified) {
      process.exit(1);
    }
    claims.push({
      id,
      rarity,
      proof,
    });
  }
  return claims;
}
