import {Component, OnInit} from '@angular/core';
import {PlanetService} from "../../../../core/services/planet.service";
import {environment} from "../../../../../environments/environment";
import { RPC_URL} from "../../../../core/utils/constant";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {ethers} from "ethers";
import {AccountService} from "../../../../core/services/account.service";
import {NotifierService} from "../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {DialogUpdateStructureComponent} from "../../components/dialog/dialog-update-structure/dialog-update-structure.component";
import {MatDialog} from "@angular/material/dialog";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ActivatedRoute} from "@angular/router";
const buildingABI = require('src/app/core/abi/AstraBuilding.json');
const FDCAbi = require('src/app/core/abi/FederationCredits.json');

@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '100%',
        maxWidth: '800px',
        height: '100%',
        background: 'transparent',
        gap: '4rem',
      })),
      state('closed', style({
        width: '100%',
        maxWidth: '450px',
        height: '100%',
        background: 'transparent',
        gap: '2rem',
      })),
      transition('closed => open', [
        animate('100ms')
      ]),
      transition('open => closed', [
        animate('100ms')
      ]),
    ]),
  ],
})
export class StructureComponent implements OnInit{
  level = 0;
  address='';
  fdc:any
  innerWidth:any
  provider:any
  selected:any
  planetSelected:any
  loadingUpdate:boolean = false
  noPlanet:boolean = false
  loading:boolean = false
  armoryAvailable:boolean = false
  haveBuildShip:boolean = false
  hangarAvailable:boolean = false
  laboratoryAvailable:boolean = false
  shieldAvailable:boolean = false
  buildStructure:any[] = []
  arrayStructure:any[] = []
  noBuildStructure:boolean = false
  isClosed:boolean = true;
  opened:boolean = false;
  src = 'lab.png'
  nameSelected = 'Laboratorio'
  structures = [
    {
      name:'Armeria',
      description:'dashboard.structure.desc_armory',
      level:0,maxLevel:0,allLevel:[{}],cutLevel:[{}], src:'armory.png', selected:'armory',timeUpdate: '0',costUpdate: 0,timestampTime:0,
    },
    {
      name:'Hangar',
      description:'dashboard.structure.desc_hangar',
      level:0,maxLevel:0,allLevel:[{}],cutLevel:[{}], src:'hangar.png', selected:'hangar',timeUpdate: '0',costUpdate: 0,timestampTime:0,
    },
    {
      name:'Laboratorio',
      description:'dashboard.structure.desc_lab',
      level:0,maxLevel:0,allLevel:[{}],cutLevel:[{}], src:'lab.png', selected:'laboratory', timeUpdate: '0',costUpdate: 0,timestampTime:0,
    },
    {
      name:'Scudo',
      description:'dashboard.structure.desc_shield',
      level:0,maxLevel:0,allLevel:[{}],cutLevel:[{}], src:'shield.png', selected:'shield',timeUpdate: '0',costUpdate: 0,timestampTime:0,
    },
  ];
  constructor(
    private _dialog:MatDialog,
    private _planetService:PlanetService,
    private _contractService:ContractService,
    private _accountService:AccountService,
    private _notifierService:NotifierService,
    private _translateService:TranslateService,
    private _route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.loading = true;
    if(this._route.snapshot.paramMap.get('name')){
      const name = this._route.snapshot.paramMap.get('name')
      const selectedFilter = this.structures.filter((data)=> data.selected === name)
      this.selected = selectedFilter[0]
    } else {
      this.selected = this.structures[0]
    }
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account  && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getBalance().subscribe({
            next:(balance) => {
              if(balance && this.fdc === undefined){
                this.fdc = balance.balanceFDC
              }
            }
          })
          this._planetService.getPlanet().subscribe({
            next:(planets) => {
              if (planets && planets.length > 0 && this.planetSelected === undefined) {
                let planetSelected = localStorage.getItem(this.address+'planetSelected')
                if(planetSelected){
                  const planet = planets.filter((data) => data.name === planetSelected)
                  if(planet && planet.length > 0){
                    this.planetSelected = planet[0]
                    this._planetService.getInfoPlanetStructure(this.planetSelected.id).subscribe({
                      next:(info) => {
                        if(info){
                          if(info.availability.armory === null && !info.haveBuildShip ){
                            this.armoryAvailable = true
                          } else if(info.haveBuildShip) {
                            this.haveBuildShip = info.haveBuildShip
                          }
                          if(info.availability.hangar === null){
                            this.hangarAvailable = true
                          }
                          if(info.availability.laboratory === null){
                            this.laboratoryAvailable = true
                          }
                          if(info.availability.shield === null){
                            this.shieldAvailable = true
                          }
                          this.getData(info.levelBuilding)
                          this.checkUpdate()
                          this.getStructureInUpdate(info.availability)
                        }
                      }
                    })
                  }
                } else {
                  this.planetSelected = planets[0]
                  this._planetService.getInfoPlanetStructure(this.planetSelected.id).subscribe({
                    next:(info) => {
                      if(info){
                        if(info.availability.armory === null && !info.haveBuildShip ){
                          this.armoryAvailable = true
                        } else if(info.haveBuildShip) {
                          this.haveBuildShip = info.haveBuildShip
                        }
                        if(info.availability.hangar === null){
                          this.hangarAvailable = true
                        }
                        if(info.availability.laboratory === null){
                          this.laboratoryAvailable = true
                        }
                        if(info.availability.shield === null){
                          this.shieldAvailable = true
                        }
                        this.getData(info.levelBuilding)
                        this.checkUpdate()
                        this.getStructureInUpdate(info.availability)
                      }
                    }
                  })
                }
              } else {
                this._planetService.getInfoPlanetStructure(0).subscribe({
                  next:(info) => {
                    if(info){
                      this.getDataNoPlanet(info.levelBuilding)
                    }
                  }
                })
              }
            }
          })
        }
      }
    })
  }

  toggle() {
    this.opened = !this.opened
    this.isClosed = !this.isClosed;
  }
  checkUpdate(){
    setInterval(async () => {
      this.innerWidth = window.innerWidth;
      if(this.arrayStructure && this.arrayStructure.length >0){
        this.checkTimeToEndStructures(this.arrayStructure)
      } else {
        this.noBuildStructure = false;
        this.buildStructure = [];
      }
    },1000)
  }
  async checkTimeToEndStructures(arrayStructures: any) {
    let timeToEnd: any[] = []
    if (arrayStructures.length > 0) {
      for (let i = 0; i < arrayStructures.length; i++) {
        const time = await this.getBlockTime(arrayStructures[i].time)
        if (time.slice(0, 1) !== '-') {
          timeToEnd.push({time: time,name:arrayStructures[i].name})
        } else {
          this.buildStructure = []
          this.updateStructureData()
        }
      }
      if(timeToEnd && timeToEnd.length > 0){
        this.buildStructure = timeToEnd
        this.noBuildStructure = true;
      } else {
        this.noBuildStructure = false;
        this.arrayStructure = []
        setTimeout(()=>{
          this.updateStructureData()
        },1000)
      }
    } else {
      this.noBuildStructure = false;
      this.arrayStructure = []
      setTimeout(()=>{
        this.updateStructureData()
      },1000)
    }
  }

  updateStructureData(){
    this.buildStructure = [];
    this._planetService.getInfoPlanetStructure(this.planetSelected.id).subscribe({
      next:(info) => {
        this.getStructureInUpdate(info.availability)
        if(info.availability.armory === null && !info.haveBuildShip ){
          this.armoryAvailable = true
        } else if(info.haveBuildShip) {
          this.haveBuildShip = info.haveBuildShip
        } else {
          this.armoryAvailable = false
        }
        this.hangarAvailable = info.availability.hangar === null;
        this.laboratoryAvailable = info.availability.laboratory === null;
        this.shieldAvailable = info.availability.shield === null;
      }
    })
  }

  async getBlockTime(timestampToEnd: any) {
    const timestamp = Math.floor(Date.now()/1000);
    const timeRemainingInSeconds = timestampToEnd - timestamp;
    let approximateTime :any;
    let daysRemaining :any;
    let hoursRemaining :any;
    let minutesRemaining:any;
    let secondsRemaining :any;
    // Calculate the time remaining in seconds
    daysRemaining = Math.floor(timeRemainingInSeconds / (3600 * 24));
    hoursRemaining = Math.floor(timeRemainingInSeconds % (3600 * 24) / 3600);
    minutesRemaining = Math.floor((timeRemainingInSeconds % 3600) / 60);
    secondsRemaining = timeRemainingInSeconds % 60;
    if(daysRemaining > 0 ){
      approximateTime = daysRemaining + 'd: ' + hoursRemaining + 'h: ' + minutesRemaining + 'm: ' + secondsRemaining + 's'
    } else if(hoursRemaining > 0){
      approximateTime = hoursRemaining + 'h: ' + minutesRemaining + 'm: ' + secondsRemaining + 's'
    } else {
      approximateTime =  minutesRemaining + 'm: ' + secondsRemaining + 's'
    }
    return  approximateTime
  }

  getData(level:any){
    this._planetService.getGlobalInfoPlanet().subscribe({
      next:(info) => {
        const building = info.costUpdateBuilding
        const timestamp = Math.floor(Date.now()/1000);
        this.structures[0].level = level.armory
        this.structures[0].maxLevel = building.armory.length
        let cutLevelAr: any[] = []
        let allLevelAr: any[] = []
        for (let i = 0; i < building.armory.length; i++) {
          this.costUpdate(i,timestamp,cutLevelAr,allLevelAr, building.armory[i],0, info.powerLevelBuilding.armory.power[i])
        }
        this.structures[1].level = level.hangar
        this.structures[1].maxLevel =  building.hangar.length
        let cutLevelHa: any[] = []
        let allLevelHa: any[] = []
        for (let i = 0; i < building.hangar.length; i++) {
          this.costUpdate(i,timestamp,cutLevelHa,allLevelHa,building.hangar[i],1, info.powerLevelBuilding.hangar.power[i])
        }
        this.structures[2].level = level.laboratory
        this.structures[2].maxLevel = building.laboratory.length
        let cutLevelLab: any[] = []
        let allLevelLab: any[] = []
        for (let i = 0; i < building.laboratory.length; i++) {
          this.costUpdate(i,timestamp,cutLevelLab,allLevelLab,building.laboratory[i],2,'- '+(info.powerLevelBuilding.laboratory.power[i])+ '%')
        }
        this.structures[3].level = level.shield
        this.structures[3].maxLevel = building.shield.length
        let cutLevelSh: any[] = []
        let allLevelSh: any[] = []
        for (let i = 0; i < building.shield.length; i++) {
          this.costUpdate(i,timestamp,cutLevelSh,allLevelSh, building.shield[i],3, (info.powerLevelBuilding.shield.power[i])+ ' %')
        }
        cutLevelAr = cutLevelAr.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[0].cutLevel = cutLevelAr;
        this.structures[0].allLevel = allLevelAr;
        cutLevelHa = cutLevelHa.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[1].cutLevel = cutLevelHa;
        this.structures[1].allLevel = allLevelHa;
        cutLevelLab = cutLevelLab.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[2].cutLevel = cutLevelLab;
        this.structures[2].allLevel = allLevelLab;
        cutLevelSh = cutLevelSh.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[3].cutLevel = cutLevelSh;
        this.structures[3].allLevel = allLevelSh;
        this.loading = false
      }
    });
  }

  getDataNoPlanet(level:any){
    this._planetService.getGlobalInfo().subscribe({
      next:(info) => {
        const building = info.costUpdateBuilding
        const timestamp = Math.floor(Date.now()/1000);
        this.structures[0].level = level.armory
        this.structures[0].maxLevel = building.armory.length
        let cutLevelAr: any[] = []
        let allLevelAr: any[] = []
        for (let i = 0; i < building.armory.length; i++) {
          this.costUpdate(i,timestamp,cutLevelAr,allLevelAr, building.armory[i],0, info.powerLevelBuilding.armory.power[i])
        }
        this.structures[1].level = level.hangar
        this.structures[1].maxLevel =  building.hangar.length
        let cutLevelHa: any[] = []
        let allLevelHa: any[] = []
        for (let i = 0; i < building.hangar.length; i++) {
          this.costUpdate(i,timestamp,cutLevelHa,allLevelHa,building.hangar[i],1, info.powerLevelBuilding.hangar.power[i])
        }
        this.structures[2].level = level.laboratory
        this.structures[2].maxLevel = building.laboratory.length
        let cutLevelLab: any[] = []
        let allLevelLab: any[] = []
        for (let i = 0; i < building.laboratory.length; i++) {
          this.costUpdate(i,timestamp,cutLevelLab,allLevelLab,building.laboratory[i],2, '- '+(info.powerLevelBuilding.laboratory.power[i])+ '%')
        }
        this.structures[3].level = level.shield
        this.structures[3].maxLevel = building.shield.length
        let cutLevelSh: any[] = []
        let allLevelSh: any[] = []
        for (let i = 0; i < building.shield.length; i++) {
          this.costUpdate(i,timestamp,cutLevelSh,allLevelSh, building.shield[i],3, '+ '+(info.powerLevelBuilding.shield.power[i])+ '%')
        }
        cutLevelAr = cutLevelAr.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[0].cutLevel = cutLevelAr;
        this.structures[0].allLevel = allLevelAr;
        cutLevelHa = cutLevelHa.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[1].cutLevel = cutLevelHa;
        this.structures[1].allLevel = allLevelHa;
        cutLevelLab = cutLevelLab.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[2].cutLevel = cutLevelLab;
        this.structures[2].allLevel = allLevelLab;
        cutLevelSh = cutLevelSh.sort(function (a: { i: number }, b: { i: number }) {
          return a.i - b.i;
        });
        this.structures[3].cutLevel = cutLevelSh;
        this.structures[3].allLevel = allLevelSh;
        this.noPlanet = true;
        this.loading = false;
      }
    });
  }
  selectCard(str:string,structure:any){
    this.nameSelected = str;
    this.selected = structure;
  }

  async costUpdate(i: any, timestamp: any, cutLevelAr: any, allLevelAr: any, cost: any, index: any,power:any) {
    if (i === this.structures[index].level) {
      this.structures[index].costUpdate = +cost.fdcPrice
      this.structures[index].timestampTime = +cost.timeBuild
      const timeUpdateW = await this.getBlockTime(timestamp + (+cost.timeBuild))
      this.structures[index].timeUpdate = String(timeUpdateW)
    }
    if (i >= this.structures[index].level && i < this.structures[index].level + 3) {
      const price = +cost.fdcPrice
      const timeUpdate = await this.getBlockTime(timestamp + (+cost.timeBuild))
      cutLevelAr.push({i, price,power, timeUpdate})
    } else if(i === this.structures[index].level -1 && this.structures[index].level === this.structures[index].maxLevel){
      const price = +cost.fdcPrice
      const timeUpdate = await this.getBlockTime(timestamp + (+cost.timeBuild))
      cutLevelAr.push({i, price,power, timeUpdate})
    }
    const price = +cost.fdcPrice
    const timeUpdate = await this.getBlockTime(timestamp + (+cost.timeBuild))
    allLevelAr.push({i, price,power,timeUpdate})
  }

  async updateStructure(nameFunction:string, label:any,selected:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    if(!this.laboratoryAvailable && selected.name === 'Laboratorio' && !this.noPlanet){
      this._notifierService.showNotificationError(this._translateService.instant('dashboard.structure.no_build'))
      return
    } else if(!this.hangarAvailable && selected.name === 'Hangar' && !this.noPlanet){
      this._notifierService.showNotificationError(this._translateService.instant('dashboard.structure.no_build'))
      return
    } else if(!this.armoryAvailable && selected.name === 'Armeria' && !this.noPlanet && this.haveBuildShip){
      this._notifierService.showNotificationError(this._translateService.instant('dashboard.structure.no_build_armory'))
      return
    } else if(!this.armoryAvailable && selected.name === 'Armeria' && !this.noPlanet && !this.haveBuildShip){
      this._notifierService.showNotificationError(this._translateService.instant('dashboard.structure.no_build'))
      return
    } else if(!this.shieldAvailable && selected.name === 'Scudo' && !this.noPlanet){
      this._notifierService.showNotificationError(this._translateService.instant('dashboard.structure.no_build'))
      return
    }
    this.loadingUpdate = true;
    let planetId = this.planetSelected.id;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner(this.address);
    let blockNumber = await provider.getBlockNumber();
    let block = await provider.getBlock(blockNumber);
    let deadline = block.timestamp + 60;
    let value =  ethers.utils.parseEther(String(selected.costUpdate))
    let name = await this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'name')
    let nonces = await this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'nonces',[signer._address])
    this._dialog.open(DialogUpdateStructureComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{price:selected.costUpdate,time:selected.timeUpdate, name:label},
    }).afterClosed().subscribe( (res:any) => {
      if (res && res.data) {
        this._planetService.getPermitSignature(signer,nonces,environment.fdc,environment.astraBuilding, value, deadline,name)
          .then(async (signatureFDC) => {
            this._contractService.writeContract(environment.astraBuilding, buildingABI, nameFunction,
              [planetId, deadline, signatureFDC.v, signatureFDC.r, signatureFDC.s])
              .then(async (chargeProject) => {
                const charge = await chargeProject.wait();
                if(charge){
                  this._notifierService.showNotificationSuccess(this._translateService.instant('notification.start_build_structure'))
                  this._planetService.getInfoPlanetStructure(this.planetSelected.id).subscribe({
                    next:(info) => {
                      if(info !== null && info.availability.armory === null && !info.haveBuildShip ){
                        this.armoryAvailable = true
                      } else if(info !== null && info.haveBuildShip) {
                        this.haveBuildShip = info.haveBuildShip
                        this.armoryAvailable = false
                      } else {
                        this.armoryAvailable = false
                        this.haveBuildShip = false
                      }
                      this.hangarAvailable = info.availability.hangar === null;
                      this.laboratoryAvailable = info.availability.laboratory === null;
                      this.shieldAvailable = info.availability.shield === null;
                      this.getData(info.levelBuilding)
                      this.checkUpdate()
                      this.getStructureInUpdate(info.availability)
                      this.fdc = undefined;
                      this._planetService.readBalance(this.address).subscribe({
                        next:(info)=>{
                          if(info && this.fdc === undefined){
                            this.fdc = info.balanceFDC / 10 ** info.decimalsFDC
                            const balance = {
                              balanceHYD: info.balanceHYD / 10 ** info.decimalsHYD,
                              balanceFDC: info.balanceFDC / 10 ** info.decimalsFDC,
                              decimalsFDC: info.decimalsFDC,
                              decimalsHYD: info.decimalsHYD,
                            }
                            this._planetService.setBalance(balance)
                          }
                        },
                        error:(err)=>{
                        }
                      })
                    }
                  })
                  this.loadingUpdate = false;
                }
              })
              .catch((err) => {
                this.loadingUpdate = false;
                const error = JSON.stringify(err)
                const data = JSON.parse(error)
                if (data.reason.includes('laboratory not available at the moment')) {
                  this._notifierService.showNotificationError(this._translateService.instant('notification.lab_not_available'))
                } else if (data.reason.includes('shield not available at the moment')) {
                  this._notifierService.showNotificationError(this._translateService.instant('notification.sh_not_available'))
                }
              });
          }).catch(() => {
          this.loadingUpdate = false;
        });
      } else {
        this.loadingUpdate = false;
      }
    })
  }

  async getStructureInUpdate(info:any) {
    const allStructures = []
    const timestamp = Math.floor(Date.now()/1000);
    if (timestamp < info.laboratory) {
      const structure = {name: 'laboratory', time:info.laboratory}
      allStructures.push(structure)
    }
    if (timestamp < info.hangar) {
      const structure = {name: 'hangar', time:info.hangar}
      allStructures.push(structure)
    }
    if (timestamp < info.armory) {
      const structure = {name: 'armory', time:info.armory}
      allStructures.push(structure)
    }
    if (timestamp < info.shield) {
      const structure = {name: 'shield', time:info.shield}
      allStructures.push(structure)
    }
    if(allStructures.length > 0){
      this.arrayStructure = allStructures
    }
  }
}
