import {Component, OnInit} from '@angular/core';
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {environment} from "../../../../../environments/environment";
import { RPC_URL} from "../../../../core/utils/constant";
import {AccountService} from "../../../../core/services/account.service";
import {PlanetService} from "../../../../core/services/planet.service";
import {ethers} from "ethers";
import {NotifierService} from "../../../../core/services/notifier.service";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {DialogMintShipsComponent} from "../../components/dialog/dialog-mint-ships/dialog-mint-ships.component";
import {DialogConfirmMintShipsComponent} from "../../components/dialog/dialog-confirm-mint-ships/dialog-confirm-mint-ships.component";
import {DialogViewShipsMintedComponent} from "../../components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
import {DialogClaimOldMysteryComponent} from "../../components/dialog/dialog-claim-old-mystery/dialog-claim-old-mystery.component";
import {getClaimDataMystery} from "../../../../core/markle/merkleTreeProof";
import {DialogDescriptionShipsComponent} from "../../components/dialog/dialog-description-ships/dialog-description-ships.component";
const FDCAbi = require('src/app/core/abi/FederationCredits.json');
const buildingABI = require('src/app/core/abi/AstraBuilding.json');

@Component({
  selector: 'app-ships',
  templateUrl: './ships.component.html',
  styleUrls: ['./ships.component.scss'],
})
export class ShipsComponent implements OnInit{

  nameSelected = ''
  ship:any;
  allShips:any;
  dateNow:any;
  balance:any;
  decimalsFDC:any;
  decimalsH:any;
  planetSelected:any;
  noPlanet:boolean = false;
  loading:boolean = false;
  loadMint:boolean = false;
  loadingClaim:boolean = false;
  loadClaimAll:boolean = false;
  noBuildShips:boolean = false;
  isArmoryAvailable:boolean = true;
  isLaboratoryAvailable:boolean = true;
  mysteryToReveal:boolean = false;
  oldMystery:any[]=[];
  viewClaimMystery:boolean=false;
  nextShips:boolean = false;
  address='';
  levelAR= 0;
  amountShipsInCart= 0;
  civId= 1;
  allShipsLength=0;
  type:string='next';
  src = '.png'
  shipsFiltered:any[] = [];
  ships:any[] = [];
  shipsCart:any[] = [];
  arrayShips:any[] = [];
  buildShips:any[] = [];
  constructor(
    private _contractService:ContractService,
    private _accountService:AccountService,
    private _planetService:PlanetService,
    private _notifierService:NotifierService,
    private _dialog:MatDialog,
    private _translateService:TranslateService
  ) {
  }

  ngOnInit(): void {
    this.dateNow = Math.floor(Date.now()/1000)
    this.loading = true;
    this.noBuildShips = false;
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account  && (this.address === '' || account.address !== this.address)) {
          this.address = account.address
          this._planetService.getBalance().subscribe({
            next:(info) => {
              if(info && this.balance === undefined){
                this.decimalsFDC = info.decimalsFDC;
                this.decimalsH = info.decimalsHYD;
                this.balance = info.balanceFDC;
              }
            }
          })
          this.getClaimMystery()
          this._planetService.getPlanet().subscribe({
            next:(planets) => {
              if (planets && planets.length > 0 && this.planetSelected === undefined) {
                let planetSelected = localStorage.getItem(this.address+'planetSelected')
                if(planetSelected){
                  const planet = planets.filter((data) => data.name === planetSelected)
                  if(planet && planet.length >0){
                    this.planetSelected = planet[0]
                    this._planetService.getInfoPlanetShip(this.planetSelected.id,this.address).subscribe({
                      next:(ship) => {
                        if(ship){
                          this.allShips = this._planetService.createShips(ship.infoShip)
                          this.checkUpdate()
                          this.getShipsInUpdate(ship.buildShip)
                          this.levelAR = ship.levelArmory
                          this.allShipsLength = ship.infoShip.length
                          if(ship.availabilityArmory > 0){
                            this.isArmoryAvailable = false
                          }
                          if(ship.availabilityLab > 0){
                            this.isLaboratoryAvailable = false
                          }
                          for (let i = 0; i < this.allShipsLength; i++) {
                            this.getShips(ship,ship.shipBalance,this.allShipsLength)
                          }
                        }
                      }
                    })
                  }
                } else {
                  this.planetSelected = planets[0]
                  this._planetService.getInfoPlanetShip(this.planetSelected.id,this.address).subscribe({
                    next:(ship) => {
                      if(ship){
                        this.allShips = this._planetService.createShips(ship.infoShip)
                        this.checkUpdate()
                        this.getShipsInUpdate(ship.buildShip)
                        this.levelAR = ship.levelArmory
                        this.allShipsLength = ship.infoShip.length
                        if(ship.availabilityArmory > 0){
                          this.isArmoryAvailable = false
                        }
                        if(ship.availabilityLab > 0){
                          this.isLaboratoryAvailable = false
                        }
                        for (let i = 0; i < this.allShipsLength; i++) {
                          this.getShips(ship,ship.shipBalance,this.allShipsLength)
                        }
                      }
                    }
                  })
                }
              } else {
                this._planetService.getInfoPlanetShip(0,this.address).subscribe({
                  next:(ship) => {
                      if(ship){
                        this.allShips = this._planetService.createShips(ship.infoShip)
                          this.allShipsLength = ship.infoShip.length
                          if(ship.availabilityArmory > 0){
                              this.isArmoryAvailable = false
                          }
                          if(ship.availabilityLab > 0){
                              this.isLaboratoryAvailable = false
                          }
                          for (let i = 0; i < this.allShipsLength; i++) {
                              this.getShipsNoPlanet(i,ship.infoShip[i].metadata,ship.infoShip[i].mintInfo,ship.shipBalance)
                          }
                      }
                  }
                })
              }
            }
          })
        }
      }
    })
  }

  getClaimMystery(){
    this._planetService.getMintPlanetInfo(this.address).subscribe({
      next:async (info) => {
        if(info){
          const newMystery:any[] = []
          this.mysteryToReveal = info.misteryBoxToReveal !== 0;
          if(info.oldMisteryBoxToClaim !== null){
            for(let data of info.oldMisteryBoxToClaim){
              newMystery.push([data.address,data.tokenId,data.rarity])
            }
            const mystery = getClaimDataMystery(newMystery)
            for(let i = 0; i < info.oldMisteryBoxToClaim.length; i ++){
              const data = {
                id:mystery[i].id,
                rarity:mystery[i].rarity,
                proof:mystery[i].proof,
                claimed:info.oldMisteryBoxToClaim[i].claimed,
                revealed:info.oldMisteryBoxToClaim[i].revealed
              }
              this.oldMystery.push(data)
            }
            if (this.oldMystery.length > 0) {
              this.viewClaimMystery = true;
            }
          }
        }
      }
    })
  }
  checkUpdate(){
    setInterval(() => {
      this.dateNow = Math.floor(Date.now()/1000)
    }, 1000);
  }

  async getShipsInUpdate(info: any) {
    const shipsQueue: any[] = [];
    const arrayOfShips: any[] = [];
    if(info && info.length > 0){
      for (let i = 0 ; i < info.length; i++) {
        const data = {
          listAmount:info[i].listAmount,
          availability:info[i].availability,
          claimed:info[i].claimed,
          planetId:info[i].planetId,
          listType:info[i].listType,
          index:info[i].index,
        }
        shipsQueue.push(data)
      }
      if(shipsQueue && shipsQueue.length >0){
        for(let i = 0; i < shipsQueue.length; i ++){
          let sum = 0;
          for(let amount of shipsQueue[i].listAmount){
            sum = sum + Number(amount)
          }
          const allShips = {
            amounts: shipsQueue[i].listAmount,
            type: shipsQueue[i].listType,
          }
          arrayOfShips.push({index:+shipsQueue[i].index,amount: sum,endUpdateAllShips:+shipsQueue[i].availability,endUpdateShips:+shipsQueue[i].availability,ships:allShips})
        }
        this.arrayShips = arrayOfShips
        this.loadingClaim = false;
      } else {
        this.arrayShips = arrayOfShips
        this.loadingClaim = false;
      }
    }else {
      this.arrayShips = arrayOfShips
      this.loadingClaim = false;
    }
  }
  selectFaction(id:number){
    this.shipsFiltered = this.ships.filter((data)=> data.civId === id)
    this.selectCardFaction(this.shipsFiltered[0].name, this.shipsFiltered[0],this.civId === id,id)
  }
  selectCardFaction(str:string,ship:any,isSame:boolean,id:any){
    if(this.nextShips || (this.nameSelected === str && isSame)){
      return
    }
    this.civId = id;
    this.nextShips = true;
    this.nameSelected = str;
    this.ship = ship;
  }
  selectCard(str:string,ship:any){
    if(this.nextShips || (this.nameSelected === str)){
      return
    }
    this.nextShips = true;
    this.nameSelected = str;
    this.ship = ship;
  }
  async mintShips() {
    if(!this.isArmoryAvailable){
      this._notifierService.showNotificationError(this._translateService.instant('dashboard.ships.no_build_ships_already_build_armory'))
      return
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(this.address);
    const planetId = this.planetSelected.id;
    this._dialog.open(DialogMintShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data: {ship:this.ship,balance:this.balance,label:'buy'},
    }).afterClosed().subscribe((res) => {
      if (res && res.data){
        if(res.data.type === 'addToCart'){
          this.amountShipsInCart = 0;
          const index = this.shipsCart.findIndex((data:any)=> data.typeShip[0] === res.data.typeShip[0])
          if(index >=0){
            this.shipsCart[index].amounts[0] = this.shipsCart[index].amounts[0] + res.data.amounts[0]
          } else {
            this.shipsCart.push(res.data)
          }
          for(let ship of this.shipsCart){
            this.amountShipsInCart = this.amountShipsInCart + ship.amounts[0]
          }
          for(let ship of this.ships){
            ship.size = 0;
          }
        } else {
          this.loadMint = true;
          this.approveMoreShips(provider,signer,ethers.utils.parseEther(String(res.data.fdcTotal)),planetId,res.data.typeShip,res.data.amounts)
        }
      } else {
        for(let ship of this.ships){
          ship.size = 0;
        }
      }
    });
  }
  async mintMoreShips() {
    if(!this.isArmoryAvailable){
      this._notifierService.showNotificationError(this._translateService.instant('dashboard.ships.no_build_ships_already_build_armory'))
      return
    }
    this._dialog.open(DialogMintShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data: {ship:this.ship,balance:this.balance,label:'add'},
    }).afterClosed().subscribe((res) => {
      if (res && res.data){
        this.amountShipsInCart = 0;
        const index = this.shipsCart.findIndex((data:any)=> data.typeShip[0] === res.data.typeShip[0])
        if(index >=0){
          this.shipsCart[index].amounts[0] = this.shipsCart[index].amounts[0] + res.data.amounts[0]
        } else {
          this.shipsCart.push(res.data)
        }
        for(let ship of this.shipsCart){
          this.amountShipsInCart = this.amountShipsInCart + ship.amounts[0]
        }
        for(let ship of this.ships){
          ship.size = 0;
        }
      } else {
        for(let ship of this.ships){
          ship.size = 0;
        }
      }
    });
  }

  async ConfirmBuyShips() {
    if(this.amountShipsInCart <= 0){
      return
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(this.address);
    const planetId = this.planetSelected.id;
    this._dialog.open(DialogConfirmMintShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data: {shipsCart:this.shipsCart,balance:this.balance},
    }).afterClosed().subscribe((res) => {
      if(res && res.data === 'cancel'){
        this.shipsCart = [];
        this.amountShipsInCart = 0;
        for(let i = 0; i < this.ships.length; i++){
          this.ships[i].size = 0;
        }
      } else if (res && res.data === 'edit'){
        this.amountShipsInCart = 0;
        let shipsCart = []
        for(let ship of res.ships){
          const index = this.shipsCart.findIndex((cart:any)=>  cart.ship.id === ship.id)
          if(index >=0){
            if(ship.size >0){
              this.shipsCart[index].amounts[0] = ship.size
              shipsCart.push(this.shipsCart[index])
            } else {
              this.shipsCart.splice(index,1)
            }
          }
        }
        this.shipsCart =shipsCart;
        for(let ship of this.shipsCart){
          this.amountShipsInCart = this.amountShipsInCart + ship.amounts[0]
        }
        for(let ship of this.ships){
          ship.size = 0;
        }
      } else if (res && res.data && res.data.fdcTotal){
        this.loadMint = true;
        this.approveMoreShips(provider,signer,ethers.utils.parseEther(String(res.data.fdcTotal)),planetId,res.data.typeShip,res.data.amounts)
      } else {
        this.amountShipsInCart = 0;
        let shipsCart = []
        for(let ship of res.ships){
          const index = this.shipsCart.findIndex((cart:any)=>  cart.ship.id === ship.id)
          if(index >=0){
            if(ship.size >0){
              this.shipsCart[index].amounts[0] = ship.size
              shipsCart.push(this.shipsCart[index])
            } else {
              this.shipsCart.splice(index,1)
            }
          }
        }
        this.shipsCart =shipsCart;
        for(let ship of this.shipsCart){
          this.amountShipsInCart = this.amountShipsInCart + ship.amounts[0]
        }
        for(let ship of this.ships){
          ship.size = 0;
        }
      }
    });
  }
  async approveMoreShips(provider:any,signer:any,fdcTotal:any,planetId:any,typeShip:any,amounts:any,) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      for(let ship of this.ships){
        ship.size = 0;
      }
      this.loadMint = false;
      return
    }
    let blockNumber = await provider.getBlockNumber();
    let block = await provider.getBlock(blockNumber);
    let deadline = block.timestamp + 60;
    const nonces = this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'nonces', [signer._address])
    const name = this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'name')
    Promise.all([nonces, name])
      .then(([nonces, name]) => {
        this._planetService.getPermitSignature(signer, nonces, environment.fdc, environment.astraBuilding, fdcTotal, deadline, name)
          .then(async (signatureFDC) => {
            this._contractService.writeContract(environment.astraBuilding, buildingABI, 'buildShip',
              [planetId, typeShip, amounts, deadline, signatureFDC.v, signatureFDC.r, signatureFDC.s])
              .then(async (chargeProject) => {
                const charge = await chargeProject.wait();
                if(charge){
                  this._notifierService.showNotificationSuccess(this._translateService.instant('notification.ships_in_queue'))
                  this.loadMint = false;
                  this.shipsCart = [];
                  this.amountShipsInCart = 0;
                  for(let i = 0; i < this.ships.length; i++){
                    this.ships[i].size = 0;
                  }
                  this._planetService.getInfoPlanetShip(this.planetSelected.id,this.address).subscribe({
                    next:(ship) => {
                      this.getShipsInUpdate(ship.buildShip)
                      this.balance = undefined;
                      this._planetService.readBalance(this.address).subscribe({
                        next:(info)=>{
                          if(info && this.balance === undefined){
                            this.balance = info.balanceFDC / 10 ** info.decimalsFDC
                            const balance = {
                              balanceHYD: info.balanceHYD / 10 ** info.decimalsHYD,
                              balanceFDC: info.balanceFDC / 10 ** info.decimalsFDC,
                              decimalsFDC: info.decimalsFDC,
                              decimalsHYD: info.decimalsHYD,
                            }
                            this._planetService.setBalance(balance)
                          }
                        },
                        error:(err)=>{
                        }
                      })
                    }
                  })
                }
              })
              .catch((err) => {
                const error = JSON.stringify(err)
                const data = JSON.parse(error)
                if(data.reason.includes('level armory must be greater than')) {
                  this._notifierService.showNotificationError(this._translateService.instant('notification.level_armory'))
                } else if (data.reason.includes('build not available at the moment')){
                  this._notifierService.showNotificationError(this._translateService.instant('notification.build_not_available'))
                }
                for(let ship of this.ships){
                  ship.size = 0;
                }
                this.loadMint = false;
              });
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            for(let ship of this.ships){
              ship.size = 0;
            }
            this.loadMint = false;
          });
      })
  }
  async getShips(shipInfo: any,allShipBalance: any,allShipsLength:any) {
    const ships: any[] = [];
    for (let i = 0; i < allShipsLength; i++) {
      const id = i;
      const metaData = shipInfo.infoShip[i].metadata;
      const infoMint = shipInfo.infoShip[i].mintInfo;
      let balance = 0;
      if(allShipBalance.ship_type.includes(id)){
        const index = allShipBalance.ship_type.findIndex((data:any)=> data === id)
        balance = allShipBalance.amount[index]
      }
      const timeBuildSingle = infoMint.timeBuildPlanet
      let approximateTime = '';
      let hoursRemaining = Math.floor(timeBuildSingle / 3600);
      let minutesRemaining = Math.floor((timeBuildSingle % 3600) / 60);
      let secondsRemaining = timeBuildSingle % 60;
      if (hoursRemaining > 0) {
        approximateTime = hoursRemaining + 'h : ' + minutesRemaining + 'm : ' + secondsRemaining + 's'
      } else {
        approximateTime = minutesRemaining + 'm : ' + secondsRemaining + 's'
      }
      const ship = {
        imageURL : 'assets/images/ships/'+i+'.png',
        desc : this._translateService.instant('dashboard.ships.'+ metaData.name.toLowerCase()),
        id: id,
        civId: infoMint.civId,
        name: metaData.name,
        description: metaData.description,
        quantity: +balance,
        size: 0,
        sizeValue: metaData.size,
        attack: metaData.att,
        defense: metaData.def,
        speed: metaData.speed,
        resistance: metaData.resistance,
        hold: metaData.hold,
        kmHydro: metaData.kmHydro,
        timeBuild: +timeBuildSingle,
        time: approximateTime,
        approximateTime: approximateTime,
        src: infoMint.uri,
        srcDisabled: infoMint.uri,
        level: infoMint.level,
        maxSupply: infoMint.maxSupply,
        priceFDC: infoMint.fdcPrice,
      }
      ships.push(ship)
      if (id === this.allShipsLength - 1 && ships.length > 0) {
        setTimeout(() => {
          this.ships = ships;
          this.shipsFiltered = this.ships.filter((data) => data.civId === this.civId)
          this.ship = this.shipsFiltered[0]
          this.selectFaction(this.planetSelected.civilization)
          this.loading = false;
          this.noPlanet = false;
        }, 1000)
      }
    }
  }

  async getShipsNoPlanet(id: any,metaData: any,infoMint: any,allShipBalance:any) {
    let balance = 0;
    if(allShipBalance.ship_type.includes(id)){
      const index = allShipBalance.ship_type.findIndex((data:any)=> data === id)
      balance = allShipBalance.amount[index]
    }
    const timeBuildSingle = infoMint.timeBuildPlanet
    let approximateTime = '';
    let hoursRemaining = Math.floor(timeBuildSingle / 3600);
    let minutesRemaining = Math.floor((timeBuildSingle % 3600) / 60);
    let secondsRemaining = timeBuildSingle % 60;
    if (hoursRemaining > 0) {
      approximateTime = hoursRemaining + 'h : ' + minutesRemaining + 'm : ' + secondsRemaining + 's'
    } else {
      approximateTime = minutesRemaining + 'm : ' + secondsRemaining + 's'
    }
    const ship = {
      imageURL : 'assets/images/ships/'+id+'.png',
      desc : this._translateService.instant('dashboard.ships.'+ metaData.name.toLowerCase()),
      id: id,
      civId: infoMint.civId,
      name: metaData.name,
      description: metaData.description,
      quantity: +balance,
      size: 0,
      sizeValue: metaData.size,
      attack: metaData.att,
      defense: metaData.def,
      speed: metaData.speed,
      resistance: metaData.resistance,
      hold: metaData.hold,
      kmHydro: metaData.kmHydro,
      timeBuild: +timeBuildSingle,
      time: approximateTime,
      approximateTime: approximateTime,
      src: infoMint.uri,
      srcDisabled: infoMint.uri,
      level: infoMint.level,
      maxSupply: infoMint.maxSupply,
      priceFDC: infoMint.fdcPrice,
    }
    this.ships.push(ship)
    if (id === this.allShipsLength - 1 && this.ships.length > 0) {
      setTimeout(() => {
        this.shipsFiltered = this.ships.filter((data) => data.civId === this.civId)
        this.ship = this.shipsFiltered[0]
        this.selectFaction(1)
        if (this.levelAR >= this.ships[0].level) {
          this.nameSelected = this.ship.name;
        }
        this.loading = false;
        this.noPlanet = true;
      }, 1000)
    }
  }

  async claimMultipleShips(ships:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    const indexShips = []
    for(let data of ships){
      if(this.dateNow > data.endUpdateAllShips){
        indexShips.push(data.index)
      }
    }
    if(indexShips.length >0 && indexShips.length !== ships.length){
      this.loadClaimAll = true;
      this._contractService.writeContract(environment.astraBuilding, buildingABI, 'claimShipBatch', [this.planetSelected.id, indexShips])
        .then(async (chargeProject) => {
          const charge = await chargeProject.wait();
          if(charge){
            setTimeout(() => {
              this.loadClaimAll = false;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.ships_claimed'))
              this._planetService.getInfoPlanetShip(this.planetSelected.id, this.address).subscribe({
                next: (ship) => {
                  this.allShips = this._planetService.createShips(ship.infoShip)
                  this.checkUpdate()
                  this.getShipsInUpdate(ship.buildShip)
                  this.levelAR = ship.levelArmory
                  this.allShipsLength = ship.infoShip.length
                  if (ship.availabilityArmory > 0) {
                    this.isArmoryAvailable = false
                  }
                  if (ship.availabilityLab > 0) {
                    this.isLaboratoryAvailable = false
                  }
                  this.getShips(ship, ship.shipBalance, this.allShipsLength)
                }
              })
            }, 1000)
          }
        })
        .catch((err) => {
          this.loadClaimAll = false;
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          if (data.reason.includes('claim not available')) {
            this._notifierService.showNotificationError(this._translateService.instant('notification.claim_not_available'))
          }
          this.loadingClaim = false;
        });
    } else if(indexShips.length === ships.length){
      this.loadClaimAll = true;
      this._contractService.writeContract(environment.astraBuilding, buildingABI, 'claimAllShip', [this.planetSelected.id])
        .then(async (chargeProject) => {
          const charge = await chargeProject.wait();
          if(charge){
            setTimeout(() => {
              this.loadClaimAll = false;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.ships_claimed'))
              this._planetService.getInfoPlanetShip(this.planetSelected.id, this.address).subscribe({
                next: (ship) => {
                  this.allShips = this._planetService.createShips(ship.infoShip)
                  this.checkUpdate()
                  this.getShipsInUpdate(ship.buildShip)
                  this.levelAR = ship.levelArmory
                  this.allShipsLength = ship.infoShip.length
                  if (ship.availabilityArmory > 0) {
                    this.isArmoryAvailable = false
                  }
                  if (ship.availabilityLab > 0) {
                    this.isLaboratoryAvailable = false
                  }
                  this.getShips(ship, ship.shipBalance, this.allShipsLength)
                }
              })
            }, 1000)
          }
        })
        .catch((err) => {
          this.loadClaimAll = false;
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          if (data.reason.includes('claim not available')) {
            this._notifierService.showNotificationError(this._translateService.instant('notification.claim_not_available'))
          }
          this.loadingClaim = false;
        });
    }
  }

  getFinishBuild(){
    const indexShips = []
    for(let data of this.arrayShips){
      if(this.dateNow > data.endUpdateAllShips){
        indexShips.push(data.index)
      }
    }

    return indexShips.length > 0
  }
  nextCard(){
    if(this.nextShips){
      return
    }
    this.type = 'next';
    this.nextShips = true;
    const index = this.shipsFiltered.findIndex((data)=> data.name === this.ship.name)
    if(index === this.shipsFiltered.length -1){
      this.ship = this.shipsFiltered[0]
      this.nameSelected = this.ship.name;
      this.selectCard(this.ship.name,this.ship)
    } else {
      this.ship = this.shipsFiltered[index +1]
      this.nameSelected = this.ship.name;
      this.selectCard(this.ship.name,this.ship)
    }
  }

  backCard(){
    if(this.nextShips){
      return
    }
    this.type = 'back';
    this.nextShips = true;
    const index = this.shipsFiltered.findIndex((data)=> data.name === this.ship.name)
    if(index === 0){
      this.ship = this.shipsFiltered[this.shipsFiltered.length -1]
      this.nameSelected = this.ship.name;
      this.selectCard(this.ship.name,this.ship)
    } else {
      this.ship = this.shipsFiltered[index -1]
      this.nameSelected = this.ship.name;
      this.selectCard(this.ship.name,this.ship)
    }
  }

  setDoneAnimation(e: boolean){
    this.nextShips = e;
  }
  openDescription(description:string){
    this._dialog.open(DialogDescriptionShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:description,
    })
  }
  setClaimMystery(){
    this._dialog.open(DialogClaimOldMysteryComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data: {oldMystery: this.oldMystery, address: this.address, mysteryToReveal:this.mysteryToReveal},
    }).afterClosed().subscribe( (res:any) => {
      if (res && res.data) {

      }
    })
  }

  viewShips(ships:any){
    this._dialog.open(DialogViewShipsMintedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.allShips},
    })
  }

  getTimeBuild(data:any){
    const hours = Math.floor(data / 3600);
    const minutes = Math.floor((data % 3600) / 60);
    const remainingSeconds = data % 60;
    let formatData = hours + "h " + minutes + "m " + remainingSeconds + "s"
    if(hours === 0){
      formatData = minutes + "m " + remainingSeconds + "s"
    }
    return formatData;
  }

}
