<div *ngIf="!loading" class="flex flex-col px-0 sm:px-2 md:flex-row w-full h-full items-start justify-between gap-10">
  <div class="flex flex-col bg-tab w-full !h-full md:w-1/2 gap-10 mr-4 xl:mr-10 2xl:mr-20 ">
    <div class="w-full h-56 md:!h-full background_image" [ngStyle]="{background:'url(../../../../../assets/images/structure/farming.png)'}">
    </div>
  </div>
  <div class="flex flex-col w-full h-full md:w-1/2 gap-8">
    <label class="title text-white">{{'dashboard.farming.title'|translate}}</label>
    <form
      [formGroup]="farmingForm"
      (ngSubmit)="farmingForm.get('amount')?.value > farming.allowanceLP ? approveFarm() : farmToken(true)"
      class="flex flex-col w-full items-start gap-4"
    >
      <div class="flex flex-col w-full max-w-md items-start gap-2">
        <label class="subtitle text-white">{{'dashboard.farming.subtitle'|translate}}</label>
        <label class="subtitle text-white">{{'dashboard.farming.subtitle_deposit'|translate}}</label>
      </div>
      <div class="flex flex-row w-full max-w-sm items-center justify-start gap-2 bg-light-black text-white p-2 h-9 mt-4">
        <input class="bg-transparent p-2 w-full outline-0" type="number" [placeholder]="'LP'" formControlName="amount"/>
        <div class="w-10 bg-light-purple px-2 py-1 text-white desc-s cursor-pointer" (click)="setMatBalance()">Max</div>
      </div>
      <label class="subtitle text-white">{{'dashboard.farming.balance'|translate}}: {{LP | currency: '':'':'1.2-3'}}</label>
      <div *ngIf="farmingForm.get('amount')?.value > farming.allowanceLP" class="flex flex-col w-full gap-2 items-start mt-4">
        <button *ngIf="!loadAddToFarming" [disabled]="farmingForm.get('amount')?.value <= 0 || farmingForm.get('amount')?.value > LP || farming.isUnderAttack" [class]="farmingForm.get('amount')?.value > 0 && farmingForm.get('amount')?.value <= LP && !farming.isUnderAttack ? 'button !m-0 text-white bg-purple cursor-pointer':'button !m-0 text-white bg-dark-black opacity-60 '" type="submit">{{'dashboard.farming.approve'|translate}}</button>
        <button *ngIf="loadAddToFarming" class="button text-white bg-purple flex items-center justify-center !m-0">
          <mat-spinner [diameter]="20"></mat-spinner>
        </button>
      </div>
      <div *ngIf="farmingForm.get('amount')?.value <= farming.allowanceLP" class="flex flex-col w-full gap-2 items-start mt-4">
        <button *ngIf="!loadAddToFarming" [disabled]="farmingForm.get('amount')?.value === 0 || farmingForm.get('amount')?.value > LP || farming.isUnderAttack" [class]="farmingForm.get('amount')?.value > 0 && farmingForm.get('amount')?.value <= LP && !farming.isUnderAttack ? 'button !m-0 text-white bg-purple cursor-pointer':'button !m-0 text-white bg-dark-black opacity-60 '" type="submit">{{'dashboard.farming.deposit'|translate}}</button>
        <button *ngIf="loadAddToFarming" class="button text-white bg-purple flex items-center justify-center !m-0">
          <mat-spinner [diameter]="20"></mat-spinner>
        </button>
      </div>
    </form>
    <div class="flex flex-col w-full container_desc max-w-sm px-6 py-4 gap-4">
      <div class="flex flex-row items-center justify-between">
        <label class="title text-white">{{'dashboard.farming.info'|translate}}</label>
        <button *ngIf="!loadClaimReward" [disabled]="farming.depositedLP === 0 || farming.isUnderAttack " [class]="farming.depositedLP > 0 && !farming.isUnderAttack ? 'button_war !m-0 text-white bg-purple cursor-pointer':'button_war !m-0 text-white bg-dark-black opacity-60 '" (click)="claimReward()">{{'dashboard.farming.withdraw_rewards'|translate}}</button>
        <button *ngIf="loadClaimReward" class="button_war text-white bg-purple flex items-center justify-center !m-0">
          <mat-spinner [diameter]="20"></mat-spinner>
        </button>
      </div>
      <div class="flex flex-col w-full gap-2">
        <label class="subtitle text-light-purple">{{'dashboard.farming.total_deposited'|translate}}:  <label class="desc-h text-white">{{this.farming.totalDepositedLP | currency: '':'':'1.2'}} LP</label></label>
        <label class="subtitle text-light-purple">{{'dashboard.farming.deposited'|translate}}:  <label class="desc-h text-white">{{this.farming.depositedLP | currency: '':'':'1.2'}} LP</label></label>
        <label class="subtitle text-light-purple">{{'dashboard.farming.reward'|translate}}:  <label class="desc-h text-white">{{this.farming.pendingReward | currency: '':'':'1.2'}} FDC</label></label>
        <label class="subtitle text-light-purple">{{'dashboard.farming.fee'|translate}}:  <label class="desc-h text-white">{{this.farming.pendingRewardWithFee | currency: '':'':'1.2'}} FDC</label></label>
        <label class="subtitle text-light-purple">{{'dashboard.farming.percentage_fees'|translate}}:  <label class="desc-h text-white">{{this.farming.percentageFeeWithdraw}} %</label></label>
        <label class="subtitle text-light-purple">{{'dashboard.farming.withdrawFee'|translate}}:  <label class="desc-h text-white">{{this.farming.amountFeeWithdraw | currency: '':'':'1.2'}} FDC</label></label>
        <!-- TODO -->
        <label class="subtitle text-light-purple">{{'dashboard.farming.score_def'|translate}}:  <label class="desc-h text-white">{{this.farming.scoreFarming | currency: '':'':'1.2'}} ( {{0.5 | currency: '':'':'1.1'}} {{'dashboard.farming.points_per_%'|translate}} )</label></label>
      </div>
      <label *ngIf="farming.isUnderAttack" class="subtitle text-red">{{'dashboard.farming.is_under_attack'|translate}}</label>
      <button *ngIf="!loadWithdraw" [disabled]="farming.depositedLP === 0 || farming.isUnderAttack" [class]="farming.depositedLP > 0 && !farming.isUnderAttack ? 'button !m-0 text-white bg-purple cursor-pointer':'button !m-0 text-white bg-dark-black opacity-60 '" (click)="Withdraw()">{{'dashboard.farming.withdraw'|translate}}</button>
      <button *ngIf="loadWithdraw" class="button text-white bg-purple flex items-center justify-center !m-0">
        <mat-spinner [diameter]="20"></mat-spinner>
      </button>
    </div>
  </div>
</div>
<div *ngIf="loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
