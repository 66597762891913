import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {RPC_URL} from "../../../../core/utils/constant";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {AccountService} from "../../../../core/services/account.service";
import {PlanetService} from "../../../../core/services/planet.service";
import {ethers} from "ethers";
import {NotifierService} from "../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogViewShipsMintedComponent} from "../../components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogDescriptionShipsComponent} from "../../components/dialog/dialog-description-ships/dialog-description-ships.component";
import {DialogViewShipsDestroyedComponent} from "../../components/dialog/dialog-view-ships-destoyed/dialog-view-ships-destroyed.component";
import {DialogForceRevealComponent} from "../../components/dialog/dialog-force-reveal/dialog-force-reveal.component";
const battleABI = require('src/app/core/abi/AstraBattle.json');
const FDCAbi = require('src/app/core/abi/FederationCredits.json');

@Component({
  selector: 'app-war',
  templateUrl: './war.component.html',
  styleUrls: ['./war.component.scss']
})

export class WarComponent implements OnInit{
  loading:boolean=false;
  newBattle:boolean=false;
  loadSend:boolean=false;
  loadConfirm:boolean=false;
  loadReveal:boolean=false;
  loadRevoke:boolean=false;
  loadEmergencyRevoke:boolean=false;
  loadEmergencyRevokeDef:boolean=false;
  checkedIncludeRevoked:boolean=false;
  loadForceReveal:boolean=false;
  calcStat:boolean=false;
  fleetAttack:any[]=[];
  battleOffOncoming:any[]=[];
  battleOffOld:any;
  battleRevokedOffOld:any;
  battleDefOncoming:any[]=[];
  battleDefOld:any;
  battleRevokedDefOld:any;
  filterBattle:any;
  dateNow:number = 0;
  type:string='all';
  typeSort:string='up';
  typeSortProb:string='up';
  sortBattle:string='now';
  viewInfoBattle:any;
  viewInfoBattleDef:any;
  viewAllShips:any;
  searchId:any;
  mapPlanets:any;
  maxSizeFleet:any;
  address:any;
  myPlanets:any
  infoPlanetToAttack:any
  fleets:any
  noPlanet:any;
  planetSelected:any;
  battleID:any;
  HYD:any;
  innerWidth:any;
  maxBattle:any;
  maxBattlePage:any;
  filterBattlePage:any;
  page:number=0;
  planetForm: FormGroup = new FormGroup({});
  selectForm: FormGroup = new FormGroup({});

  constructor(
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog,
    private _contractService: ContractService,
    private _accountService: AccountService,
    public _planetService: PlanetService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.planetForm = this._formBuilder.group({
      id: ['', Validators.required],
    });
    this.selectForm = this._formBuilder.group({
      select: [''],
    });
    this.getData();
  }
  getData() {
    this.innerWidth = window.innerWidth;
    this.dateNow = Math.floor(Date.now()/1000)
    this.loading = true;
    this._accountService.getWalletAddress().subscribe({
      next: (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getBalance().subscribe({
            next: (balance) => {
              if(this.HYD === undefined){
                this.HYD = balance.balanceHYD
                this.updatePlanetFleet()
              }
            }
          })
        }
      }
    })
    setInterval(()=>{
      this.dateNow = Math.floor(Date.now()/1000)
    },1000)
  }

  setBattle(typeSort:any,type:any,from:any,page:any){
    this.checkedIncludeRevoked = false;
    this.sortBattle = typeSort
    this.type = type
    this.page = page
    if(typeSort === 'old'){
      if(this.type === 'off'){
        this._planetService.getBattleListRevealedOldOff(this.planetSelected.id,from).subscribe({
          next: (info) => {
            this.maxBattle = info.maxBattle;
            this.maxBattlePage = [];
            for (let i = 0; i < this.maxBattle / 5; i++) {
              this.maxBattlePage.push(i);
            }
            const filterBattlePage = this.maxBattlePage
            if(this.page + 5 > this.maxBattle){
              this.filterBattlePage = filterBattlePage.slice(this.page,this.maxBattle)
            } else {
              this.filterBattlePage = filterBattlePage.slice(this.page,this.page +5)
            }
            this.battleOffOld = this.getPlanetBattle(info.battleInfo);
            this.battleOffOld = this.battleOffOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
              return b.timeRevealed - a.timeRevealed;
            });
            this.filterBattle = this.battleOffOld
          }
        })
      } else if(this.type === 'def'){
        this._planetService.getBattleListRevealedOldDef(this.planetSelected.id,from).subscribe({
          next: (info) => {
            this.maxBattle = info.maxBattle;
            this.maxBattlePage = [];
            for (let i = 0; i < this.maxBattle / 5; i++) {
              this.maxBattlePage.push(i);
            }
            const filterBattlePage = this.maxBattlePage
            if(this.page + 5 > this.maxBattle){
              this.filterBattlePage = filterBattlePage.slice(this.page,this.maxBattle)
            } else {
              this.filterBattlePage = filterBattlePage.slice(this.page,this.page +5)
            }
            this.battleDefOld = this.getPlanetBattle(info.battleInfo);
            this.battleDefOld = this.battleDefOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
              return b.timeRevealed - a.timeRevealed;
            });
            this.filterBattle = this.battleDefOld
          }
        })
      }
    } else {
      const planetConfirmedToReveal = this.battleOffOncoming.filter((data:any)=> data.confirmed)
      if(planetConfirmedToReveal && planetConfirmedToReveal.length >0){
        this.checkEvent(planetConfirmedToReveal[0])
      }
      if(type === 'off'){
        this.filterBattle = this.battleOffOncoming
      } else if(type === 'def'){
        this.filterBattle = this.battleDefOncoming
      } else if(type === 'all'){
        this.filterBattle = this.battleDefOncoming.concat(this.battleOffOncoming)
      }
      this.sortTypeWar(this.typeSort)
    }
  }

  checkIncludeRevokedBattleOld(){
    if(!this.checkedIncludeRevoked && (this.battleRevokedOffOld === undefined || this.battleRevokedDefOld === undefined)){
      this.checkedIncludeRevoked = true;
      if(this.type === 'off'){
        this._planetService.getBattleListRevokedOldOff(this.planetSelected.id).subscribe({
          next: (revoked) => {
            if(revoked !== null && revoked.length >0){
              this.battleRevokedOffOld = this.getPlanetBattle(revoked);
              this.battleOffOld = this.battleOffOld.concat(this.battleRevokedOffOld)
            }
            this.battleOffOld = this.battleOffOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
              return b.timeRevealed - a.timeRevealed;
            });
            this.filterBattle = this.battleOffOld
          }
        })
      } else if(this.type === 'def') {
        this._planetService.getBattleListRevokedOldDef(this.planetSelected.id).subscribe({
          next: (revoked) => {
            if(revoked !== null && revoked.length >0){
              this.battleRevokedDefOld = this.getPlanetBattle(revoked);
              this.battleDefOld = this.battleDefOld.concat(this.battleRevokedDefOld)
            }
            this.battleDefOld = this.battleDefOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
              return b.timeRevealed - a.timeRevealed;
            });
            this.filterBattle = this.battleDefOld
          }
        })
      }
    } else if (!this.checkedIncludeRevoked && (this.battleRevokedOffOld !== undefined || this.battleRevokedDefOld !== undefined)){
      this.checkedIncludeRevoked = true;
      if(this.battleRevokedOffOld !== null && this.battleRevokedOffOld !== undefined){
        this.battleOffOld = this.battleOffOld.concat(this.battleRevokedOffOld)
      }
      if(this.battleRevokedDefOld !== null && this.battleRevokedDefOld !== undefined){
        this.battleDefOld = this.battleDefOld.concat(this.battleRevokedDefOld)
      }
      if(this.type === 'off'){
        this.battleOffOld = this.battleOffOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
          return b.timeRevealed - a.timeRevealed;
        });
        this.filterBattle = this.battleOffOld
      } else if(this.type === 'def'){
        this.battleDefOld = this.battleDefOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
          return b.timeRevealed - a.timeRevealed;
        });
        this.filterBattle = this.battleDefOld
      }
    } else {
      this.checkedIncludeRevoked = false;
      if(this.type === 'off'){
        this.battleOffOld = this.battleOffOld.filter((data:any)=> !data.revoked)
        this.battleOffOld = this.battleOffOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
          return b.timeRevealed - a.timeRevealed;
        });
        this.filterBattle = this.battleOffOld
      } else if(this.type === 'def'){
        this.battleDefOld = this.battleDefOld.filter((data:any)=> !data.revoked)
        this.battleDefOld = this.battleDefOld.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
          return b.timeRevealed - a.timeRevealed;
        });
        this.filterBattle = this.battleDefOld
      } else if(this.type === 'all'){
        let battle = this.battleDefOld.concat(this.battleOffOld)
        this.filterBattle = battle.sort(function (a: { timeRevealed: number }, b: { timeRevealed: number }) {
          return b.timeRevealed - a.timeRevealed;
        });
      }
    }
  }

  openNewBattle(){
    this.newBattle = true;
    this._planetService.getInfoMap().subscribe({
      next:(mapPlanets) => {
        if(mapPlanets && mapPlanets.coordinate){
          this.mapPlanets = mapPlanets.coordinate
        }
      }
    })
  }
  setParams(id:any){
    if(id !== null){
      this.newBattle = true;
      this.planetForm.get('id')?.setValue(id)
      this.searchPlanet()
    }
  }

  updatePlanetFleet(){
    let planetSelected = localStorage.getItem(this.address+'planetSelected')
    this._planetService.getPlanet().subscribe({
      next: (planets) => {
        if (planets && planets.length > 0) {
          this.myPlanets = planets
          const planet = planets.filter((data) => data.name === planetSelected)
          if(planet && planet.length >0){
            this.planetSelected = planet[0]
            this._planetService.getInfoPlanetBattle(this.planetSelected.id).subscribe({
              next: async (info) => {
                if(info !== null){
                  this.viewAllShips = this._planetService.createShips(info.infoShip)
                  const fleets = info.fleetOff
                  await this.updateFleet(fleets);
                  this.battleOffOncoming = this.getPlanetBattle(info.battleOffOncoming)
                  this.battleDefOncoming = this.getPlanetBattle(info.battleDefOncoming)
                  this.filterBattle = [];
                  const sortBattle = localStorage.getItem('sortBattle')
                  if(sortBattle !== null){
                    this.sortBattle = sortBattle
                    if(sortBattle === 'old'){
                      this.type = 'off'
                      localStorage.removeItem('sortBattle')
                      this.setBattle(this.sortBattle,this.type,0,0)
                    } else {
                      this.setBattle(this.sortBattle,this.type,0,0)
                    }
                  } else {
                    this.setBattle(this.sortBattle,this.type,0,0)
                  }
                  this.setParams(this._route.snapshot.paramMap.get('id'))
                  this.HYD = undefined;
                  this._planetService.readBalance(this.address).subscribe({
                    next:(info)=>{
                      if(info && this.HYD === undefined){
                        this.HYD = info.balanceHYD / 10 ** info.decimalsHYD
                        const balance = {
                          balanceHYD: info.balanceHYD / 10 ** info.decimalsHYD,
                          balanceFDC: info.balanceFDC / 10 ** info.decimalsFDC,
                          decimalsFDC: info.decimalsFDC,
                          decimalsHYD: info.decimalsHYD,
                        }
                        this._planetService.setBalance(balance)
                      }
                    },
                    error:(err)=>{
                    }
                  })
                }
              }
            })
          }
        } else {
          this.noPlanet = true;
          this.loading = false;
        }
      }
    });
  }

  getPlanetBattle(battle:any){
    const battleStatus:any[] = []
    for(let i = 0; i < battle.length; i ++){
      const infoBattle = battle[i]
      let amountBurnDef = 0;
      let amountBurnOff = 0;
      if(infoBattle.fleetBurnDef !== null){
        for(let data of infoBattle.fleetBurnDef){
          for(let amounts of data.amountsBurn){
            amountBurnDef = amountBurnDef + amounts
          }
        }
      }
      if(infoBattle.fleetBurnOff !== null){
        for(let data of infoBattle.fleetBurnOff){
          for(let amounts of data.amountsBurn){
            amountBurnOff = amountBurnOff + amounts
          }
        }
      }
      const data = {
        index:i,
        id:infoBattle.id,
        speedMin:infoBattle.stats.speedMin,
        totAtt:infoBattle.stats.totAtt,
        totDef:infoBattle.stats.totDef,
        resistanceOff:infoBattle.stats.resistanceOff,
        resistanceDef:infoBattle.stats.resistanceDef,
        totHold:this._planetService.formatBigNumber(infoBattle.stats.totHold),
        fleetBurnDef: infoBattle.fleetBurnDef,
        amountsBurnDef: amountBurnDef,
        fleetBurnOff: infoBattle.fleetBurnOff,
        amountsBurnOff: amountBurnOff,
        planetIdOff:infoBattle.planetIdOff,
        planetIdDef:infoBattle.planetIdDef,
        kmHydro:infoBattle.stats.kmHydro / 10**2,
        confirmed:infoBattle.status.confirmed,
        revealed:infoBattle.status.revealed,
        revoked:infoBattle.status.revoked,
        canDefRevoke:infoBattle.canDefRevoke,
        rewardStolen:this._planetService.formatBigNumber(infoBattle.status.rewardStolen),
        startBattle:infoBattle.status.startBattle,
        returnTime:infoBattle.returnTime,
        randomNumber:infoBattle.randomNumber,
        winProbability:infoBattle.winProbability,
        battleDelay:infoBattle.battleDelay,
        isDefInPhaseReveal:infoBattle.isDefInPhaseReveal,
        infoReveal:infoBattle.infoReveal,
        planetNameDef:infoBattle.planetNameDef,
        planetNameOff:infoBattle.planetNameOff,
        timeRevealed:infoBattle.timeRevealed,
        scoreBattle:infoBattle.scoreBattle,
        deltaWin:infoBattle.deltaWin,
        offWin:infoBattle.winProbability >= infoBattle.randomNumber,
        defWin:infoBattle.winProbability < infoBattle.randomNumber,
      }
      battleStatus.push(data)
    }
    return battleStatus
  }

  async updateFleet(fleetOff:any) {
    if(this.planetSelected && this.planetSelected.id){
      let fleets: any[] = [];
      if(fleetOff && fleetOff.length >0){
        for (let i = 0; i < fleetOff.length; i++) {
          const fleetData = {
            name: 'fleet_att',
            name_filter: 'fleet_att_' + fleetOff[i].indexFleet,
            index: fleetOff[i].indexFleet,
            type: 'att',
            id: fleetOff[i].id,
            resistance: fleetOff[i].resistance,
            speedMin: fleetOff[i].speedMin,
            totalAtt: fleetOff[i].totAtt,
            totalDef: fleetOff[i].totDef,
            totalFuelCons: this._planetService.formatBigNumber(fleetOff[i].totFuelCons),
            totalHold: this._planetService.formatBigNumber(fleetOff[i].totHold),
            totalSize: fleetOff[i].totSize,
            shipsType: fleetOff[i].shipsType,
            amounts: fleetOff[i].amounts,
          }
          fleets.push(fleetData)
        }
        fleets = fleets.sort(function (
          a: { index: number },
          b: { index: number }
        ) {
          return a.index - b.index;
        });
        this.fleets = fleets.filter((data)=> data.totalAtt > 0);
        this.loading= false;
      }else {
        this.loading= false;
      }
    }
  }

  addShipToAttack(fleet:any){
    this.fleetAttack.push(fleet)
    this.updateShipsData();
  }
  removeShipsToAttack(index:any){
    this.fleetAttack.splice(index,1)
    this.updateShipsData();
  }

  updateShipsData(){
    let totShips = 0;
    let totalAtt = 0;
    let totHold = 0;
    let totResistance = 0;
    for(let fleet of this.fleetAttack){
      totalAtt = totalAtt + fleet.totalAtt
      totResistance = totResistance + fleet.resistance
      totHold = totHold + fleet.totalHold
      for(let amount of fleet.amounts){
        totShips = totShips + Number(amount)
      }
    }
    this.calcStats(totShips,totalAtt,totHold,totResistance);
  }
  async searchPlanet() {
    if(this.myPlanets === undefined){
      this._notifierService.showNotificationError(this._translateService.instant('notification.no_planet_to_attack'))
      return
    }
    const isMyPlanet = this.myPlanets.filter((planet:any)=> planet.id === Number(this.planetForm.get('id')?.value) || planet.name === this.planetForm.get('id')?.value)
    if(isMyPlanet && isMyPlanet.length > 0){
      this._notifierService.showNotificationError(this._translateService.instant('notification.planet_owner_same'))
      return
    }
    this.fleetAttack = [];
    this.viewInfoBattle = undefined;
    if(this.planetForm.get('id')?.value !== this.searchId){
      let id = Number(this.planetForm.get('id')?.value)
      if(isNaN(id)){
        const planet = this.mapPlanets.filter((planet:any)=> planet.name === this.planetForm.get('id')?.value)
        id = planet[0].id
      }
      this._router.navigate(['/dashboard/war/'+id])
      this.searchId = id
      this._planetService.getInfoPlanetToAttack(id,this.planetSelected.id).subscribe({
        next:(res)=>{
          if(res.planetName === ""){
            this.infoPlanetToAttack = undefined;
            this._notifierService.showNotificationError(this._translateService.instant('notification.planet_does_not_exist'))
          } else {
            this.infoPlanetToAttack = {
              id: id,
              coordinate: res.coordinate,
              def: res.totalDef,
              fleetDef: res.fleetDef,
              levelShield: res.levelShield,
              percentageFeeWithdraw: res.percentageFeeWithdraw,
              deltaLevelPlanet: res.deltaLevelPlanet,
              maxRewardToStole: this._planetService.formatBigNumber(res.maxRewardToStole),
              distance: res.distance,
              name: res.planetName,
              power: res.power,
              rewardFarming: this._planetService.formatBigNumber(res.rewardFarming),
              planetsCivilizationId: res.civ,
              isProtected: res.isPlanetProtected,
              protectedTime: res.protectedTime,
            }
            this._planetService.getPreviewBattle(this.planetSelected.id,this.infoPlanetToAttack.id,[]).subscribe({
              next:async (res) => {
                this.viewInfoBattleDef = {
                  resistanceDef : res.stats.resistanceDef,
                  totDef : res.stats.totDef,
                };
              }
            })
          }
        }
      })
    }
  }

  cancelAttack(){
    this.newBattle = false;
    this.infoPlanetToAttack = undefined;
    this.searchId = undefined
    this.planetForm.get('id')?.setValue('')
    this._router.navigate(['/dashboard/war'])
  }

  async calcStats(totShips:any,totalAtt:any,totHold:any,totResistance:any,) {
    this.calcStat = true;
    const listFleetId = []
    let timestamp = Math.floor(Date.now()/1000);
    for(let fleet of this.fleetAttack){
      listFleetId.push(fleet.index)
    }
    if(listFleetId.length >0){
      this._planetService.getPreviewBattle(this.planetSelected.id,this.infoPlanetToAttack.id,listFleetId).subscribe({
        next:async (res) => {
          this.viewInfoBattle = {
            planetIdOff: '',
            resistanceDef : res.stats.resistanceDef,
            resistanceOff : res.stats.resistanceOff,
            speedMin : res.stats.speedMin,
            maxRewardToStole : this._planetService.formatBigNumber(res.maxRewardToStole),
            deltaLevelPlanet : res.deltaLevelPlanet,
            probabilitiesToWin : res.winProbability,
            totAtt : res.stats.totAtt,
            totDef : res.stats.totDef,
            totShips : totShips,
            totResistance : res.stats.resistanceOff,
            totalHold : this._planetService.formatBigNumber(res.stats.totHold),
            kmHydro : res.stats.kmHydro / 10 ** 2,
            estimatedTime : res.stats.kmHydro > 0 ? this.getApproximateTime(res.infoTravel.timeTravel) : '0h: 0m: 0s',
            totalFuelCons : res.stats.kmHydro > 0 ? this._planetService.formatBigNumber(res.infoTravel.fuelConsTravel) : 0,
          };
          this.dateNow = res.stats.kmHydro > 0 ? timestamp + res.infoTravel.timeTravel : Math.floor(Date.now() / 1000);
          this.calcStat = false;
        }
      })
    } else {
      this.viewInfoBattle = {
        planetIdOff:'',
        resistanceDef : 0,
        resistanceOff : 0,
        speedMin : 0,
        maxRewardToStole : 0,
        deltaLevelPlanet : 0,
        probabilitiesToWin : 0,
        totAtt : 0,
        totDef : 0,
        totAttack : totalAtt,
        totShips : totShips,
        totResistance : totResistance,
        totalHold : this._planetService.formatBigNumber(totHold),
        kmHydro :0,
        estimatedTime : '0h: 0m: 0s',
        totalFuelCons : 0,
      };
      this.dateNow = Math.floor(Date.now() / 1000);
      this.calcStat = false;
    }
  }

  getApproximateTime(time:any){
    let approximateTime = '';
    let hoursRemaining = Math.floor(time/ 3600);
    let minutesRemaining = Math.floor((time % 3600) / 60);
    let secondsRemaining = time % 60;
    if (hoursRemaining > 0) {
      approximateTime = hoursRemaining + 'h : ' + minutesRemaining + 'm : ' + secondsRemaining + 's'
    } else {
      approximateTime = minutesRemaining + 'm : ' + secondsRemaining + 's'
    }
    return approximateTime;
  }

  async sendAttack() {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    if(this.viewInfoBattle.totalFuelCons > this.HYD){
      this._notifierService.showNotificationError(this._translateService.instant('notification.no_hydro_balance'))
      return
    }
    this.loadSend = true;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner(this.address);
    let blockNumber = await provider.getBlockNumber();
    let block = await provider.getBlock(blockNumber);
    let deadline = block.timestamp + 60;
    let value = ethers.utils.parseEther(String(this.viewInfoBattle.totalFuelCons))
    let name = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'name')
    let nonces = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'nonces', [signer._address])
    const fleetIndex: any[] = []
    for(let fleet of this.fleetAttack){
      fleetIndex.push(fleet.index)
    }
    this._planetService.getPermitSignature(signer, nonces, environment.hydrogen, environment.astraBattle, value, deadline, name)
      .then(async (signature) => {
        this._contractService.writeContract(environment.astraBattle, battleABI, 'sendAttack', [this.planetSelected.id, this.infoPlanetToAttack.id, fleetIndex, deadline, signature.v,  signature.r, signature.s])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadSend = false;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.attack_sent'))
              this.cancelAttack()
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            if(data.reason.includes('planet def is protected')){
              this._notifierService.showNotificationError(this._translateService.instant('notification.planet_is_protected'))
            } else if(data.reason.includes('cannot send attack if owner planet def is equal owner planet off')){
              this._notifierService.showNotificationError(this._translateService.instant('notification.planet_owner_same'))
            }
            this.loadSend = false;
          });
      }).catch((err) => {
        this.loadSend = false;
    });

  }

  async confirmAttack(battle:any,indexBattle:any) {
    if(!(this.dateNow < battle.startBattle || (this.battleID === battle.id || this.battleID !== undefined))){
      let amountHydroToConfirm = this._planetService.formatBigNumber(battle.battleDelay.amountHydroToConfirm)
      if(amountHydroToConfirm > this.HYD){
        this._notifierService.showNotificationError(this._translateService.instant('notification.no_hydro_balance_to_confirm'))
        return
      }
      const checkNetwork = await this._planetService.checkNetwork();
      if(checkNetwork){
        return
      }
      this.loadConfirm = true;
      this.battleID = battle.id;
      this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
        next: async (info) => {
          const amountHydro = ethers.utils.parseEther(String(amountHydroToConfirm))
          const planetIdOff = battle.planetIdOff
          const planetIdDef = battle.planetIdDef
          const indexBattleOff = indexBattle
          const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner(this.address);
          const blockNumber = await provider.getBlockNumber();
          const block = await provider.getBlock(blockNumber);
          const deadline = block.timestamp + 60;
          const name = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'name')
          const nonces = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'nonces', [signer._address])
          this._planetService.getPermitSignature(signer, nonces, environment.hydrogen, environment.astraBattle, amountHydro, deadline, name)
            .then(async (signature) => {
              this._contractService.writeContract(environment.astraBattle, battleABI, 'confirmAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef, deadline, signature.v,  signature.r, signature.s,amountHydro])
                .then(async (chargeProject) => {
                  const charge = await chargeProject.wait();
                  if(charge){
                    this.loadConfirm = false;
                    this.battleID = undefined;
                    battle.confirmed = true;
                    this._notifierService.showNotificationSuccess(this._translateService.instant('notification.attack_confirmed'))
                    this.checkEvent(battle)
                  }
                })
                .catch((err) => {
                  const error = JSON.stringify(err)
                  const data = JSON.parse(error)
                  this.loadConfirm = false;
                  this.battleID = undefined;
                });
            }).catch((err) => {
            this.loadConfirm = false;
            this.battleID = undefined;
          });
        },
        error:()=>{
          this.loadConfirm = false;
          this.battleID = undefined;
        }
      });
    }
  }

  async checkEvent(battle: any) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let contract = new ethers.Contract(environment.astraBattle, battleABI, provider);
    const filter = contract.filters["AttackRevealed"](battle.id);
    const listener = async (log:any) => {
      this.updatePlanetFleet();
    };
    contract.on(filter, listener);
    try {
      contract.on("AttackRevealed", (tokenId, tokenURI) => {
        this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reveal_confirmed'))
        //this._router.navigate(['/dashboard/war/off/old/'+battle.id])
      });
    } catch (error) {

    }
  }

  async emergencyRevokeAttack(battle:any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadEmergencyRevoke = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
      next: async (info) => {
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = indexBattle
        const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
        this._contractService.writeContract(environment.astraBattle, battleABI, 'emergencyRevokeAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadEmergencyRevoke = false;
              this.battleID = undefined;
              this.loading = true;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.revoke_confirmed'))
              setTimeout(()=>{
                this.updatePlanetFleet();
              },1000)
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this.loadEmergencyRevoke = false;
            this.battleID = undefined;
          });
      },
      error:()=>{
        this.loadEmergencyRevoke = false;
        this.battleID = undefined;
      }
    })
  }

  async emergencyRevokeAttackDef(battle: any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadEmergencyRevokeDef = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdOff).subscribe({
      next: async (info) => {
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = info.battleOffOncoming.findIndex((data: any) => data.id === battle.id)
        const indexBattleDef = indexBattle
        this._contractService.writeContract(environment.astraBattle, battleABI, 'emergencyRevokeAttack', [indexBattleOff, planetIdOff, indexBattleDef, planetIdDef])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadEmergencyRevokeDef = false;
              this.battleID = undefined;
              this.loading = true;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.revoke_confirmed'))
              setTimeout(() => {
                this.updatePlanetFleet();
              }, 1000)
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this.loadEmergencyRevokeDef = false;
            this.battleID = undefined;
          });
      },
      error: () => {
        this.loadEmergencyRevokeDef = false;
        this.battleID = undefined;
      }
    })
  }

  async revokeAttack(battle:any,indexBattle:any) {
    if(!(this.battleID === battle.id || this.battleID !== undefined)){
      let amountHydroToConfirm = this._planetService.formatBigNumber(battle.battleDelay.amountHydroToConfirm)
      if(amountHydroToConfirm > this.HYD){
        this._notifierService.showNotificationError(this._translateService.instant('notification.no_hydro_balance_to_confirm'))
        return
      }
      const checkNetwork = await this._planetService.checkNetwork();
      if(checkNetwork){
        return
      }
      this.loadRevoke = true;
      this.battleID = battle.id;
      this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
        next: async (info) => {
          const amountHydro = ethers.utils.parseEther(String(amountHydroToConfirm))
          const planetIdOff = battle.planetIdOff
          const planetIdDef = battle.planetIdDef
          const indexBattleOff = indexBattle
          const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner(this.address);
          const blockNumber = await provider.getBlockNumber();
          const block = await provider.getBlock(blockNumber);
          const deadline = block.timestamp + 60;
          const name = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'name')
          const nonces = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'nonces', [signer._address])
          this._planetService.getPermitSignature(signer, nonces, environment.hydrogen, environment.astraBattle, amountHydro, deadline, name)
            .then(async (signature) => {
              this._contractService.writeContract(environment.astraBattle, battleABI, 'revokeAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef, deadline, signature.v,  signature.r, signature.s,amountHydro])
                .then(async (chargeProject) => {
                  const charge = await chargeProject.wait();
                  if(charge){
                    this.loadRevoke = false;
                    this.battleID = undefined;
                    this.loading = true;
                    battle.revoked = true;
                    this._notifierService.showNotificationSuccess(this._translateService.instant('notification.revoke_confirmed'))
                    setTimeout(()=>{
                      this.updatePlanetFleet();
                    },1000)
                  }
                })
                .catch((err) => {
                  const error = JSON.stringify(err)
                  const data = JSON.parse(error)
                  this.loadRevoke = false;
                  this.battleID = undefined;
                });
            }).catch((err) => {
            this.loadRevoke = false;
            this.battleID = undefined;
          });
        },
        error:(err)=>{
          this.loadRevoke = false;
          this.battleID = undefined;
        }
      })
    }
  }

  async forceRevealAttack(battle:any) {
    if(!(this.battleID === battle.id || this.battleID !== undefined)){
      const checkNetwork = await this._planetService.checkNetwork();
      if(checkNetwork){
        return
      }
      this._dialog.open(DialogForceRevealComponent, {
        width: '90%',
        maxWidth: '500px',
        panelClass: 'css-dialog',
        disableClose: true,
      }).afterClosed().subscribe((res) => {
        if(res && res.data === 'confirmed'){
          this.loadForceReveal = true;
          this.battleID = battle.id;
          const indexBattleOff = battle.infoReveal.indexOffBattle;
          const planetIdOff = battle.infoReveal.planetIdOff;
          const indexBattleDef = battle.infoReveal.indexDefBattle;
          const planetIdDef = battle.infoReveal.planetIdDef;
          this._contractService.writeContract(environment.astraBattle, battleABI, 'revealAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
            .then(async (chargeProject) => {
              const charge = await chargeProject.wait();
              if(charge){
                this.loadForceReveal = false;
                this.battleID = undefined;
                this.loading = true;
                this.planetForm.get('id')?.setValue('')
                this.infoPlanetToAttack = undefined
                this.searchId = undefined
                this.newBattle = false;
                this._notifierService.showNotificationSuccess(this._translateService.instant('notification.force_reveal_confirmed'))
                setTimeout(()=>{
                  this.updatePlanetFleet();
                },1000)
              }
            })
            .catch((err) => {
              const error = JSON.stringify(err)
              const data = JSON.parse(error)
              this._notifierService.showNotificationError(this._translateService.instant('notification.wait_for_reveal'))
              this.loadForceReveal = false;
              this.battleID = undefined;
            });
        }
      })
    }
  }
  async revealAttack(battle:any,indexBattle:any) {
    if(!(this.battleID === battle.id || this.battleID !== undefined)){
      const checkNetwork = await this._planetService.checkNetwork();
      if(checkNetwork){
        return
      }
      this.loadReveal = true;
      this.battleID = battle.id;
      this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
        next: (info) => {
          const planetIdOff = battle.planetIdOff
          const planetIdDef = battle.planetIdDef
          const indexBattleOff = indexBattle
          const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
          this._contractService.writeContract(environment.astraBattle, battleABI, 'revealAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
            .then(async (chargeProject) => {
              const charge = await chargeProject.wait();
              if(charge){
                this.loadReveal = false;
                this.battleID = undefined;
                this.loading = true;
                this.planetForm.get('id')?.setValue('')
                this.infoPlanetToAttack = undefined
                this.searchId = undefined
                this.newBattle = false;
                this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reveal_confirmed'))
                setTimeout(()=>{
                  this.updatePlanetFleet();
                },1000)
              }
            })
            .catch((err) => {
              const error = JSON.stringify(err)
              const data = JSON.parse(error)
              this._notifierService.showNotificationError(this._translateService.instant('notification.wait_for_reveal'))
              this.loadReveal = false;
              this.battleID = undefined;
            });
        },
        error:()=>{
          this.loadReveal = false;
          this.battleID = undefined;
        }
      })
    }
  }

  async revealAttackDef(battle:any,indexBattle:any) {
    if(!(this.battleID === battle.id || this.battleID !== undefined)){
      const checkNetwork = await this._planetService.checkNetwork();
      if(checkNetwork){
        return
      }
      this.loadReveal = true;
      this.battleID = battle.id;
      this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
        next: (info) => {
          const planetIdOff = battle.planetIdOff
          const planetIdDef = battle.planetIdDef
          const indexBattleOff = info.battleOffOncoming.findIndex((data: any) => data.id === battle.id)
          const indexBattleDef = indexBattle
          this._contractService.writeContract(environment.astraBattle, battleABI, 'revealAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
            .then(async (chargeProject) => {
              const charge = await chargeProject.wait();
              if(charge){
                this.loadReveal = false;
                this.battleID = undefined;
                this.loading = true;
                this.planetForm.get('id')?.setValue('')
                this.infoPlanetToAttack = undefined
                this.searchId = undefined
                this.newBattle = false;
                this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reveal_confirmed'))
                setTimeout(()=>{
                  this.updatePlanetFleet();
                },1000)
              }
            })
            .catch((err) => {
              const error = JSON.stringify(err)
              const data = JSON.parse(error)
              this._notifierService.showNotificationError(this._translateService.instant('notification.wait_for_reveal'))
              this.loadReveal = false;
              this.battleID = undefined;
            });
        },
        error:()=>{
          this.loadReveal = false;
          this.battleID = undefined;
        }
      })
    }
  }

  viewShips(battle:any){
    const amountsBurn = [];
    const shipsType = [];
    for(let data of battle){
      for(let burn of data.amountsBurn){
        amountsBurn.push(burn)
      }
      for(let type of data.shipsType){
        shipsType.push(type)
      }
    }
    const ships = {
      amounts: amountsBurn, type: shipsType
    }
    this._dialog.open(DialogViewShipsDestroyedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.viewAllShips},
    })
  }

  viewInfoFleet(fleet:any){
    const ships = {
      amounts: fleet.amounts, type: fleet.shipsType
    }
    this._dialog.open(DialogViewShipsMintedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.viewAllShips},
    })
  }

  getDataTimer(timeToEnd:any):any{
    const today = new Date(timeToEnd *1000);
    let hh = today.getHours();
    let mm = today.getMinutes();
    const amOrPm = hh >= 12 ? 'PM' : 'AM';
    const formattedHours = hh % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${this.padZero(mm)} ${amOrPm}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  openDescription(key:string){
    this._dialog.open(DialogDescriptionShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:this._translateService.instant(key),
    })
  }

  updateData(){
    this.filterBattle = [];
    this._planetService.getInfoPlanetBattle(this.planetSelected.id).subscribe({
      next: async (info) => {
        if(info !== null){
          this.battleOffOncoming = this.getPlanetBattle(info.battleOffOncoming)
          this.battleDefOncoming = this.getPlanetBattle(info.battleDefOncoming)
          if(this.sortBattle === 'now'){
            this.setBattle(this.sortBattle,this.type,0,0)
          }
        }
      }
    })
    if(this.sortBattle === 'old'){
      this.setBattle(this.sortBattle,this.type,0,0)
    }
  }

  setPage(page: number) {
    let pageFrom;
    if(page>0 && page <= this.maxBattlePage.length -1){
      pageFrom = (page * 5) - 1
      this.setBattle(this.sortBattle,this.type,pageFrom,page)
    } else if(page === 0) {
      this.setBattle(this.sortBattle,this.type,0,page)
    }
  }

  goToPage(page:string){
    this._router.navigate([page])
    if(this.sortBattle === 'old'){
      localStorage.setItem('sortBattle',this.sortBattle)
    }
  }

  sortTypeWar(type:string){
    if(type === 'down'){
      this.typeSort = 'up';
      this.filterBattle = this.filterBattle.sort(function (a: { startBattle: number }, b: { startBattle: number }) {
        return b.startBattle - a.startBattle;
      });
    } else {
      this.typeSort = 'down';
      this.filterBattle = this.filterBattle.sort(function (a: { startBattle: number }, b: { startBattle: number }) {
        return a.startBattle - b.startBattle;
      });
    }
  }

  sortProbWar(type:string){
    if(type === 'down'){
      this.typeSortProb = 'up';
      this.filterBattle = this.filterBattle.sort(function (a: { winProbability: number }, b: { winProbability: number }) {
        return b.winProbability - a.winProbability;
      });
    } else {
      this.typeSortProb = 'down';
      this.filterBattle = this.filterBattle.sort(function (a: { winProbability: number }, b: { winProbability: number }) {
        return a.winProbability - b.winProbability;
      });
    }
  }
}
