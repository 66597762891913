<div class="flex flex-col justify-center gap-4 p-4 sm:p-5 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="subtitle md:title text-white">{{'dialog.mint_ships.title'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="closeCart()"/>
  </div>
  <div class="flex flex-col w-full sm:px-4 mt-4" >
    <div class="flex flex-col w-full gap-3 mt-5 overflow-x-auto h-52">
      <table class="table-responsive w-full max-w-7xl text-sm text-left shadow-none">
        <thead class="text-xs text-light-gray uppercase bg-transparent">
        <tr>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.NAME" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.FDC" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.TIME" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">
            {{"dialog.mint_ships.SELECT" | translate}}
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-auto">
            {{"dialog.mint_ships.TOTAL_SHIPS" | translate}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ship of ships; let i = index" [class]="i % 2 == 0 ? 'bg-tab' : 'bg-transparent'">
          <td [attr.aria-label]="'dialog.mint_ships.NAME' | translate" class="p-2 subtitle text-light-purple">
            <label class="text-white">{{ship.name}}</label>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.FDC' | translate" class="p-2 subtitle text-light-purple">
            <label class="text-white">{{ship.priceFDC}}</label>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.TIME' | translate" class="p-2 subtitle text-light-purple w-36">
            <label class="text-white">{{ship.approximateTime}}</label>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.SELECT' | translate" class="p-2 subtitle text-light-purple md:!w-32">
            <div class="flex flex-row w-20 items-center gap-2 justify-between text-center ">
              <img src="assets/images/icon/remove.svg" class="cursor-pointer w-4" alt="" (click)="addShipsToFleet('-',i)">
              <label class="desc-h md:subtitle text-white w-20 !mx-auto">{{ship.size}}</label>
              <img src="assets/images/icon/add.svg" class="cursor-pointer w-4" alt="" (click)="addShipsToFleet('+',i)">
            </div>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.TOTAL_SHIPS' | translate" class="p-2 subtitle text-light-purple">
            <label class="text-white">{{ship.quantity}}</label>
          </td>
          <td [attr.aria-label]="'dialog.mint_ships.delete' | translate" class="p-2 subtitle text-light-purple">
            <div class="w-5">
              <img src="assets/images/icon/delete.svg" class="!w-5 cursor-pointer" (click)="removeShips(ships,i)" alt="">
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="flex flex-col gap-2">
    <label class="subtitle text-white">{{"dialog.mint_ships.total" | translate}} {{sumShips | currency: 'FDC: ' }}</label>
    <label class="subtitle text-white">{{"dialog.mint_ships.total_time" | translate}}: {{totalTime}}</label>
    <label *ngIf="sumShips > balance" class="subtitle text-red">{{"dialog.mint_ships.no_balance" | translate}}</label>
  </div>
  <div class="flex flex-col w-full p-2 sm:p-4" >
    <div class="flex flex-row w-full items-center justify-center gap-8 mt-5">
      <button class="button !m-0 text-white bg-purple" (click)="deleteShips()">{{"dialog.mint_ships.clean_cart" | translate}}</button>
      <button [disabled]="sumShips > balance" [class]="sumShips > balance ? 'button !m-0 text-white opacity-60 bg-dark-black' : 'button !m-0 text-white bg-purple'" (click)="confirmShips()">{{"dialog.mint_ships.buy" | translate}}</button>
    </div>
  </div>
</div>
