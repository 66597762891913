import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger,AnimationEvent} from "@angular/animations";

@Component({
  selector: 'app-image-animate',
  templateUrl: './image-animate.component.html',
  styleUrls: ['./image-animate.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0 ,marginLeft:-100}),
        animate('400ms', style({ opacity: 1,marginLeft:0 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 ,marginLeft:0}),
        animate('400ms', style({ opacity: 0,marginLeft:100 }))
      ]),
    ]),
    trigger('fadeOut', [
      transition(':enter', [
        style({ opacity: 0 ,marginLeft:100}),
        animate('400ms', style({ opacity: 1,marginLeft:0 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 ,marginLeft:0}),
        animate('400ms', style({ opacity: 0,marginLeft:-100 }))
      ]),
    ]),
  ]
})
export class ImageAnimateComponent implements OnInit{
  @Input() src:any;
  @Input() type:any;
  @Output() isEndAnimation = new EventEmitter<boolean>();
  mySrc:any = ''
  animationState = 'void';
  constructor() {}
  ngOnInit(): void {
    this.animationState = 'visible';
    this.mySrc = this.src
  }

  onDone(event: AnimationEvent) {
    this.mySrc = this.src
    if (event.fromState !== 'visible') {
      this.isEndAnimation.emit(false);
    }
  }
}
