<div class="flex flex-col justify-center gap-6 p-4 sm:p-8 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="title text-white">{{'dialog.change_name.title'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <label class="subtitle text-start text-light-gray">{{'dialog.change_name.desc_how'|translate}}</label>
  <form
    [formGroup]="nameForm"
    (ngSubmit)="confirmShips()"
    class="flex flex-col w-full items-start gap-2"
  >
    <div class="flex flex-col w-full">
      <div class="flex flex-row w-full max-w-sm items-center justify-start gap-2 bg-light-black text-white p-2  h-9">
        <input class="bg-transparent p-2 w-full outline-0" maxlength="15" [placeholder]="planet.name" formControlName="name" />
      </div>
    </div>
    <div class="flex flex-row w-full max-w-md gap-2 items-center justify-around mt-5 !mx-auto">
      <button class="button !m-0 text-white bg-dark-black cursor-pointer"  type="reset" (click)="_dialog.closeAll()">{{'dialog.buy_ships.cancel'|translate}}</button>
      <button *ngIf="!loadChangeName" [disabled]="this.nameForm.get('name')?.value === planet.name" [class]="this.nameForm.get('name')?.value !== planet.name ? 'button !m-0 text-white bg-purple cursor-pointer':'button !m-0 text-white bg-dark-black opacity-60 '" type="submit">{{'dialog.buy_ships.confirm'|translate}}</button>
      <button *ngIf="loadChangeName" class="button text-white bg-purple !items-center !justify-center !m-0">
        <mat-spinner [diameter]="20" class="!mx-auto"></mat-spinner>
      </button>
    </div>
  </form>
</div>
