import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../../../../../core/services/account.service";
import {PlanetService} from "../../../../../../core/services/planet.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogViewShipsMintedComponent} from "../../../../components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogViewShipsDestroyedComponent} from "../../../../components/dialog/dialog-view-ships-destoyed/dialog-view-ships-destroyed.component";
const allShips = require('src/app/core/ships/ship.json');

@Component({
  selector: 'app-war-info-off-old',
  templateUrl: './war-info-off-old.component.html',
  styleUrls: ['./war-info-off-old.component.scss']
})
export class WarInfoOffOldComponent implements OnInit{
  loading:boolean=false;
  battleOffOncoming:any[]=[];
  battleOffOld:any[]=[];
  battleDefOncoming:any[]=[];
  battleDefOld:any[]=[];
  dateNow:number = 0;
  type:string | null='';
  sortBattle:string | null='';
  viewInfoBattle:any;
  viewAllShips:any;
  maxSizeFleet:any;
  address:any;
  myPlanets:any
  HYD:any;
  noPlanet:any;
  planetSelected:any;
  innerWidth:any;
  constructor(
    private _dialog: MatDialog,
    private _accountService: AccountService,
    private _planetService: PlanetService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.innerWidth = window.innerWidth;
    this.dateNow = Math.floor(Date.now()/1000)
    this.loading = true;
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getBalance().subscribe({
            next: (balance) => {
              if(this.HYD === undefined){
                this.HYD = balance.balanceHYD
                this.getBattle()
                setInterval(()=>{
                  this.dateNow = Math.floor(Date.now()/1000)
                },1000)
              }
            }
          })
        }
      }
    })
  }

  getBattle(){
    let planetSelected = localStorage.getItem(this.address+'planetSelected')
    this._planetService.getPlanet().subscribe({
      next: async (planets) => {
        if (planets && planets.length > 0) {
          this.myPlanets = planets
          const planet = planets.filter((data) => data.name === planetSelected)
          if(planet && planet.length >0){
            this.planetSelected = planet[0]
            this.type = 'off'
            this.sortBattle = this._route.snapshot.paramMap.get('sort')
            const battle_id = this._route.snapshot.paramMap.get('id')
            this._planetService.getBattleOld(battle_id).subscribe({
              next: async (battle) => {
                this.viewAllShips = this._planetService.createShips(allShips)
                this.viewInfoBattle = this.formatBattle(battle);
                this.loading = false;
              }
            })
          }
        } else {
          this.noPlanet = true;
          this.loading = false;
        }
      }
    });
  }

  formatBattle(battle:any){
    const infoBattle = battle
    let amountBurnDef = 0;
    let amountBurnOff = 0;
    if(infoBattle.fleetBurnDef !== null){
      for(let data of infoBattle.fleetBurnDef){
        for(let amounts of data.amountsBurn){
          amountBurnDef = amountBurnDef + amounts
        }
      }
    }
    if(infoBattle.fleetBurnOff !== null){
      for(let data of infoBattle.fleetBurnOff){
        for(let amounts of data.amountsBurn){
          amountBurnOff = amountBurnOff + amounts
        }
      }
    }
    return {
      id: infoBattle.id,
      battle: infoBattle,
      speedMin: infoBattle.stats.speedMin,
      totAtt: infoBattle.stats.totAtt,
      totDef: infoBattle.stats.totDef,
      resistanceOff: infoBattle.stats.resistanceOff,
      resistanceDef: infoBattle.stats.resistanceDef,
      totHold: this._planetService.formatBigNumber(infoBattle.stats.totHold),
      fleetBurnDef: infoBattle.fleetBurnDef,
      amountsBurnDef: amountBurnDef,
      fleetBurnOff: infoBattle.fleetBurnOff,
      amountsBurnOff: amountBurnOff,
      fleetOff: infoBattle.fleet.fleetOff,
      fleetDef: infoBattle.fleet.fleetDef,
      planetIdOff: infoBattle.planetIdOff,
      planetIdDef: infoBattle.planetIdDef,
      kmHydro: infoBattle.stats.kmHydro / 10 ** 2,
      confirmed: infoBattle.status.confirmed,
      revealed: infoBattle.status.revealed,
      revoked: infoBattle.status.revoked,
      canDefRevoke: infoBattle.canDefRevoke,
      rewardStolen: this._planetService.formatBigNumber(infoBattle.status.rewardStolen),
      startBattle: infoBattle.status.startBattle,
      returnTime: infoBattle.returnTime,
      randomNumber: infoBattle.randomNumber,
      winProbability: infoBattle.winProbability,
      battleDelay: infoBattle.battleDelay,
      isDefInPhaseReveal: infoBattle.isDefInPhaseReveal,
      infoReveal: infoBattle.infoReveal,
      planetNameDef: infoBattle.planetNameDef,
      planetNameOff: infoBattle.planetNameOff,
      timeRevealed: infoBattle.timeRevealed,
      scoreBattle: infoBattle.scoreBattle,
      deltaWin: infoBattle.deltaWin,
      offWin: infoBattle.winProbability >= infoBattle.randomNumber,
    }
  }

  cancelAttack(){
    this._router.navigate(['/dashboard/war'])
  }

  viewInfoFleet(fleet:any){
    let ships :any;
    ships = {
      amounts: fleet.amounts, type: fleet.shipsType
    }
    this._dialog.open(DialogViewShipsMintedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.viewAllShips},
    })
  }

  getFleetBurned(fleet:any){
    return fleet.reduce(function (acc: any, current: any) {
      return acc + current;
    }, 0)
  }

  viewInfoFleetBurned(fleet:any){
    const ships = {
      amounts: fleet.amountsBurn, type: fleet.shipsType
    }
    this._dialog.open(DialogViewShipsDestroyedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.viewAllShips},
    })
  }

}
