import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  private _account = new BehaviorSubject<any>(null);
  setWalletAddress(address: string) {
    this._account.next(address);
  }

  getWalletAddress() {
    return this._account.asObservable();
  }

  async addNetwork() {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0xa0c71fd',
            blockExplorerUrls: ['https://testnet.blastscan.io/'],
            chainName: 'Blast Sepolia',
            nativeCurrency: {
              name: 'ETH',
              symbol: 'ETH',
              decimals: 18,
            },
            rpcUrls: ['https://blast-sepolia.blockpi.network/v1/rpc/public'],
          },
        ],
      });
    } catch (addError) {
    }
  }

}
