<div class="flex flex-col md:flex-row container gap-12 px-0 sm:px-2 lg:pl-0 lg:pr-10 ">
  <div *ngIf="!this.loading" class="flex flex-row items-end justify-start w-full ">
    <div *ngIf="innerWidth > 768" class="flex flex-col items-end justify-start image_background w-full " [ngStyle]="{background: 'url('+'assets/images/structure/'+selected.src+')'}">

    </div>
    <div  [@openClose]="isClosed ? 'closed' : 'open'" [class]="!opened ? 'flex !flex-col w-full h-full sm:p-2 md:p-6' :'flex !flex-row w-full h-full sm:p-2 md:p-6'">
      <div class="flex flex-col gap-5 w-full max-w-lg h-full">
        <div class="flex flex-col gap-2">
          <div class="flex flex-row items-center gap-4">
            <label class="title text-white mt-2">{{"dashboard.structure."+selected.selected | translate}}</label>
            <app-image *ngIf="!opened" src="/icon/expand.svg" class="hidden md:block w-6 cursor-pointer" (click)="toggle()"></app-image>
          </div>
          <div *ngIf="selected.name === 'Laboratorio'" class="flex flex-col gap-2">
            <label *ngIf="selected.level !== selected.maxLevel" class="subtitle text-white">{{"dashboard.structure.level" | translate}}: {{this.laboratoryAvailable || this.noPlanet ? selected.level : selected.level}} </label>
            <label *ngIf="selected.level === selected.maxLevel" class="subtitle text-white !font-light">{{"dashboard.structure.max_level" | translate}}: {{selected.level}} </label>
          </div>
          <div *ngIf="selected.name === 'Hangar'" class="flex flex-col gap-2">
            <label *ngIf="selected.level !== selected.maxLevel" class="subtitle text-white">{{"dashboard.structure.level" | translate}}: {{this.hangarAvailable || this.noPlanet ? selected.level : selected.level}} </label>
            <label *ngIf="selected.level === selected.maxLevel" class="subtitle text-white !font-light">{{"dashboard.structure.max_level" | translate}}: {{selected.level}} </label>
          </div>
          <div *ngIf="selected.name === 'Armeria'" class="flex flex-col gap-2">
            <label *ngIf="selected.level !== selected.maxLevel" class="subtitle text-white">{{"dashboard.structure.level" | translate}}: {{this.armoryAvailable || this.noPlanet ? selected.level : selected.level}} </label>
            <label *ngIf="selected.level === selected.maxLevel" class="subtitle text-white !font-light">{{"dashboard.structure.max_level" | translate}}: {{selected.level}} </label>
          </div>
          <div *ngIf="selected.name === 'Scudo'" class="flex flex-col gap-2">
            <label *ngIf="selected.level !== selected.maxLevel" class="subtitle text-white">{{"dashboard.structure.level" | translate}}: {{this.shieldAvailable || this.noPlanet ? selected.level : selected.level}} </label>
            <label *ngIf="selected.level === selected.maxLevel" class="subtitle text-white !font-light">{{"dashboard.structure.max_level" | translate}}: {{selected.level}} </label>
          </div>
        </div>
        <div class="flex flex-row items-center gap-4">
          <app-image src="/icon_token/fdc.svg" class="w-6" ></app-image>
          <label class="desc-h text-white">{{selected.costUpdate | translate}}</label>
        </div>
        <label class="desc-h text-white">{{selected.description | translate}}</label>
        <div *ngIf="!opened" class="flex flex-col h-44 overflow-x-auto">
          <table class="w-full max-w-7xl text-sm text-left bg-transparent shadow-none text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-light-gray uppercase bg-transparent">
            <tr>
              <th scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.LEVEL" | translate}}</th>
              <th scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.TIME" | translate}}</th>
              <th *ngIf="selected.name === 'Laboratorio'" scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.POWER_LAB" | translate}}</th>
              <th *ngIf="selected.name === 'Hangar'" scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.POWER_HA" | translate}}</th>
              <th *ngIf="selected.name === 'Armeria'" scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.POWER_AR" | translate}}</th>
              <th *ngIf="selected.name === 'Scudo'" scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.POWER_SH" | translate}}</th>
              <th scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.COST" | translate}} (FDC)</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let level of selected.cutLevel | orderBy: 'i':'asc' ; let i = index" class="text_table text-white border-2 justify-between ">
              <td *ngIf="level"  class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel" [class]="level.i === selected.level ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.i +1}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.i +1}}</label>
              </td>
              <td *ngIf="level" class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel"  [class]="level.i === selected.level ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.timeUpdate}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.timeUpdate}}</label>
              </td>
              <td *ngIf="level" class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel"  [class]="level.i === selected.level ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.power}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.power}}</label>
              </td>
              <td *ngIf="level" class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel"  [class]="level.i === selected.level  ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.price | currency: '':'':'1.2-3'}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.price | currency: '':'':'1.2-3'}}</label>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="flex flex-col gap-2 !mt-auto  text-center">
          <div *ngIf=" selected.name === 'Laboratorio' && !this.laboratoryAvailable && !this.noPlanet " class="flex flex-col text-center">
            <label class="desc-h text-white">{{"dashboard.structure.expanding_lab" | translate}} {{selected.level}}</label>
            <div *ngFor="let struct of buildStructure,let i = index">
              <label *ngIf="struct.name === selected.selected" class="desc-h text-white mt-2">{{struct.time}}</label>
            </div>
          </div>
          <div *ngIf=" selected.name === 'Hangar' && !this.hangarAvailable && !this.noPlanet " class="flex flex-col text-center">
            <label  class="desc-h text-white">{{"dashboard.structure.expanding_ha" | translate}} {{selected.level}}</label>
            <div *ngFor="let struct of buildStructure,let i = index">
              <label *ngIf="struct.name === selected.selected" class="desc-h text-white mt-2">{{struct.time}}</label>
            </div>
          </div>
          <div *ngIf="selected.name === 'Armeria' && !this.armoryAvailable && !this.noPlanet  && !this.haveBuildShip" class="flex flex-col text-center">
            <label class="desc-h text-white">{{"dashboard.structure.expanding_ar" | translate}} {{selected.level}}</label>
            <div *ngFor="let struct of buildStructure,let i = index">
              <label *ngIf="struct.name === selected.selected" class="desc-h text-white mt-2">{{struct.time}}</label>
            </div>
          </div>
          <div *ngIf=" selected.name === 'Scudo' && !this.shieldAvailable && !this.noPlanet " class="flex flex-col text-center">
            <label class="desc-h text-white">{{"dashboard.structure.expanding_sh" | translate}} {{selected.level}}</label>
            <div *ngFor="let struct of buildStructure,let i = index">
              <label *ngIf="struct.name === selected.selected" class="desc-h text-white mt-2">{{struct.time}}</label>
            </div>
          </div>
          <button [disabled]="selected.level === selected.maxLevel || selected.costUpdate > fdc " *ngIf="!loadingUpdate && selected.name === 'Laboratorio'"
                  [class]="(selected.level !== selected.maxLevel && selected.costUpdate <= fdc) ? 'button bg-purple text-white !mt-auto !mx-auto !mb-0':'button bg-dark-black opacity-80 text-white !mt-auto !mx-auto !mb-0'" (click)="updateStructure('updateLevelLaboratory','laboratory',selected)">{{selected.level === 0 ? ('dashboard.structure.build'|translate): selected.level === selected.maxLevel ? ('dashboard.structure.mx_lev'|translate) :('dashboard.structure.increase'|translate)}}</button>
          <button [disabled]="selected.level === selected.maxLevel || selected.costUpdate > fdc " *ngIf="!loadingUpdate && selected.name === 'Hangar'"
                  [class]="(selected.level !== selected.maxLevel && selected.costUpdate <= fdc) ? 'button bg-purple text-white !mt-auto !mx-auto !mb-0':'button bg-dark-black opacity-80 text-white !mt-auto !mx-auto !mb-0'" (click)="updateStructure('updateLevelHangar','hangar',selected)">{{selected.level === 0 ?  ('dashboard.structure.build'|translate): selected.level === selected.maxLevel ? ('dashboard.structure.mx_lev'|translate) :('dashboard.structure.increase'|translate)}}</button>
          <button [disabled]="selected.level === selected.maxLevel || selected.costUpdate > fdc " *ngIf="!loadingUpdate && selected.name === 'Armeria'"
                  [class]="(selected.level !== selected.maxLevel && selected.costUpdate <= fdc) ? 'button bg-purple text-white !mt-auto !mx-auto !mb-0':'button bg-dark-black opacity-80 text-white !mt-auto !mx-auto !mb-0'" (click)="updateStructure('updateLevelArmory','armory',selected)">{{selected.level === 0 ?  ('dashboard.structure.build'|translate): selected.level === selected.maxLevel ? ('dashboard.structure.mx_lev'|translate) :('dashboard.structure.increase'|translate)}}</button>
          <button [disabled]="selected.level === selected.maxLevel || selected.costUpdate > fdc " *ngIf="!loadingUpdate && selected.name === 'Scudo'"
                  [class]="(selected.level !== selected.maxLevel && selected.costUpdate <= fdc) ? 'button bg-purple text-white !mt-auto !mx-auto !mb-0':'button bg-dark-black opacity-80 text-white !mt-auto !mx-auto !mb-0'" (click)="updateStructure('updateLevelShield','shield',selected)">{{selected.level === 0 ?  ('dashboard.structure.build'|translate): selected.level === selected.maxLevel ? ('dashboard.structure.mx_lev'|translate) :('dashboard.structure.increase'|translate)}}</button>
          <button *ngIf="loadingUpdate" class="button bg-purple text-white !mt-auto !mx-auto !mb-0 flex items-center justify-center" >
            <mat-spinner [diameter]="20"></mat-spinner>
          </button>
        </div>
      </div>
      <div *ngIf="opened" class="flex flex-col gap-6 w-full ">
        <div class="flex flex-row items-center justify-end gap-4">
          <app-image src="/icon/close.svg" class="w-6 cursor-pointer" (click)="toggle()"></app-image>
        </div>
        <div class="flex flex-col container_side_level overflow-x-auto">
          <table class="w-full max-w-7xl text-sm text-left bg-transparent shadow-none text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-light-gray uppercase bg-transparent">
            <tr>
              <th scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.LEVEL" | translate}}</th>
              <th scope="col" class="p-2 description_structures cursor-default" >{{"dashboard.structure.TIME" | translate}}</th>
              <th *ngIf="selected.name === 'Laboratorio'" scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.POWER_LAB" | translate}}</th>
              <th *ngIf="selected.name === 'Hangar'" scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.POWER_HA" | translate}}</th>
              <th *ngIf="selected.name === 'Armeria'" scope="col" class="p-2 description_structures cursor-default">{{"dashboard.structure.POWER_AR" | translate}}</th>
              <th *ngIf="selected.name === 'Scudo'" scope="col" class="p-2 description_structures cursor-auto">{{"dashboard.structure.POWER_SH" | translate}}</th>
              <th scope="col" class="p-2 description_structures cursor-auto">{{"dashboard.structure.COST" | translate}} (FDC)</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let level of selected.allLevel| orderBy: 'i':'asc' , let i = index" class="text_table text-white border-2 justify-between ">
              <td *ngIf="level"  class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel" [class]="level.i === selected.level ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.i +1}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.i +1}}</label>
              </td>
              <td *ngIf="level" class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel"  [class]="level.i === selected.level ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.timeUpdate}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.timeUpdate}}</label>
              </td>
              <td *ngIf="level" class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel"  [class]="level.i === selected.level ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.power}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.power}}</label>
              </td>
              <td *ngIf="level" class="p-2">
                <label *ngIf="level.i +1 !== selected.maxLevel"  [class]="level.i === selected.level  ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.price | currency: '':'':'1.2-3'}}</label>
                <label *ngIf="level.i +1 === selected.maxLevel" [class]="selected.level === selected.maxLevel ? 'desc-h text-light-purple !font-light' : 'desc-h text-white !font-light'">{{level.price | currency: '':'':'1.2-3'}}</label>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
    <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
  </div>
</div>
<div *ngIf="!this.loading" class="flex flex-col md:flex-row items-center justify-between gap-10 mt-12 w-full px-1 sm:px-5 lg:pl-0 lg:pr-10">
  <div *ngFor="let structure of structures" class="gap-2 container_image_structure flex flex-col p-4 items-start justify-end cursor-pointer" (click)="selectCard(structure.name, structure)" [ngStyle]="{background: 'linear-gradient(360deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(assets/images/structure/'+structure.src+')', 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center center' }">
    <label [class]="'title_structures text-white cursor-pointer'">{{"dashboard.structure."+structure.selected | translate}}</label>
    <label [class]="'desc-h text-white !font-light cursor-pointer'">{{"dashboard.structure.level"| translate}} : {{structure.level}}</label>
    <div *ngIf="buildStructure.length > 0">
      <div *ngFor="let struct of buildStructure,let i = index">
        <label *ngIf="struct.name === structure.selected && !this.loading" class="desc-h text-white cursor-pointer">{{struct.time}}</label>
      </div>
    </div>
  </div>
</div>
