import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {NotifierService} from "../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {environment} from "../../../../../../environments/environment";
import {getClaimDataMystery} from "../../../../../core/markle/merkleTreeProof";
import {PlanetService} from "../../../../../core/services/planet.service";
const mysteryAbi = require('src/app/core/abi/AstraMisteryBox.json');

@Component({
  selector: 'app-dialog-claim-old-mystery',
  templateUrl: './dialog-claim-old-mystery.component.html',
  styleUrls: ['./dialog-claim-old-mystery.component.scss']
})
export class DialogClaimOldMysteryComponent implements OnInit{
  oldMystery:any;
  fdc:any;
  address= '';
  mysteryId= '';
  loading:boolean=false;
  mysteryToReveal:boolean=false;
  loadingClaim:boolean=false;
  constructor(
    public _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: DialogClaimOldMysteryComponent,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _contractService: ContractService,
    private _planetService: PlanetService,
  ) {}

  ngOnInit(){
    this.mysteryToReveal = this.data.mysteryToReveal
    this.oldMystery = this.data.oldMystery
    this.address = this.data.address
  }

  async claimMysteryBox(mystery:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.mysteryId = mystery.id
    this.loadingClaim = true;
    let oldTokenId = mystery.id
    let scarcity = mystery.rarity
    let proof = mystery.proof
    this._contractService.writeContract(environment.astraMisteryBox, mysteryAbi, 'claimMysteryBox',
      [oldTokenId, scarcity, proof])
      .then(async (chargeProject) => {
        const charge = await chargeProject.wait();
        if(charge){
          this.updateMystery()
        }
      })
      .catch((err) => {
        this.mysteryId = '';
        this.loadingClaim = false;
        const error = JSON.stringify(err)
        const data = JSON.parse(error)
        if (data.reason.includes('transfer amount exceeds balance')) {
          this._notifierService.showNotificationError(this._translateService.instant('notification.no_fdc'))
        } else {
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_mystery'))
        }
      });
  }

  async revealMysteryBox() {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }    this.loading = true;
    this._contractService.writeContract(environment.astraMisteryBox, mysteryAbi, 'revealMysteryBox', [])
      .then(async (chargeProject) => {
        const charge = await chargeProject.wait();
        if(charge){
          this.updateMystery()
        }
      })
      .catch((err) => {
        const error = JSON.stringify(err)
        const data = JSON.parse(error)
        if (data.reason.includes('transfer amount exceeds balance')) {
          this._notifierService.showNotificationError(this._translateService.instant('notification.no_fdc'))
        } else {
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_mystery'))
        }
        this.loading = false;
      });
  }

  async updateMystery() {
    this._planetService.getMintPlanetInfo(this.address).subscribe({
      next:async (info) => {
        this.mysteryToReveal = info.misteryBoxToReveal !== 0;
        const newMystery:any[] = []
        const updateMystery:any[] = []
        for(let data of info.oldMisteryBoxToClaim){
          newMystery.push([data.address,data.tokenId,data.rarity])
        }
        const mystery = getClaimDataMystery(newMystery)
        for(let i = 0; i < info.oldMisteryBoxToClaim.length; i ++){
          const data = {
            id:mystery[i].id,
            rarity:mystery[i].rarity,
            proof:mystery[i].proof,
            claimed:info.oldMisteryBoxToClaim[i].claimed,
            revealed:info.oldMisteryBoxToClaim[i].revealed
          }
          updateMystery.push(data)
        }
        this.oldMystery = updateMystery;
        this.loading = false;
        this.loadingClaim = false;
        this.mysteryId = '';
      }
    })
  }
}
