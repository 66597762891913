import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {NotifierComponent} from "../../children/dashboard/components/notifier/notifier.component";

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(private _snackbar: MatSnackBar) {}

  showNotificationSuccess(message: string) {
    this._snackbar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
      },
      panelClass: 'app-notification-success',
      duration:3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }

  showNotificationError(message: string) {
    this._snackbar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
      },
      panelClass: 'app-notification-error',
      duration:3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
  }
}
