import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {environment} from "../../../../../../environments/environment";
import {NotifierService} from "../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {PlanetService} from "../../../../../core/services/planet.service";
const fleetABI = require('src/app/core/abi/AstraFleet.json');
const erc1155ABI = require('src/app/core/abi/erc1155.json');

@Component({
  selector: 'app-dialog-add-new-fleet',
  templateUrl: './dialog-add-new-fleet.component.html',
  styleUrls: ['./dialog-add-new-fleet.component.scss']
})
export class DialogAddNewFleetComponent implements OnInit{
  address:string = '';
  type:string = 'def';
  ships:any[]=[];
  levelArmory:any;
  maxSizeFleet:any;
  buildFleetDef:any;
  buildFleetAtt:any;
  planetSelected:any;
  sumShips=0;
  fleet:any;
  allShips:any;
  isPlanetInPhaseReveal:boolean=false;
  loadingBuild:boolean=false;
  loading:boolean=false;
  haveAllowance:boolean=false;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogAddNewFleetComponent>,
    private _contractService: ContractService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _planetService: PlanetService,
    @Inject(MAT_DIALOG_DATA) private data: DialogAddNewFleetComponent
  ) {}

  ngOnInit(){
    this.ships = this.data.ships;
    this.isPlanetInPhaseReveal = this.data.isPlanetInPhaseReveal;
    this.allShips = this.data.allShips;
    for(let i = 0 ; i < this.ships.length ;i++){
      this.sumShips = 0
      this.ships[i].size = 0
    }
    this.levelArmory = this.data.levelArmory;
    this.type = this.data.type;
    this.maxSizeFleet = this.data.maxSizeFleet;
    this.buildFleetDef = this.data.buildFleetDef;
    this.buildFleetAtt = this.data.buildFleetAtt;
    this.planetSelected = this.data.planetSelected;
    this.haveAllowance = this.data.haveAllowance;
  }

  setType(type:string){
    this.type = type;
  }
  addShipsToFleet(type:string,ship:any){
    if(this.type === 'def' && this.isPlanetInPhaseReveal){
      return;
    }
    if(this.levelArmory < ship.level){
      this._notifierService.showNotificationError(this._translateService.instant('notification.to_build_fleet_lv_ar')+' '+ship.level)
      return
    }
    const index =  this.ships.findIndex((data: any) => data.id === ship.id);
    const sum =  this.ships.reduce((accumulator, object) => {
      return accumulator + (object.size * object.valueOfSize);
    }, 0);
    if(type === '+'){
      if(sum + this.ships[index].valueOfSize <= this.maxSizeFleet){
        this.sumShips = sum + this.ships[index].valueOfSize
        this.ships[index].size = this.ships[index].size + 1
        this.ships[index].quantity = this.ships[index].quantity - 1
      }
    }else {
      if(this.ships[index].size - 1 >= 0){
        this.sumShips = sum - this.ships[index].valueOfSize
        this.ships[index].size = this.ships[index].size-1
        this.ships[index].quantity = this.ships[index].quantity + 1
      }
    }
    const ships = this.ships.filter((data)=> data.size >0)
    this.getInfoFleet(ships,this.allShips)
  }

  onChangeInputQuantity(event:Event,ship:any){
    const value = Number((event.target as HTMLInputElement).value);
    if(this.levelArmory < ship.level){
      this._notifierService.showNotificationError(this._translateService.instant('notification.to_build_fleet_lv_ar')+' '+ship.level)
      return
    }
    if(value <0){
      return;
    }
    const ships =  this.ships
    const index =  ships.findIndex((data: any) => data.id === ship.id);
    const oldSize = ships[index].size
    const oldQuantity = ships[index].quantity
    const maxQuantity = ships[index].size + ships[index].quantity
    const newQuantity = maxQuantity - value
    if(newQuantity === 0 && value > ships[index].size){
      ships[index].size = value
      ships[index].quantity = 0;
    } else if(newQuantity < 0){
      const maxQuantity = oldSize + oldQuantity
      ships[index].quantity = 0;
      ships[index].size = maxQuantity
    } else {
      ships[index].size = value
      ships[index].quantity = newQuantity
    }
    this.ships = [...ships];
    let sumShips = 0;

    for(let ships of this.ships){
      if(ships.size > 0){
        sumShips = sumShips + ships.size * ships.valueOfSize
      }
    }
    this.sumShips = sumShips;
    const newShips = this.ships.filter((data)=> data.size >0)
    this.getInfoFleet(newShips,this.allShips)
  }

  getInfoFleet(ships:any, allShips:any){
    let totalAtt = 0;
    let totalDef = 0;
    let resistance = 0;
    let speedMin = 0;
    let totalFuelCons = 0;
    let totalHold = 0;
    for(let i = 0; i< allShips.length; i++){
      let dataShips = ships.filter((data:any)=> data.id === i)
      if(dataShips && dataShips.length >0){
        const info = allShips[i].metadata
        totalAtt = totalAtt + (info.att * dataShips[0].size)
        totalDef = totalDef + (info.def * dataShips[0].size)
        totalFuelCons = totalFuelCons + ((100 / info.kmHydro)* dataShips[0].size)
        totalHold = totalHold + (info.hold * dataShips[0].size)
        resistance = resistance + info.resistance / ships.length
        if(speedMin === 0 || info.speed <= speedMin){
          speedMin = info.speed
        }
      }
    }
    this.fleet = {
      totalAtt: totalAtt,
      totalDef: totalDef,
      resistance: resistance,
      speedMin: speedMin,
      totalFuelCons: 100/totalFuelCons,
      totalHold: totalHold,
    }
  }

  async buildFleet(buildType:string){
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    if(this.type === 'def' && this.isPlanetInPhaseReveal){
      return;
    }
    this.loadingBuild= true;
    let shipsId: any[] = [];
    let shipsAmount: any[] = [];
    for(let ship of this.ships){
      if(ship.size > 0){
        shipsId.push(ship.id)
        shipsAmount.push(ship.size)
      }
    }
    if(this.haveAllowance){
      this._contractService.writeContract(environment.astraFleet, fleetABI, buildType, [this.planetSelected.id,shipsId,shipsAmount])
        .then(async (buildFleet) => {
          const build = await buildFleet.wait();
          if(build){
            this.loading = false;
            this._notifierService.showNotificationSuccess(this._translateService.instant('notification.new_fleet_build'))
            for(let i = 0 ; i < this.ships.length ;i++){
              this.sumShips = 0
              this.ships[i].size = 0
              this.ships[i].quantity = 0
            }
            this._dialogRef.close({maxSizeFleet:this.maxSizeFleet})
          }
        })
        .catch((err) => {
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_build_fleet'))
          this.loadingBuild= false;
          this.loading= false;
        });
    } else {
      this._contractService.writeContract(environment.astraShip, erc1155ABI, 'setApprovalForAll', [environment.astraFleet,true])
        .then(async (setApprovalForAll) => {
          const approval = await setApprovalForAll.wait();
          if(approval){
              this._contractService.writeContract(environment.astraFleet, fleetABI, buildType, [this.planetSelected.id,shipsId,shipsAmount])
                .then(async (buildFleet) => {
                  const build = await buildFleet.wait();
                  if(build){
                    this.loading = false;
                    this._notifierService.showNotificationSuccess(this._translateService.instant('notification.new_fleet_build'))
                    for(let i = 0 ; i < this.ships.length ;i++){
                      this.sumShips = 0
                      this.ships[i].size = 0
                      this.ships[i].quantity = 0
                    }
                    this._dialogRef.close({maxSizeFleet:this.maxSizeFleet})
                  }
                })
                .catch((err) => {
                  const error = JSON.stringify(err)
                  const data = JSON.parse(error)
                  this._notifierService.showNotificationError(this._translateService.instant('notification.error_build_fleet'))
                  this.loadingBuild= false;
                  this.loading= false;
                });
          }
        })
        .catch((err) => {
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_approve'))
          this.loadingBuild= false;
          this.loading= false;
        });
    }
  }

  cancelUpdateFleet(){
    for(let i = 0 ; i < this.ships.length ;i++){
      this.sumShips = 0
      this.ships[i].size = 0
    }
    this._dialogRef.close()
  }
}
