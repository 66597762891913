// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl:  'https://engine.astrafinance.io',
  fdc: '0x2Ab624d795149a06157C3178424bCEb354472B53',
  hydrogen: '0x3Eb27E5dC1F1daA407d37F42dbA2ee340523b3B5',
  astraManagerVRF: '0xdBB2a46d4Dd16b894FFbB263aAc18fcA967257DB',
  astraPlanet: '0x5f780163177bf00aeDA8EE003C5feB6027852E38',
  astraPlanetName: '0x7d720DD88Baf700d64444a2C34CDeb4d125529f6',
  astraFarmingManager: '0x5142991C60febC368f54fC8D5Ba5009a06F6ff0E',
  astraFarmingImplement: '0x216a1b35cCDC3B7ecF17199FB019A5b1A8FD9cfe',
  astraShip: '0x9BfF1910FDF8223A9F13868F46e4f1AcA866F817',
  astraBuilding: '0x1d5B6751868Dbb959d937404e8052aD96775D407',
  astraFleet: '0xF672f2e70dA06Db776F5cF28Ff5E5477c7e29284',
  astraBattle: '0xFAfa9377e52744b1909ee1a52e591855815aE073',
  astraBattleCalc: '0xFF7bE7bD7897c83a5329082c3762fe575e297497',
  astraMisteryBox: '0x7a3F3D714571079832aC608289a0772cf9E61d90',
  astraSwapHydro: '0x2e230e5De6343905d144aE49fc9DE054264C23fa',
  astraScore: '0x7b074947875081f844aB16b5416e7d4564A43F88',
  lpToken: '0xc5530970Ced7635277252eB948AD00340d7c07Ee',
};
