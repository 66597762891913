import { Component } from '@angular/core';
import {GlobalVariables, WalletService} from "@scalingparrots/dapp-angular-lib";
import {MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {NotifierService} from "../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {PlanetService} from "../../../../../core/services/planet.service";
import {AccountService} from "../../../../../core/services/account.service";

@Component({
  selector: 'app-connect-wallet-dialog',
  templateUrl: './connect-wallet-dialog.component.html',
  styleUrls: ['./connect-wallet-dialog.component.scss']
})
export class ConnectWalletDialogComponent {

  loadMetamask:boolean=false;
  loadWalletConnect:boolean=false;
  constructor(
    private _dialogRef: MatDialogRef<ConnectWalletDialogComponent>,
    private _walletService: WalletService,
    private _router: Router,
    private _notifierService: NotifierService,
    private _planetService:PlanetService,
    private _translateService: TranslateService,
    private _accountService: AccountService,
  ) {}

  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }

  async connectWallet(type: string) {
    if (type === 'walletConnect') {
      this.loadWalletConnect = true;
    } else {
      this.loadMetamask = true;
    }
    if (type === 'walletConnect') {
      this._dialogRef.close();
    }
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      await this._accountService.addNetwork()
    }
    this._walletService.connectWallet(type).then(async (res) => {
      if (res !== undefined) {
        window.localStorage.setItem('connected', String(true));
        await this._planetService.updatePlanet(res, '')
        this.loadMetamask = false;
        this.loadWalletConnect = false;
        this._dialogRef.close()
      } else {
        this._notifierService.showNotificationError(this._translateService.instant('notification.wallet_not_found'))
      }
    });
  }
}
