import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-withdraw',
  templateUrl: './dialog-withdraw.component.html',
  styleUrls: ['./dialog-withdraw.component.scss']
})
export class DialogWithdrawComponent implements OnInit{
  amountForm: FormGroup = new FormGroup({});
  max:any;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogWithdrawComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: DialogWithdrawComponent
  ) {}

  ngOnInit(){
    this.amountForm = this._formBuilder.group({
      amount: [0, Validators.required],
    });
    this.max = this.data;
  }
  confirmShips() {
    this._dialogRef.close({data: 'confirm',amount:this.amountForm.get('amount')?.value})
  }

  setMax(){
    this.amountForm.get('amount')?.setValue(this.max)
  }
}
