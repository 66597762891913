<div class="flex flex-col justify-center gap-6 p-4 sm:p-8 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="title text-white">{{'dialog.change_name.title_civ'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <label class="subtitle text-start text-light-gray">{{'dialog.change_name.desc_change_civilization'|translate}}</label>
  <div class="flex flex-row items-center justify-start gap-10 w-full">
    <div [class]="civilizationId === 1 ? 'container_images_selected':'container_images'">
      <img class="h-20 cursor-pointer" src="assets/images/factions/signarok_logo.svg" alt="" (click)="selectFaction(1)">
    </div>
    <div [class]="civilizationId === 2 ? 'container_images_selected':'container_images'">
      <img class="h-20 cursor-pointer" src="assets/images/factions/numaya_logo.svg" alt="" (click)="selectFaction(2)">
    </div>
    <div [class]="civilizationId === 3 ? 'container_images_selected':'container_images'">
      <img class="h-20 cursor-pointer" src="assets/images/factions/afarit_logo.svg" alt="" (click)="selectFaction(3)">
    </div>
  </div>
  <div class="flex flex-row w-full max-w-md gap-2 items-center justify-around mt-5 !mx-auto">
    <button class="button !m-0 text-white bg-dark-black cursor-pointer"  type="reset" (click)="_dialog.closeAll()">{{'dialog.buy_ships.cancel'|translate}}</button>
    <button *ngIf="!loadChangeCiv" [disabled]="planet.civilization === civilizationId" [class]="planet.civilization !== civilizationId ? 'button !m-0 text-white bg-purple cursor-pointer':'button !m-0 text-white bg-dark-black opacity-60 '" (click)="confirmShips()">{{'dialog.buy_ships.confirm'|translate}}</button>
    <button *ngIf="loadChangeCiv" class="button text-white bg-purple !items-center !justify-center !m-0">
      <mat-spinner [diameter]="20" class="!mx-auto"></mat-spinner>
    </button>
  </div>
</div>
