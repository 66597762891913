import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../../../../environments/environment";
import {RPC_URL} from "../../../../../../core/utils/constant";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {AccountService} from "../../../../../../core/services/account.service";
import {PlanetService} from "../../../../../../core/services/planet.service";
import {ethers} from "ethers";
import {NotifierService} from "../../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogViewShipsMintedComponent} from "../../../../components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogDescriptionShipsComponent} from "../../../../components/dialog/dialog-description-ships/dialog-description-ships.component";
import {DialogForceRevealComponent} from "../../../../components/dialog/dialog-force-reveal/dialog-force-reveal.component";
const battleABI = require('src/app/core/abi/AstraBattle.json');
const FDCAbi = require('src/app/core/abi/FederationCredits.json');

@Component({
  selector: 'app-war-info-off',
  templateUrl: './war-info-off.component.html',
  styleUrls: ['./war-info-off.component.scss']
})
export class WarInfoOffComponent implements OnInit{
  loading:boolean=false;
  loadConfirm:boolean=false;
  loadReveal:boolean=false;
  loadRevoke:boolean=false;
  loadEmergencyRevoke:boolean=false;
  loadForceReveal:boolean=false;
  battleOffOncoming:any[]=[];
  battleOffOld:any[]=[];
  battleDefOncoming:any[]=[];
  battleDefOld:any[]=[];
  dateNow:number = 0;
  type:string | null='';
  sortBattle:string | null='';
  viewInfoBattle:any;
  viewAllShips:any;
  maxSizeFleet:any;
  address:any;
  myPlanets:any
  infoPlanetToAttack:any
  HYD:any;
  noPlanet:any;
  planetSelected:any;
  battleID:any;
  innerWidth:any;
  constructor(
    private _dialog: MatDialog,
    private _contractService: ContractService,
    private _accountService: AccountService,
    private _planetService: PlanetService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.innerWidth = window.innerWidth;
    this.dateNow = Math.floor(Date.now()/1000)
    this.loading = true;
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getBalance().subscribe({
            next: (balance) => {
              if(this.HYD === undefined){
                this.HYD = balance.balanceHYD
                this.updatePlanetFleet()
                setInterval(()=>{
                  this.dateNow = Math.floor(Date.now()/1000)
                },1000)
              }
            }
          })
        }
      }
    })
  }

  updatePlanetFleet(){
    let planetSelected = localStorage.getItem(this.address+'planetSelected')
    this._planetService.getPlanet().subscribe({
      next: async (planets) => {
        if (planets && planets.length > 0) {
          this.myPlanets = planets
          const planet = planets.filter((data) => data.name === planetSelected)
          if(planet && planet.length >0){
            this.planetSelected = planet[0]
            this.type = 'off'
            this.sortBattle = this._route.snapshot.paramMap.get('sort')
            const battle_id = this._route.snapshot.paramMap.get('id')
            this._planetService.getInfoPlanetBattle(this.planetSelected.id).subscribe({
              next: async (info) => {
                this.viewAllShips = this._planetService.createShips(info.infoShip)
                this.battleOffOncoming = this.getPlanetBattle(info.battleOffOncoming)
                this.searchBattle(battle_id)
              }
            })
          }
        } else {
          this.noPlanet = true;
          this.loading = false;
        }
      }
    });
  }

  getPlanetBattle(battle:any){
    const battleStatus:any[] = []
    for(let i = 0; i < battle.length; i ++){
      const infoBattle = battle[i]
      let amountBurnDef = 0;
      let amountBurnOff = 0;
      if(infoBattle.fleetBurnDef !== null){
        for(let data of infoBattle.fleetBurnDef){
          for(let amounts of data.amountsBurn){
            amountBurnDef = amountBurnDef + amounts
          }
        }
      }
      if(infoBattle.fleetBurnOff !== null){
        for(let data of infoBattle.fleetBurnOff){
          for(let amounts of data.amountsBurn){
            amountBurnOff = amountBurnOff + amounts
          }
        }
      }
      const data = {
        index:i,
        id:infoBattle.id,
        speedMin:infoBattle.stats.speedMin,
        totAtt:infoBattle.stats.totAtt,
        totDef:infoBattle.stats.totDef,
        resistanceOff:infoBattle.stats.resistanceOff,
        resistanceDef:infoBattle.stats.resistanceDef,
        totHold: this._planetService.formatBigNumber(infoBattle.stats.totHold),
        fleetBurnDef: infoBattle.fleetBurnDef,
        amountsBurnDef: amountBurnDef,
        fleetBurnOff: infoBattle.fleetBurnOff,
        amountsBurnOff: amountBurnOff,
        fleetOff: infoBattle.fleet.fleetOff,
        fleetDef: infoBattle.fleet.fleetDef,
        planetIdOff:infoBattle.planetIdOff,
        planetIdDef:infoBattle.planetIdDef,
        kmHydro:infoBattle.stats.kmHydro / 10**2,
        confirmed:infoBattle.status.confirmed,
        revealed:infoBattle.status.revealed,
        revoked:infoBattle.status.revoked,
        canDefRevoke:infoBattle.canDefRevoke,
        rewardStolen: this._planetService.formatBigNumber(infoBattle.status.rewardStolen),
        startBattle:infoBattle.status.startBattle,
        returnTime:infoBattle.returnTime,
        randomNumber:infoBattle.randomNumber,
        winProbability:infoBattle.winProbability,
        battleDelay:infoBattle.battleDelay,
        isDefInPhaseReveal:infoBattle.isDefInPhaseReveal,
        infoReveal:infoBattle.infoReveal,
        planetNameDef:infoBattle.planetNameDef,
        planetNameOff:infoBattle.planetNameOff,
        timeRevealed:infoBattle.timeRevealed,
        scoreBattle:infoBattle.scoreBattle,
        deltaWin:infoBattle.deltaWin,
        offWin:infoBattle.winProbability >= infoBattle.randomNumber,
        defWin:infoBattle.winProbability < infoBattle.randomNumber,
      }
      battleStatus.push(data)
    }
    return battleStatus
  }

  searchBattle(id:any){
    const battle = this.battleOffOncoming.filter((battle)=> battle.id === Number(id))
    if(battle[0].confirmed && !battle[0].revealed){
      this.checkEvent(battle)
    }
    this.viewBattleInfo(battle[0].planetIdDef,battle[0])
  }

  cancelAttack(){
    this._router.navigate(['/dashboard/war'])
  }
  async checkEvent(battle: any) {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let contract = new ethers.Contract(environment.astraBattle, battleABI, provider);
    const filter = contract.filters["AttackRevealed"](battle.id);
    const listener = async (log:any) => {
    };
    contract.on(filter, listener);
    try {
      contract.on("AttackRevealed", (tokenId, tokenURI) => {
        this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reveal_confirmed'))
        //this._router.navigate(['/dashboard/war/off/old/'+battle.id])
      });
    } catch (error) {

    }
  }
  getApproximateTime(time:any){
    let approximateTime = '';
    let hoursRemaining = Math.floor(time/ 3600);
    let minutesRemaining = Math.floor((time % 3600) / 60);
    let secondsRemaining = time % 60;
    if (hoursRemaining > 0) {
      approximateTime = hoursRemaining + 'h : ' + minutesRemaining + 'm : ' + secondsRemaining + 's'
    } else {
      approximateTime = minutesRemaining + 'm : ' + secondsRemaining + 's'
    }
    return approximateTime;
  }

  async confirmAttack(battle:any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    let amountHydroToConfirm = this._planetService.formatBigNumber(battle.battleDelay.amountHydroToConfirm)
    if(amountHydroToConfirm > this.HYD){
      this._notifierService.showNotificationError(this._translateService.instant('notification.no_hydro_balance_to_confirm'))
      return
    }
    this.loadConfirm = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
      next: async (info) => {
        const amountHydro = ethers.utils.parseEther(String(amountHydroToConfirm))
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = indexBattle
        const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner(this.address);
        const blockNumber = await provider.getBlockNumber();
        const block = await provider.getBlock(blockNumber);
        const deadline = block.timestamp + 60;
        const name = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'name')
        const nonces = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'nonces', [signer._address])
        this._planetService.getPermitSignature(signer, nonces, environment.hydrogen, environment.astraBattle, amountHydro, deadline, name)
          .then(async (signature) => {
            this._contractService.writeContract(environment.astraBattle, battleABI, 'confirmAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef, deadline, signature.v,  signature.r, signature.s,amountHydro])
              .then(async (chargeProject) => {
                const charge = await chargeProject.wait();
                if(charge){
                  this.loadConfirm = false;
                  this.battleID = undefined;
                  battle.confirmed = true;
                  this._notifierService.showNotificationSuccess(this._translateService.instant('notification.attack_confirmed'))
                  this.viewBattleInfo(battle.planetIdDef,battle)
                  this.checkEvent(battle)
                }
              })
              .catch((err) => {
                const error = JSON.stringify(err)
                const data = JSON.parse(error)
                this.loadConfirm = false;
                this.battleID = undefined;
              });
          }).catch((err) => {
          this.loadConfirm = false;
          this.battleID = undefined;
        });
      },
      error:()=>{
        this.loadConfirm = false;
        this.battleID = undefined;
      }
    });
  }

  async forceRevealAttack(battle:any) {
    if(!(this.battleID === battle.id || this.battleID !== undefined)){
      const checkNetwork = await this._planetService.checkNetwork();
      if(checkNetwork){
        return
      }
      this._dialog.open(DialogForceRevealComponent, {
        width: '90%',
        maxWidth: '500px',
        panelClass: 'css-dialog',
        disableClose: true,
      }).afterClosed().subscribe((res) => {
        if(res && res.data === 'confirmed'){
          this.loadForceReveal = true;
          this.battleID = battle.id;
          const indexBattleOff = battle.infoReveal.indexOffBattle;
          const planetIdOff = battle.infoReveal.planetIdOff;
          const indexBattleDef = battle.infoReveal.indexDefBattle;
          const planetIdDef = battle.infoReveal.planetIdDef;
          this._contractService.writeContract(environment.astraBattle, battleABI, 'revealAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
            .then(async (chargeProject) => {
              const charge = await chargeProject.wait();
              if(charge){
                this.loadForceReveal = false;
                this.battleID = undefined;
                this.loading = true;
                this.infoPlanetToAttack = undefined
                this._notifierService.showNotificationSuccess(this._translateService.instant('notification.force_reveal_confirmed'))
                setTimeout(()=>{
                  this.updatePlanetFleet();
                },1000)
              }
            })
            .catch((err) => {
              const error = JSON.stringify(err)
              const data = JSON.parse(error)
              this._notifierService.showNotificationError(this._translateService.instant('notification.wait_for_reveal'))
              this.loadForceReveal = false;
              this.battleID = undefined;
            });
        }
      })
    }
  }
  async revealAttack(battle:any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadReveal = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
      next: async (info) => {
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = indexBattle
        const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
        this._contractService.writeContract(environment.astraBattle, battleABI, 'revealAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadReveal = false;
              this.battleID = undefined;
              this.loading = true;
              this.infoPlanetToAttack = undefined
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reveal_confirmed'))
              setTimeout(()=>{
                this._router.navigate(['/dashboard/war'])
              },1000)
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this._notifierService.showNotificationError(this._translateService.instant('notification.wait_for_reveal'))
            this.loadReveal = false;
            this.battleID = undefined;
          });
      },
      error:()=>{
        this.loadReveal = false;
        this.battleID = undefined;
      }
    })
  }

  async emergencyRevokeAttack(battle:any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadEmergencyRevoke = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
      next: async (info) => {
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = indexBattle
        const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
        this._contractService.writeContract(environment.astraBattle, battleABI, 'emergencyRevokeAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadEmergencyRevoke = false;
              this.battleID = undefined;
              this.loading = true;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.revoke_confirmed'))
              this.viewBattleInfo(battle.planetIdDef,battle)
              setTimeout(()=>{
                this.updatePlanetFleet();
              },1000)
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this.loadEmergencyRevoke = false;
            this.battleID = undefined;
          });
      },
      error:()=>{
        this.loadEmergencyRevoke = false;
        this.battleID = undefined;
      }
    })
  }

  async revokeAttack(battle:any,indexBattle:any) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadRevoke = true;
    this.battleID = battle.id;
    this._planetService.getInfoPlanetBattle(battle.planetIdDef).subscribe({
      next: async (info) => {
        const amountHydroToConfirm = ethers.utils.parseEther(String(battle.battleDelay.amountHydroToConfirm))
        const planetIdOff = battle.planetIdOff
        const planetIdDef = battle.planetIdDef
        const indexBattleOff = indexBattle
        const indexBattleDef = info.battleDefOncoming.findIndex((data:any)=> data.id === battle.id)
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner(this.address);
        const blockNumber = await provider.getBlockNumber();
        const block = await provider.getBlock(blockNumber);
        const deadline = block.timestamp + 60;
        const name = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'name')
        const nonces = await this._contractService.readContract(environment.hydrogen, RPC_URL, FDCAbi, 'nonces', [signer._address])
        this._planetService.getPermitSignature(signer, nonces, environment.hydrogen, environment.astraBattle, amountHydroToConfirm, deadline, name)
          .then(async (signature) => {
            this._contractService.writeContract(environment.astraBattle, battleABI, 'revokeAttack', [indexBattleOff,planetIdOff,indexBattleDef,planetIdDef, deadline, signature.v,  signature.r, signature.s,amountHydroToConfirm])
              .then(async (chargeProject) => {
                const charge = await chargeProject.wait();
                if(charge){
                  this.loadRevoke = false;
                  this.battleID = undefined;
                  this.loading = true;
                  battle.revoked = true;
                  this._notifierService.showNotificationSuccess(this._translateService.instant('notification.revoke_confirmed'))
                  this.viewBattleInfo(battle.planetIdDef,battle)
                  setTimeout(()=>{
                    this.updatePlanetFleet();
                  },1000)
                }
              })
              .catch((err) => {
                const error = JSON.stringify(err)
                const data = JSON.parse(error)
                this.loadRevoke = false;
                this.battleID = undefined;
              });
          }).catch((err) => {
          this.loadRevoke = false;
          this.battleID = undefined;
        });
      },
      error:(err)=>{
        this.loadRevoke = false;
        this.battleID = undefined;
      }
    })
  }

  viewInfoFleet(fleet:any){
    let ships :any;
    if(this.viewInfoBattle.battle.revealed){
      ships = {
        amounts: fleet.amountsRemaining, type: fleet.shipsType
      }
    }else {
      ships = {
        amounts: fleet.amounts, type: fleet.shipsType
      }
    }
    this._dialog.open(DialogViewShipsMintedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.viewAllShips},
    })
  }

  viewBattleInfo(enemyPlanetId:any,battle:any){
    let planet_def = 0;
    let planet_off = 0;
    if(this.planetSelected.id === battle.planetIdOff){
      planet_def = enemyPlanetId;
      planet_off = this.planetSelected.id;
    } else {
      planet_def = this.planetSelected.id;
      planet_off = enemyPlanetId;
    }
    this._planetService.getInfoPlanetToAttack(planet_def,planet_off).subscribe({
      next:(res)=>{
        this._planetService.getPreviewBattleById(battle.id).subscribe({
          next:(PrBatId)=>{
            this.viewInfoBattle = {
              battle : battle,
              planetIdOff : battle.planetIdOff,
              fleetOff : battle.fleetOff,
              amountHydroToConfirm : battle.battleDelay !== null ? this._planetService.formatBigNumber(battle.battleDelay.amountHydroToConfirm):0,
              planetNameOff : battle.planetNameOff,
              resistanceDef : PrBatId.battleStats.resistanceDef,
              resistanceOff : battle.resistanceOff,
              speedMin : battle.speedMin,
              maxRewardToStole : this._planetService.formatBigNumber(PrBatId.maxRewardToStole),
              deltaLevelPlanet : PrBatId.deltaLevelPlanet,
              probabilitiesToWin : battle.winProbability,
              totalAtt : battle.totAtt,
              totalDef : PrBatId.battleStats.totDef,
              totalHold :  battle.totHold,
              kmHydro : battle.kmHydro,
              estimatedTime : battle.kmHydro > 0 ? this.getApproximateTime(PrBatId.infoTravel.timeTravel) : '0h: 0m: 0s',
              totalFuelCons : battle.kmHydro > 0 ? this._planetService.formatBigNumber(PrBatId.infoTravel.fuelConsTravel) : 0,
            };
            this.dateNow = battle.kmHydro > 0 ? battle.startBattle : Math.floor(Date.now() / 1000);
            this.infoPlanetToAttack = {
              coordinate: res.coordinate,
              def: res.totalDef,
              fleetDef: res.fleetDef,
              levelShield: res.levelShield,
              percentageFeeWithdraw: res.percentageFeeWithdraw,
              deltaLevelPlanet: res.deltaLevelPlanet,
              maxRewardToStole: this._planetService.formatBigNumber(res.maxRewardToStole),
              distance: res.distance,
              name: res.planetName,
              power: res.power,
              rewardFarming: this._planetService.formatBigNumber(res.rewardFarming),
              planetsCivilizationId: res.civ,
              isProtected: res.isPlanetProtected,
            }
            this.loading = false
          }
        })
      }
    })
  }

  getDataTimer(timeToEnd:any):any{
    const today = new Date(timeToEnd *1000);
    let hh = today.getHours();
    let mm = today.getMinutes();
    const amOrPm = hh >= 12 ? 'PM' : 'AM';
    const formattedHours = hh % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${this.padZero(mm)} ${amOrPm}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  openDescription(key:string){
    this._dialog.open(DialogDescriptionShipsComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:this._translateService.instant(key),
    })
  }
}
