import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from "./dashboard.component";
import {SummaryComponent} from "./children/summary/summary.component";
import {MintPlanetComponent} from "./children/mint-planet/mint-planet.component";
import {StructureComponent} from "./children/structure/structure.component";
import {ShipsComponent} from "./children/ships/ships.component";
import {MapComponent} from "./children/map/map.component";
import {FleetComponent} from "./children/fleet/fleet.component";
import {WarComponent} from "./children/war/war.component";
import {ResourcesComponent} from "./children/resources/resources.component";
import {StatisticsComponent} from "./children/statistics/statistics.component";
import {SupportComponent} from "./children/support/support.component";
import {FarmingComponent} from "./children/farming/farming.component";
import {SwapComponent} from "./children/swap/swap.component";
import {WarInfoOffComponent} from "./children/war/info/war-info-off/war-info-off.component";
import {WarInfoDefComponent} from "./children/war/info/war-info-def/war-info-def.component";
import {RankingBetaComponent} from "./children/ranking-beta/ranking-beta.component";
import {WarInfoOffOldComponent} from "./children/war/info/war-info-off-old/war-info-off-old.component";
import {WarInfoDefOldComponent} from "./children/war/info/war-info-def-old/war-info-def-old.component";

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
      {
        path: 'planet',
        component: MintPlanetComponent,
      },
      {
        path: 'summary',
        component: SummaryComponent,
      },
      {
        path: 'structure',
        component: StructureComponent,
      },
      {
        path: 'structure/:name',
        component: StructureComponent,
      },
      {
        path: 'ships',
        component: ShipsComponent,
      },
      {
        path: 'map',
        component: MapComponent,
      },
      {
        path: 'fleet',
        component: FleetComponent,
      },
      {
        path: 'war',
        component: WarComponent,
      },
      {
        path: 'war/:id',
        component: WarComponent,
      },
      {
        path: 'war/off/now/:id',
        component: WarInfoOffComponent,
      },
      {
        path: 'war/off/old/:id',
        component: WarInfoOffOldComponent,
      },
      {
        path: 'war/def/now/:id',
        component: WarInfoDefComponent,
      },
      {
        path: 'war/def/old/:id',
        component: WarInfoDefOldComponent,
      },
      {
        path: 'resources',
        component: ResourcesComponent,
      },
      {
        path: 'statistics',
        component: StatisticsComponent,
      },
      /*{
        path: 'ranking/beta',
        component: RankingBetaComponent,
      },*/
      {
        path: 'farming',
        component: FarmingComponent,
      },
      {
        path: 'support',
        component: SupportComponent,
      },
      {
        path: 'swap',
        component: SwapComponent,
      },
      {
        path: '',
        redirectTo: 'planet',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'planet',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
