import {Component, OnInit} from '@angular/core';
import {PlanetService} from "../../../../core/services/planet.service";
import {AccountService} from "../../../../core/services/account.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {ethers} from "ethers";
import {NotifierService} from "../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {DialogDescriptionFarmComponent} from "../../components/dialog/dialog-description-farm/dialog-description-farm.component";
import {DialogWithdrawComponent} from "../../components/dialog/dialog-withdraw/dialog-withdraw.component";
const erc20ABI = require('src/app/core/abi/erc20.json');
const farmingManagerABI = require('src/app/core/abi/AstraFarmingManager.json');

@Component({
  selector: 'app-farming',
  templateUrl: './farming.component.html',
  styleUrls: ['./farming.component.scss']
})
export class FarmingComponent implements OnInit{
  dateNow:any;
  address:any;
  planetSelected:any;
  farming:any;
  LP:any=0;
  farmingForm: FormGroup = new FormGroup({});
  loadClaimReward:boolean=false;
  loadAddToFarming:boolean=false;
  loadWithdraw:boolean=false;
  loading:boolean=false;
  approved:boolean=false;
  constructor(
    private _planetService:PlanetService,
    private _accountService:AccountService,
    private _formBuilder:FormBuilder,
    private _contractService:ContractService,
    private _notifierService:NotifierService,
    private _dialog:MatDialog,
    private _translateService:TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.dateNow = Math.floor(Date.now()/1000)
    this.loading = true;
    this.farmingForm = this._formBuilder.group({
      amount: [0, Validators.required],
    });
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getPlanet().subscribe({
            next:(planets) => {
              if (planets && planets.length > 0 && this.planetSelected === undefined) {
                let planetSelectedName = localStorage.getItem(this.address+'planetSelected')
                if(planetSelectedName){
                  const planet = planets.filter((data) => data.name === planetSelectedName)
                  if(planet){
                    this.planetSelected = planet[0]
                    this.updateData(this.planetSelected.id)
                  } else {
                    this.planetSelected = planets[0]
                    this.updateData(this.planetSelected.id)
                  }
                } else {
                  this.planetSelected = planets[0]
                  this.updateData(this.planetSelected.id)
                }
              } else {
                this.updateData(0)
              }
            }
          })
        }
      }
    });
    setInterval(() => {
      this.dateNow = Math.floor(Date.now()/1000)
    }, 1000);
  }

  updateData(id:any){
    this._planetService.getInfoPlanetFarming(id,this.address).subscribe({
      next:(farm)=>{
        this.farming = {
          allowanceLP: farm ? farm.allowanceLP / 10**18 : 0,
          amountFeeWithdraw: farm ? farm.amountFeeWithdraw / 10**18 : 0,
          apr: farm ? farm.apr : 0,
          balanceLP: farm ? farm.balanceLP / 10**18 : 0,
          currentPid: farm ? farm.currentPid : 0,
          totalDepositedLP: farm ? farm.totalDepositedLP / 10**18 : 0,
          depositedLP: farm ? farm.depositedLP / 10**18 : 0,
          pendingReward: farm ? farm.pendingReward / 10**18 : 0,
          pendingRewardWithFee: farm ? farm.pendingRewardWithFee / 10**18 : 0,
          percentageFeeWithdraw: farm ? farm.percentageFeeWithdraw : 0,
          isUnderAttack: farm ? farm.isUnderAttack : false,
          scoreFarming: farm ? farm.scoreFarming : 0,
          ratioPlanet: farm ? farm.ratioPlanet : 0,
        }
        this.LP = this.farming.balanceLP;
        this.loading = false;
      }
    })
  }

  async approveFarm() {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    if(this.farming.pendingReward >0){
      this._dialog.open(DialogDescriptionFarmComponent, {
        width: '90%',
        maxWidth: '700px',
        panelClass: 'css-dialog',
        disableClose: true,
        data: {},
      }).afterClosed().subscribe(async (res) => {
        if(res && res.data==='confirm'){
          this.loadAddToFarming = true;
          const valueToApprove = ethers.utils.parseEther(String(this.farmingForm.get('amount')?.value - this.farming.allowanceLP))
          this._contractService.writeContract(environment.lpToken, erc20ABI, 'approve', [environment.astraFarmingImplement, valueToApprove])
            .then(async (chargeProject) => {
              const charge = await chargeProject.wait();
              if(charge){
                this.loadAddToFarming = false;
                this.approved = true;
                this.farmToken(false);
              }
            })
            .catch((err) => {
              const error = JSON.stringify(err)
              const data = JSON.parse(error)
              this.loadAddToFarming = false;
            });
        }
      })
    } else {
      this.loadAddToFarming = true;
      const valueToApprove = ethers.utils.parseEther(String(this.farmingForm.get('amount')?.value - this.farming.allowanceLP))
      this._contractService.writeContract(environment.lpToken, erc20ABI, 'approve', [environment.astraFarmingImplement, valueToApprove])
        .then(async (chargeProject) => {
          const charge = await chargeProject.wait();
          if(charge){
              this.loadAddToFarming = false;
              this.approved = true;
              this.farmToken(false);
          }
        })
        .catch((err) => {
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          this.loadAddToFarming = false;
        });
    }
  }

  async farmToken(type:boolean) {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    if(this.farming.pendingReward >0 && type){
      this._dialog.open(DialogDescriptionFarmComponent, {
        width: '90%',
        maxWidth: '700px',
        panelClass: 'css-dialog',
        disableClose: true,
        data: {},
      }).afterClosed().subscribe(async (res) => {
        if(res && res.data==='confirm'){
          this.loadAddToFarming = true;
          const value = ethers.utils.parseEther(String(this.farmingForm.get('amount')?.value))
          this._contractService.writeContract(environment.astraFarmingManager, farmingManagerABI, 'deposit', [this.farming.currentPid, value, this.planetSelected.id])
            .then(async (chargeProject) => {
              const charge = await chargeProject.wait();
              if(charge){
                this.loadAddToFarming = false;
                this.approved = false;
                this._notifierService.showNotificationSuccess(this._translateService.instant('notification.deposit_confirmed'))
                this.farmingForm.get('amount')?.setValue('0')
                this.updateData(this.planetSelected.id)
              }
            })
            .catch((err) => {
              const error = JSON.stringify(err)
              const data = JSON.parse(error)
              this.loadAddToFarming = false;
            });
        }
      })
    } else {
      this.loadAddToFarming = true;
      const value = ethers.utils.parseEther(String(this.farmingForm.get('amount')?.value))
      this._contractService.writeContract(environment.astraFarmingManager, farmingManagerABI, 'deposit', [this.farming.currentPid, value, this.planetSelected.id])
        .then(async (chargeProject) => {
          const charge = await chargeProject.wait();
          if(charge){
            this.loadAddToFarming = false;
            this.approved = false;
            this._notifierService.showNotificationSuccess(this._translateService.instant('notification.deposit_confirmed'))
            this.farmingForm.get('amount')?.setValue('0')
            this.updateData(this.planetSelected.id)
          }
        })
        .catch((err) => {
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          this.loadAddToFarming = false;
        });
    }
  }

  async claimReward() {
    const checkNetwork = await this._planetService.checkNetwork();
    if (checkNetwork) {
      return
    }
    this.loadClaimReward = true;
    this.farmingForm.get('amount')?.setValue(0)
    const value = ethers.utils.parseEther(String(this.farmingForm.get('amount')?.value))
    this._contractService.writeContract(environment.astraFarmingManager, farmingManagerABI, 'deposit', [this.farming.currentPid, value, this.planetSelected.id])
      .then(async (chargeProject) => {
        const charge = await chargeProject.wait();
        if(charge){
          this.loadClaimReward = false;
          this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reward_claimed'))
          this.farmingForm.get('amount')?.setValue('0')
          this.updateData(this.planetSelected.id)
          this.updateBalance()
        }
      })
      .catch((err) => {
        const error = JSON.stringify(err)
        const data = JSON.parse(error)
        this.loadClaimReward = false;
      });
  }

  async Withdraw(){
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this._dialog.open(DialogWithdrawComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data: this.farming.depositedLP,
    }).afterClosed().subscribe(async (res) => {
      if(res && res.data === 'confirm'){
        const value = ethers.utils.parseEther(String(res.amount))
        this.loadWithdraw = true;
        this._contractService.writeContract(environment.astraFarmingManager, farmingManagerABI, 'withdraw', [this.farming.currentPid, value, this.planetSelected.id])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              this.loadWithdraw = false;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.reward_claimed'))
              this.updateData(this.planetSelected.id)
              this.updateBalance()
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this.loadWithdraw = false;
          });
      }
    })
  }
  setMatBalance(){
    this.farmingForm.get('amount')?.setValue(this.LP)
  }

  updateBalance(){
    this._planetService.readBalance(this.address).subscribe({
      next:(res)=>{
        const balance = {
          balanceHYD: res.balanceHYD / 10 ** res.decimalsHYD,
          balanceFDC: res.balanceFDC / 10 ** res.decimalsFDC,
          decimalsFDC: res.decimalsFDC,
          decimalsHYD: res.decimalsHYD,
        }
        this._planetService.setBalance(balance)
      },
      error:(err)=>{
      }
    })
  }
}
