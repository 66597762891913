import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {environment} from "../../../../../../environments/environment";
import {NotifierService} from "../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {ethers} from "ethers";
import {PlanetService} from "../../../../../core/services/planet.service";
const fleetABI = require('src/app/core/abi/AstraFleet.json');
const erc1155ABI = require('src/app/core/abi/erc1155.json');

@Component({
  selector: 'app-dialog-edit-fleet',
  templateUrl: './dialog-edit-fleet.component.html',
  styleUrls: ['./dialog-edit-fleet.component.scss']
})
export class DialogEditFleetComponent implements OnInit{
  address:string = '';
  ships:any[]=[];
  planetSelected:any;
  levelArmory:any;
  fleetSelectedType:any;
  newFleetSelectedType:any;
  maxSizeFleet:any;
  fleetSelected:any;
  sumShips=0;
  fleet:any;
  allShips:any;
  loadingBuild:boolean=false;
  loading:boolean=false;
  haveAllowance:boolean=false;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogEditFleetComponent>,
    private _contractService: ContractService,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _planetService: PlanetService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data: DialogEditFleetComponent
  ) {}

  ngOnInit(){
    this.maxSizeFleet = this.data.maxSizeFleet;
    this.ships = this.data.ships;
    this.allShips = this.data.allShips;
    this.fleetSelectedType = this.data.fleetSelectedType;
    this.newFleetSelectedType = this.data.newFleetSelectedType;
    this.maxSizeFleet = this.data.maxSizeFleet;
    this.fleetSelected = this.data.fleetSelected;
    this.sumShips = this.data.sumShips;
    this.haveAllowance = this.data.haveAllowance;
    this.planetSelected = this.data.planetSelected;
    this.levelArmory = this.data.levelArmory;
    this.getInfoFleet(this.ships,this.allShips)
  }

  addShipsToFleet(type:string,ship:any){
    if(this.levelArmory < ship.level){
      this._notifierService.showNotificationError(this._translateService.instant('notification.to_build_fleet_lv_ar')+' '+ship.level)
      return
    }
    const index =  this.ships.findIndex((data: any) => data.id === ship.id);
    const sum =  this.ships.reduce((accumulator, object) => {
      return accumulator + (object.size * object.valueOfSize);
    }, 0);
    if(type === '+'){
      if(sum + this.ships[index].valueOfSize <= this.maxSizeFleet){
        this.sumShips = sum + this.ships[index].valueOfSize
        this.ships[index].size = this.ships[index].size + 1
        this.ships[index].quantity = this.ships[index].quantity - 1
      }
    }else {
      if(this.ships[index].size - 1 >= 0){
        this.sumShips = sum - this.ships[index].valueOfSize
        this.ships[index].size = this.ships[index].size-1
        this.ships[index].quantity = this.ships[index].quantity + 1
      }
    }

    this.updateChanges();
  }

  onChangeInputQuantity(event:Event,ship:any){
    const value = Number((event.target as HTMLInputElement).value);
    if(this.levelArmory < ship.level){
      this._notifierService.showNotificationError(this._translateService.instant('notification.to_build_fleet_lv_ar')+' '+ship.level)
      return
    }
    if(value <0){
      return;
    }
    const ships =  this.ships
    const index =  ships.findIndex((data: any) => data.id === ship.id);
    const oldSize = ships[index].size
    const oldQuantity = ships[index].quantity
    const maxQuantity = ships[index].size + ships[index].quantity
    const newQuantity = maxQuantity - value
    if(newQuantity === 0 && value > ships[index].size){
      ships[index].size = value
      ships[index].quantity = 0;
    } else if(newQuantity < 0){
      const maxQuantity = oldSize + oldQuantity
      ships[index].quantity = 0;
      ships[index].size = maxQuantity
    } else {
      ships[index].size = value
      ships[index].quantity = newQuantity
    }
    this.ships = [...ships];
    this.cdr.detectChanges();
    let sumShips = 0;

    for(let ships of this.ships){
      if(ships.size > 0){
        sumShips = sumShips + ships.size * ships.valueOfSize
      }
    }
    this.sumShips = sumShips;

    this.updateChanges();
  }


  updateChanges(){
    let shipsId:any[] = [];
    let shipsAmount:any[] = [];
    shipsId = this.fleetSelectedType.shipsType
    shipsAmount = this.fleetSelectedType.amounts
    for(let ship of this.ships){
      const index = this.fleetSelectedType.shipsType.findIndex((data:any)=> +data === ship.id)
      if(index > -1){
        if(ship.size === 0 ){
          const updatedShipsAmount = [...shipsAmount];
          updatedShipsAmount[index] = ethers.utils.parseUnits(String(0));
          shipsAmount = updatedShipsAmount;
        } else {
          const updatedShipsAmount = [...shipsAmount];
          updatedShipsAmount[index] = ethers.utils.parseUnits(String(ship.size),0)
          shipsAmount = updatedShipsAmount;
        }
      } else {
        if(ship.size == 0) {
          continue
        }
        const id = ethers.utils.parseUnits(String(ship.id),0)
        const amount = ethers.utils.parseUnits(String(ship.size),0)
        shipsId = shipsId.concat(id)
        shipsAmount = shipsAmount.concat(amount)
      }
    }
    const ships = this.ships.filter((data)=> data.size >0)
    this.getInfoFleet(ships,this.allShips)
    this.newFleetSelectedType = { shipsType: shipsId, amounts: shipsAmount };
  }

  getInfoFleet(ships:any, allShips:any){
    let totalAtt = 0;
    let totalDef = 0;
    let resistance = 0;
    let speedMin = 0;
    let totalFuelCons = 0;
    let totalHold = 0;
    for(let i = 0; i< allShips.length; i++){
      let dataShips = ships.filter((data:any)=> data.id === i)
      if(dataShips && dataShips.length >0){
        const info = allShips[i].metadata
        totalAtt = totalAtt + (info.att * dataShips[0].size)
        totalDef = totalDef + (info.def * dataShips[0].size)
        totalFuelCons = totalFuelCons + ((100 / info.kmHydro)* dataShips[0].size)
        totalHold = totalHold + (info.hold * dataShips[0].size)
        resistance = resistance + info.resistance / ships.length
        if(speedMin === 0 || info.speed <= speedMin){
          speedMin = info.speed
        }
      }
    }
    this.fleet = {
      totalAtt: totalAtt,
      totalDef: totalDef,
      resistance: resistance,
      speedMin: speedMin,
      totalFuelCons: 100/totalFuelCons,
      totalHold: totalHold,
    }
  }

  async modifyFleet(index:any,buildType:string){
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadingBuild= true;
    let shipsId: any[] = [];
    let shipsAmount: any[] = [];
    shipsId = this.newFleetSelectedType.shipsType
    shipsAmount = this.newFleetSelectedType.amounts
    if(this.haveAllowance){
      this._contractService.writeContract(environment.astraFleet, fleetABI, buildType, [this.planetSelected.id,index,shipsId,shipsAmount])
        .then(async (buildFleet) => {
          const build = await buildFleet.wait();
          if(build){
            this.loading = false;
            this._notifierService.showNotificationSuccess(this._translateService.instant('notification.fleet_modified'))
            this._dialogRef.close({data:'confirm'})
          }
        })
        .catch((err) => {
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_build_fleet'))
          this.loadingBuild= false;
          this.loading= false;
        });
    } else {
      this._contractService.writeContract(environment.astraShip, erc1155ABI, 'setApprovalForAll', [environment.astraFleet,true])
        .then(async (setApprovalForAll) => {
          const approval = await setApprovalForAll.wait();
          for (const event of approval.events) {
            this._contractService.writeContract(environment.astraFleet, fleetABI, buildType, [this.planetSelected.id,index,shipsId,shipsAmount])
              .then(async (buildFleet) => {
                const build = await buildFleet.wait();
                if(build){
                  this.loading = false;
                  this._notifierService.showNotificationSuccess(this._translateService.instant('notification.fleet_modified'))
                  this._dialogRef.close()
                }
              })
              .catch((err) => {
                const error = JSON.stringify(err)
                const data = JSON.parse(error)
                this._notifierService.showNotificationError(this._translateService.instant('notification.error_build_fleet'))
                this.loadingBuild= false;
                this.loading= false;
              });
          }
        })
        .catch((err) => {
          const error = JSON.stringify(err)
          const data = JSON.parse(error)
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_approve'))
          this.loading= false;
          this.loadingBuild= false;
        });
    }
  }
}
