import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-force-reveal',
  templateUrl: './dialog-force-reveal.component.html',
  styleUrls: ['./dialog-force-reveal.component.scss']
})
export class DialogForceRevealComponent implements OnInit{
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogForceRevealComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogForceRevealComponent
  ) {}

  ngOnInit(){
  }

  confirm(type:string) {
    this._dialogRef.close({data:type})
  }
}
