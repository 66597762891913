<div class="flex flex-col lg:flex-row container gap-6 px-0 sm:px-2">
  <div class="flex flex-col w-full h-full">
    <div id="map" [ngStyle]="{background:'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(../../../../../assets/images/landing/background.png)'}">
    </div>
  </div>
  <div class="flex flex-col w-full container_desc px-6 py-6 gap-6">
    <div class="flex flex-row items-center justify-start gap-4">
      <label class="title text-white">{{"dashboard.map.title" | translate}}</label>
      <button [class]="this.myPlanetSelected === undefined ? 'button !m-0 bg-dark-black text-white opacity-60 !h-7 !w-36': 'button bg-purple text-white !m-0 !h-7 !w-36'" [disabled]="this.myPlanetSelected === undefined" (click)="goToMyPlanet()">{{'dashboard.map.go_to_my_planet'|translate}}</button>
    </div>
    <div class="flex flex-col items-start justify-center gap-2">
      <label class="subtitle text-white">{{"dashboard.map.subtitle" | translate}}</label>
      <form [formGroup]="planetForm" class="flex flex-row gap-4 items-center">
        <input class="input-coordinate desc-h text-white !h-8 outline-0" placeholder="{{'dashboard.war.ID'|translate}}" formControlName="value" />
        <button [class]="this.planetForm.get('id')?.value === '' ? 'button bg-dark-black text-white !h-7 !w-36 !m-0 opacity-60' : 'button bg-purple text-white !h-7 !w-36 !m-0'" [disabled]="this.planetForm.get('id')?.value === ''" (click)="searchPlanet()">{{'dashboard.war.search'|translate}}</button>
      </form>
    </div>
    <table *ngIf="planetSelected" class="w-full max-w-md text-sm text-left bg-transparent shadow-none">
      <tbody>
      <tr class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.planet" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.name}}</td>
      </tr>
      <tr class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.coordinate" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.coordinate}}</td>
      </tr>
      <tr class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.civilization" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">
          <label *ngIf="planetSelected.planetsCivilizationId === 1" class="desc-h text-white">Signarock</label>
          <label *ngIf="planetSelected.planetsCivilizationId === 2" class="titdescle text-white">Numaya</label>
          <label *ngIf="planetSelected.planetsCivilizationId === 3" class="desc-h text-white">Afarit</label>
        </td>
      </tr>
      <tr class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.power" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.power}}</td>
      </tr>
      <tr class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.defense" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.def}}</td>
      </tr>
      <tr class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.shield" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.levelShield}}</td>
      </tr>
      <tr *ngIf="!this.isMyPlanet" class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">
          <div class="flex flex-row items-center gap-2">
            {{"dashboard.war.delta" | translate}} <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.delta_description')">
          </div>
        </th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.deltaLevelPlanet}} %</td>
      </tr>
      <tr class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.farming_reward" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.rewardFarming | currency: '':'':'1.2'}} {{"dashboard.ships.fdc" | translate}}</td>
      </tr>
      <tr *ngIf="!this.isMyPlanet" class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">
          <div class="flex flex-row items-center gap-2">
            {{"dashboard.war.fee" | translate}} <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.reward_description')">
          </div>
        </th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.maxRewardToStole | currency: '':'':'1.2'}} {{"dashboard.ships.fdc" | translate}}</td>
      </tr>
      <tr  class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">
          <label>{{"dashboard.war.fee_farming" | translate}}</label>
        </th>
        <td class="desc-h py-1 px-2 text-white">
          <label>{{planetSelected.percentageFeeWithdraw}}%</label>
        </td>
      </tr>
      <tr *ngIf="!this.isMyPlanet" class="gap-4">
        <th scope="col" class="py-1 px-2 subtitle text-light-purple cursor-default">{{"dashboard.war.distance" | translate}}</th>
        <td class="desc-h py-1 px-2 text-white">{{planetSelected.distance}}  {{"dashboard.war.mil_km" | translate}}</td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="planetSelected && !this.isMyPlanet" class="flex flex-col w-full items-start justify-center gap-3">
      <button [class]="planetSelected.isProtected || this.myPlanetSelected === undefined ? 'button !m-0 bg-dark-black text-white opacity-60': 'button bg-purple text-white !m-0'" [disabled]="planetSelected.isProtected || this.myPlanetSelected === undefined" (click)="attackPlanet(planetSelected.id)">{{'dashboard.war.attack'|translate}}</button>
      <label *ngIf="planetSelected.isProtected" class="subtitle text-red">{{"dashboard.war.planetProtected" | translate}}</label>
    </div>
    <div class="flex flex-col w-full items-start justify-center gap-2 mt-auto">
      <div class="flex flex-row items-center gap-2">
        <img src="assets/images/map_planet/enemy_planet.svg" alt="" class="w-4">
        <label class="desc-h text-white">{{"dashboard.map.enemy_planet" | translate}}</label>
      </div>
      <div class="flex flex-row items-center gap-2">
        <img src="assets/images/map_planet/free_planet.svg" alt="" class="w-4">
        <label class="desc-h text-white">{{"dashboard.map.free_planet" | translate}}</label>
      </div>
      <div class="flex flex-row items-center gap-2">
        <img src="assets/images/map_planet/my_planet.svg" alt="" class="w-4">
        <label class="desc-h text-white">{{"dashboard.map.my_planet" | translate}}</label>
      </div>
    </div>
  </div>
</div>
