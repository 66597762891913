import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../../environments/environment";
import {RPC_URL} from "../../../../core/utils/constant";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import { ethers } from 'ethers';
import {AccountService} from "../../../../core/services/account.service";
import {NotifierService} from "../../../../core/services/notifier.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {PlanetService} from "../../../../core/services/planet.service";
import {TranslateService} from "@ngx-translate/core";
import {getClaimDataPlanetCalculate} from "../../../../core/markle/merkleTreeProof";
import {MatDialog} from "@angular/material/dialog";
import {DialogClaimOldPlanetComponent} from "../../components/dialog/dialog-claim-old-planet/dialog-claim-old-planet.component";
import {DialogInfoNameComponent} from "../../components/dialog/dialog-info-name/dialog-info-name.component";
import {DialogConfirmMintPlanetComponent} from "../../components/dialog/dialog-confirm-mint-planet/dialog-confirm-mint-planet.component";
import {DialogInfoCivComponent} from "../../components/dialog/dialog-info-civ/dialog-info-civ.component";
const FDCAbi = require('src/app/core/abi/FederationCredits.json');
const planetABI = require('src/app/core/abi/AstraPlanetManager.json');
const allShips = require('src/app/core/ships/ship.json');
@Component({
  selector: 'app-mint-planet',
  templateUrl: './mint-planet.component.html',
  styleUrls: ['./mint-planet.component.scss']
})
export class MintPlanetComponent implements OnInit{
  nameForm: FormGroup = new FormGroup({});
  initialShipMint: any[] = [];
  amountForPrice:any;
  viewAllaShips:any;
  planetSupply:number= 0 ;
  fdc:any;
  oldPlanet:any[]=[];
  viewClaimPlanet:boolean=false;
  step1:boolean=true;
  step2:boolean=false;
  address= '';
  civilizationId= 1;
  loading:boolean=false;
  loadMint:boolean=false;
  claimPlanet:boolean=false;
  claimMysteryBox:boolean=false;
  constructor(
    private _contractService:ContractService,
    private _accountService:AccountService,
    private _planetService:PlanetService,
    private _notifierService:NotifierService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _translateService: TranslateService,
    private _dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.loading = true
    this.nameForm = this._formBuilder.group({
      name: ['', Validators.required],
    });
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getGlobalInfo().subscribe({
            next:(getGlobalInfo)=>{
              this.amountForPrice = getGlobalInfo.mintPrice
              this.viewAllaShips = this._planetService.createShips(allShips)
              let sumInitialShipMint:any[] = []
              for(let i = 0; i < getGlobalInfo.initialShipMint.amounts.length; i++){
                const info = this.viewAllaShips[getGlobalInfo.initialShipMint.type[i]]
                sumInitialShipMint.push({uri:info.imageURL,name: info.metadata.name,amount:getGlobalInfo.initialShipMint.amounts[i]});
              }
              this.initialShipMint = sumInitialShipMint
              this._planetService.getBalance().subscribe({
                next:(getBalance)=>{
                  if(getBalance && this.fdc === undefined){
                    this.fdc = getBalance.balanceFDC
                    this._planetService.getMintPlanetInfo(this.address).subscribe({
                      next:async (info) => {
                        if(info){
                          this.planetSupply = info.planetSupply
                          const newPlanet:any[] = []
                          if(info.oldPlanetIdToClaim !== null){
                            for(let data of info.oldPlanetIdToClaim){
                              newPlanet.push([data.Address,data.TokenId])
                            }
                            this.oldPlanet = getClaimDataPlanetCalculate(newPlanet)
                            if (this.oldPlanet.length > 0) {
                              this.viewClaimPlanet = true;
                            }
                          }
                          this.loading = false
                        }
                      }
                    })
                  }
                }
              })
            }
          })
        }
      }
    })
  }

  selectFaction(id:number){
    this.civilizationId = id;
  }


  goToNextStep(){
    const name = this.nameForm.get('name')?.value
    this._planetService.checkName(name).subscribe({
      next:(res)=>{
        if(res){
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_mint_name'))
        } else {
          this.step1 = false;
          this.step2 = true;
        }
      }
    })
  }

  goBack(){
    this.step1 = true;
    this.step2 = false;
  }
  confirmMintPlanet(){
    this._dialog.open(DialogConfirmMintPlanetComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
    }).afterClosed().subscribe( (res:any) => {
      if (res && res.data) {
        this.mintAnotherPlanet();
      }
    })
  }

  async mintAnotherPlanet() {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loadMint = true;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner(this.address);
    let civilizationId = this.civilizationId;
    let namePlanet = this.nameForm.get('name')?.value
    let blockNumber = await provider.getBlockNumber();
    let block = await provider.getBlock(blockNumber);
    let deadline = block.timestamp + 60;
    let value =  ethers.utils.parseEther(String(this.amountForPrice))
    let name = await this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'name')
    let nonces = await this._contractService.readContract(environment.fdc, RPC_URL, FDCAbi, 'nonces',[signer._address])
    this._planetService.getPermitSignature(signer,nonces,environment.fdc,environment.astraPlanet, value, deadline,name)
      .then(async (signature) => {
        this._contractService.writeContract(environment.astraPlanet, planetABI, 'mintPlanet',
          [civilizationId, namePlanet, deadline, signature.v,  signature.r, signature.s])
          .then(async (chargeProject) => {
            const charge = await chargeProject.wait();
            if(charge){
              await this._planetService.updatePlanet(this.address,namePlanet)
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.planet_minted'))
              this._router.navigate(['/dashboard/summary'])
              this.loadMint= false;
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            if(data.reason.includes('transfer amount exceeds balance')){
              this._notifierService.showNotificationError(this._translateService.instant('notification.no_fdc'))
            }else if(data.reason.includes('name already used')){
              this._notifierService.showNotificationError(this._translateService.instant('notification.error_mint_name'))
            }else{
              this._notifierService.showNotificationError(this._translateService.instant('notification.error_mint'))
            }
            this.loadMint= false;
          });
      }).catch((err) => {
      this.loadMint= false;
    });
  }

  setClaimPlanet(){
    this._dialog.open(DialogClaimOldPlanetComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
      data: {oldPlanet: this.oldPlanet, address: this.address},
    }).afterClosed().subscribe( (res:any) => {
      if (res && res.data) {
        this._router.navigate(['/dashboard/summary'])
        this.loadMint= false;
      }
    })
  }

  openInfoName(){
    this._dialog.open(DialogInfoNameComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
    })
  }

  openInfoCiv(){
    this._dialog.open(DialogInfoCivComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
    })
  }

}
