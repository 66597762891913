import {Component, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss'],
  animations:[
    trigger('toLeft', [
      transition(':enter', [
        style({ opacity: 0 ,marginLeft:-200, filter: 'blur(10px)'}),
        animate('1500ms', style({ opacity: 1,marginLeft:0 , filter: 'blur(0px)'}))
      ]),
    ]),
    trigger('toRight', [
      transition(':enter', [
        style({ opacity: 0 ,marginRight:-300, filter: 'blur(10px)'}),
        animate('1500ms', style({ opacity: 1,marginRight:0 , filter: 'blur(0px)'}))
      ]),
    ]),
  ]
})
export class UnderMaintenanceComponent implements OnInit{
  lang = 'it';
  constructor(
    private _dialog: MatDialog,
    private _translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    if(localStorage.getItem('lang') !== this.lang){
      const lang = localStorage.getItem('lang')
      if(lang){
        this.lang = lang
      }
    }
  }
  openLink(url:string){
    window.open(url)
  }
  setLang(lang:string) {
    localStorage.setItem('lang',lang)
    this.lang = lang
    this._translateService.setDefaultLang(lang)
    this._translateService.use(lang)
  }
  openTelegram(){
    const lang = this._translateService.getBrowserLang()
    if(lang === 'it'){
      window.open('https://t.me/+vtYmeCo798E5Y2Nk')
    } else {
      window.open('https://t.me/+ViLl2xiNAGI3MDM0')
    }
  }
  openForm(){
    window.open('https://docs.google.com/forms/d/15v8oHHdVsUb0dXfMKhOAK3vVVngtjfv235Od7Iyr05s/viewform?ts=64ef8792&edit_requested=true&pli=1')
  }
}
