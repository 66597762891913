import {Component, OnInit} from '@angular/core';
import {WalletService} from "@scalingparrots/dapp-angular-lib";
import {MatDialog} from "@angular/material/dialog";
import {PRIMARY_NETWORK} from "./core/utils/constant";
import {TranslateService} from "@ngx-translate/core";
import {PlanetService} from "./core/services/planet.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    constructor(
      public dialog: MatDialog,
      private _walletService: WalletService,
      private _translateService: TranslateService,
      private _planetService: PlanetService,
    ) {
      const userLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : this._translateService.getBrowserLang()
      if(userLang ==='it'){
        localStorage.setItem('lang','it')
        this._translateService.setDefaultLang(userLang);
        this._translateService.use(userLang);
      } else {
        localStorage.setItem('lang','en')
        this._translateService.setDefaultLang('en');
        this._translateService.use('en');
      }
    }

    ngOnInit(): void {
      const connected = localStorage.getItem('connected')
      if(!connected){
        localStorage.setItem('connected','false')
      }
      this._walletService.initNetwork(PRIMARY_NETWORK);
      this.getProvider();
      this._planetService.updateGlobalInfo();
    }

    async getProvider() {
      await this._walletService.getWebProvider();
    }

}
