<div class="flex flex-col justify-center gap-4 p-4 sm:p-5 text-center ">
  <div class="flex flex-row items-center justify-between gap-2">
    <label class="title text-white">{{'dialog.view_ships.title'|translate}}</label>
    <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer" (click)="_dialog.closeAll()"/>
  </div>
  <div class="flex flex-col w-full gap-3 overflow-x-auto h-64">
    <div *ngFor="let ship of ships" class="flex flex-row items-center gap-8">
      <img [src]="ship.uri" alt="" class="w-48">
      <div class="flex flex-col items-start justify-center gap-4">
        <label class="subtitle text-light-purple">{{'dialog.view_ships.name'|translate}}: <label class="subtitle text-white">{{ship.name}}</label></label>
        <label class="subtitle text-light-purple">{{'dialog.view_ships.qnt'|translate}}: <label class="subtitle text-white">{{ship.amount}}</label></label>
      </div>
    </div>
  </div>
</div>
