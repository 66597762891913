import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LandingComponent} from "./children/component/landing/landing.component";
import {AuthGuard} from "./core/guard/auth.guard";
import {UnderMaintenanceComponent} from "./children/component/under-maintenance/under-maintenance.component";

const underMaintenance = false

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./children/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

const maintenance: Routes = [
  {
    path: '',
    component: UnderMaintenanceComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(underMaintenance ? maintenance : routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
