import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-confirm-mint',
  templateUrl: './dialog-confirm-mint-planet.component.html',
  styleUrls: ['./dialog-confirm-mint-planet.component.scss']
})
export class DialogConfirmMintPlanetComponent implements OnInit{
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogConfirmMintPlanetComponent>,
  ) {}

  ngOnInit(){
  }
  confirmShips() {
    this._dialogRef.close({data: 'confirm'})
  }
}
