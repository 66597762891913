<div class="flex flex-col gap-10 px-3 lg:pl-0 lg:pr-10">
  <div class="flex flex-col items-start justify-start w-full max-w-lg gap-7">
    <div class="flex flex-row items-center justify-start gap-10">
      <label class="title text-white">{{'dashboard.statistics.ranking_beta'|translate}}</label>
      <img src="assets/images/icon/back.svg" class="w-6 cursor-pointer" [routerLink]="'/dashboard/statistics'"  alt="">
    </div>
    <table *ngIf="score" class="w-full py-4 px-4 text-sm text-left shadow-none">
      <thead class="text-xs text-light-gray uppercase bg-transparent">
      <tr>
        <th scope="col" class=" subtitle text-light-purple cursor-auto">
          {{"dashboard.statistics.name" | translate}}
        </th>
        <th scope="col" class=" subtitle text-light-purple cursor-auto">
          {{"dashboard.statistics.id" | translate}}
        </th>
        <th scope="col" class=" subtitle text-light-purple cursor-auto">
          {{"dashboard.statistics.score" | translate}}
        </th>
        <th scope="col" class=" subtitle text-light-purple cursor-auto">
          {{"dashboard.statistics.reward" | translate}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let planet of filterRanking;let i = index" [class]="i%2 ? 'bg-tab' : 'bg-transparent'">
        <td class="py-2 subtitle text-white">
          {{planet.name}}
        </td>
        <td class="py-2 subtitle text-white">
          {{planet.planetId}}
        </td>
        <td class="py-2 subtitle text-white">
          {{planet.score}}
        </td>
        <td class="py-2 subtitle text-white">
          {{planet.score}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
