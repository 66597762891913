import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-description-farm',
  templateUrl: './dialog-description-farm.component.html',
  styleUrls: ['./dialog-description-farm.component.scss']
})
export class DialogDescriptionFarmComponent implements OnInit{
  desc:any =''
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogDescriptionFarmComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogDescriptionFarmComponent
  ) {}

  ngOnInit(){
    this.desc = this.data;
  }

  Confirm(){
    this._dialogRef.close({data:'confirm'})
  }
}
