<div *ngIf="!this.loading" class="flex flex-col gap-10">
  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-2">
      <div class="flex flex-row items-center justify-start gap-10">
        <label class="title text-white">{{('dashboard.war.info_battle'|translate)}}</label>
        <img src="assets/images/icon/back.svg" class="w-6 cursor-pointer" (click)="cancelAttack()" alt="">
      </div>
    </div>
    <div *ngIf="viewInfoBattle" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full  max-w-7xl">
      <div class="flex flex-col gap-2">
        <div class="flex flex-row items-center gap-2 w-full !max-w-sm !mx-auto sm:!mx-0">
          <label class="text-light-gray title">{{viewInfoBattle.planetNameOff}} <label class="title_small text-light-purple">({{ viewInfoBattle.planetIdOff }})</label></label>
        </div>
        <div [class]="'container_data_attack_protected flex flex-col gap-2 !mx-auto md:!mx-0'">
          <label class="subtitle text-light-gray">{{'dashboard.war.fleet_enemy'|translate}} - {{viewInfoBattle.planetNameOff}}</label>
          <div class="flex flex-row items-center gap-4">
            <label class="desc-h text-light-purple">{{'dashboard.war.total_attack_value'|translate}}: <label class="text-light-gray">{{viewInfoBattle.totAtt}}</label></label>
            <label class="desc-h text-light-purple">{{'dashboard.war.fleets_resistance'|translate}}: <label class="text-light-gray">{{viewInfoBattle.resistanceOff}} %</label></label>
          </div>
          <label class="desc-h text-light-purple">{{"dashboard.war.speed_fleet" | translate}}: <label class="text-light-gray">{{viewInfoBattle.speedMin}} {{"dashboard.war.mil_km/h" | translate}}</label></label>
          <label class="desc-h text-light-purple">{{"dashboard.war.consume_fleet" | translate}}: <label class="text-light-gray">{{viewInfoBattle.kmHydro}} {{"dashboard.war.mil_km/hydro" | translate}}</label></label>
          <div class="flex flex-col gap-4 overflow-x-auto h-28">
            <div *ngFor="let fleet of viewInfoBattle.fleetOff ;let i = index" [class]="'button_fleet_battle bg-signarok-red text-white flex !flex-row items-center gap-2 cursor-pointer !py-2'">
              <span class="desc-h text-light-gray"> {{"dashboard.fleet.fleet_att" | translate}} {{fleet.id}}</span>
              <img src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleet(fleet)" alt="">
            </div>
          </div>
          <label *ngIf="viewInfoBattle.revealed && viewInfoBattle.amountsBurnOff > 0" class="subtitle text-light-gray">{{'dashboard.war.fleet_burned'|translate}}</label>
          <div *ngIf="viewInfoBattle.revealed && viewInfoBattle.amountsBurnOff > 0" class="flex flex-col overflow-x-auto h-28">
            <div *ngFor="let fleet of viewInfoBattle.fleetBurnOff;let i = index">
              <div *ngIf="getFleetBurned(fleet.amountsBurn) > 0" [class]="'button_fleet_battle bg-signarok-red text-white flex !flex-row items-center gap-2 cursor-pointer !py-2 mb-4'">
                <span class="desc-h text-light-gray"> {{"dashboard.fleet.fleet_att" | translate}} {{fleet.id}}</span>
                <img src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleetBurned(fleet)" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div [class]="'container_data_attack_no_border flex flex-col gap-4 mx-auto !mt-auto'" >
          <label class="subtitle text-light-gray">{{("dashboard.war.info_battle" | translate)}}</label>
          <div *ngIf="!viewInfoBattle.revoked" class="flex flex-col gap-3">
            <label *ngIf="viewInfoBattle.revealed" [class]="viewInfoBattle.offWin ? 'desc-h text-red' : 'desc-h text-green'">{{viewInfoBattle.offWin ? ('dashboard.war.lose'|translate):('dashboard.war.win'|translate)}}</label>
            <label class="desc-h text-light-purple">{{"dashboard.war.probabilities_win" | translate}}: <label class="text-light-gray">{{100 - viewInfoBattle.probabilitiesToWin}} %</label></label>
            <label *ngIf="viewInfoBattle.revealed" class="desc-h text-light-purple">{{"dashboard.war.random_number" | translate}}: <label class="text-light-gray">{{100 - viewInfoBattle.randomNumber}}</label></label>
            <label *ngIf="viewInfoBattle.revealed" class="desc-h text-light-purple">{{"dashboard.war.delta_win" | translate}}: <label class="text-light-gray">{{viewInfoBattle.deltaWin}}</label></label>
            <label *ngIf="viewInfoBattle.revealed" class="desc-h text-light-purple">{{"dashboard.war.reward" | translate}}: <label class="text-light-gray">{{viewInfoBattle.rewardStolen | currency: '':'':'1.2'}} FDC</label></label>
            <label *ngIf="viewInfoBattle.revealed" class="desc-h text-light-purple">{{viewInfoBattle.planetNameOff}}: <label class="text-light-gray">{{viewInfoBattle.scoreBattle ? viewInfoBattle.scoreBattle.scoreTotOff : 0}} {{"dashboard.war.points_off" | translate}}</label></label>
            <label *ngIf="viewInfoBattle.revealed" class="desc-h text-light-purple">{{viewInfoBattle.planetNameDef}}: <label class="text-light-gray">{{viewInfoBattle.scoreBattle ? viewInfoBattle.scoreBattle.scoreTotDef : 0}} {{"dashboard.war.points_def" | translate}}</label></label>
          </div>
          <div *ngIf="viewInfoBattle.revoked" class="flex flex-col gap-3">
            <label class="desc-h text-light-purple">{{"dashboard.war.probabilities_win" | translate}}: <label class="text-light-gray">{{100 - viewInfoBattle.probabilitiesToWin}} %</label></label>
            <label  class="desc-s text-light-purple">{{"dashboard.war.attack_value" | translate}}: <label  class="desc-s text-red">{{"dashboard.war.revoke" | translate}}</label></label>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <div class="flex flex-row items-center gap-4 w-full !max-w-sm !mx-auto sm:!ml-auto">
          <label class="text-light-gray title">{{viewInfoBattle.planetNameDef}} <label class="title_small text-light-purple">({{ viewInfoBattle.planetIdDef }})</label></label>
        </div>
        <div [class]="'container_data_attack_fleet flex flex-col gap-4 !mx-auto md:!ml-auto'">
          <label class="subtitle text-light-gray">{{'dashboard.war.fleet_def'|translate}}</label>
          <div class="flex flex-row items-center gap-4">
            <label class="desc-h text-light-purple">{{'dashboard.war.fleets_def'|translate}}: <label class="text-light-gray">{{viewInfoBattle.totDef }}</label></label>
            <label class="desc-h text-light-purple">{{'dashboard.war.fleets_resistance'|translate}}: <label class="text-light-gray">{{viewInfoBattle.resistanceDef}} %</label></label>
          </div>
          <div class="flex flex-col gap-4 overflow-x-auto h-28">
            <div *ngFor="let fleet of viewInfoBattle.fleetDef;let i = index" [class]="'button_fleet_battle bg-purple text-white flex !flex-row items-center gap-2 cursor-pointer !py-2'">
              <span class="desc-h text-light-gray"> {{"dashboard.fleet.fleet_def" | translate}} {{fleet.id}}</span>
              <img src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleet(fleet)" alt="">
            </div>
          </div>
          <label *ngIf="viewInfoBattle.revealed && viewInfoBattle.amountsBurnDef > 0" class="subtitle text-light-gray">{{'dashboard.war.fleet_burned'|translate}}</label>
          <div *ngIf="viewInfoBattle.revealed && viewInfoBattle.amountsBurnDef > 0" class="flex flex-col overflow-x-auto h-28">
            <div *ngFor="let fleet of viewInfoBattle.fleetBurnDef;let i = index">
              <div *ngIf="getFleetBurned(fleet.amountsBurn) > 0" [class]="'button_fleet_battle bg-purple text-white flex !flex-row items-center gap-2 cursor-pointer !py-2 mb-4'">
                <span class="desc-h text-light-gray"> {{"dashboard.fleet.fleet_def" | translate}} {{fleet.id}}</span>
                <img  src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleetBurned(fleet)" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
