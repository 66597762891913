import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-description-ships',
  templateUrl: './dialog-description-ships.component.html',
  styleUrls: ['./dialog-description-ships.component.scss']
})
export class DialogDescriptionShipsComponent implements OnInit{
  desc:any =''
  constructor(
    public _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: DialogDescriptionShipsComponent
  ) {}

  ngOnInit(){
    this.desc = this.data;
  }
}
