import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DashboardComponent} from "./dashboard.component";
import {DashboardRoutingModule} from "./dashboard-routing.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSelectModule} from "@angular/material/select";
import {MatTooltipModule} from "@angular/material/tooltip";
import { SummaryComponent } from './children/summary/summary.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import {TranslateModule} from "@ngx-translate/core";
import { HeaderComponent } from './components/header/header.component';
import { MintPlanetComponent } from './children/mint-planet/mint-planet.component';
import { StructureComponent } from './children/structure/structure.component';
import { ShipsComponent } from './children/ships/ships.component';
import { MapComponent } from './children/map/map.component';
import { WarComponent } from './children/war/war.component';
import { ResourcesComponent } from './children/resources/resources.component';
import { StatisticsComponent } from './children/statistics/statistics.component';
import { SupportComponent } from './children/support/support.component';
import { FleetComponent } from './children/fleet/fleet.component';
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import { ImageComponent } from './components/image/image.component';
import {ImageAnimateComponent} from "./components/image-animate/image-animate.component";
import { FooterComponent } from './components/footer/footer.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {ConnectWalletDialogComponent} from "./components/dialog/connect-wallet-dialog/connect-wallet-dialog.component";
import {OrderByPipe} from "../../core/pipe/pipeSort";
import {DialogChangeNameComponent} from "./components/dialog/dialog-change-name/dialog-change-name.component";
import {DialogMintShipsComponent} from "./components/dialog/dialog-mint-ships/dialog-mint-ships.component";
import {DialogUpdateStructureComponent} from "./components/dialog/dialog-update-structure/dialog-update-structure.component";
import { FarmingComponent } from './children/farming/farming.component';
import {DialogClaimOldPlanetComponent} from "./components/dialog/dialog-claim-old-planet/dialog-claim-old-planet.component";
import {DialogClaimOldMysteryComponent} from "./components/dialog/dialog-claim-old-mystery/dialog-claim-old-mystery.component";
import {DialogAddNewFleetComponent} from "./components/dialog/dialog-add-new-fleet/dialog-add-new-fleet.component";
import {DialogConfirmMintShipsComponent} from "./components/dialog/dialog-confirm-mint-ships/dialog-confirm-mint-ships.component";
import {MatMenuModule} from "@angular/material/menu";
import {DialogInfoNameComponent} from "./components/dialog/dialog-info-name/dialog-info-name.component";
import {DialogChangeCivilizationComponent} from "./components/dialog/dialog-change-civilization/dialog-change-civilization.component";
import { TimerComponent } from './components/timer/timer.component';
import {DialogConfirmMintPlanetComponent} from "./components/dialog/dialog-confirm-mint-planet/dialog-confirm-mint-planet.component";
import { SwapComponent } from './children/swap/swap.component';
import {DialogEditFleetComponent} from "./components/dialog/dialog-edit-fleet/dialog-edit-fleet.component";
import {DialogViewShipsMintedComponent} from "./components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
import {TimerCountdownComponent} from "./components/timer-countdown/timer-countdown.component";
import {DialogInfoCivComponent} from "./components/dialog/dialog-info-civ/dialog-info-civ.component";
import {DialogDescriptionShipsComponent} from "./components/dialog/dialog-description-ships/dialog-description-ships.component";
import {WarInfoOffComponent} from "./children/war/info/war-info-off/war-info-off.component";
import {WarInfoDefComponent} from "./children/war/info/war-info-def/war-info-def.component";
import {DialogViewShipsDestroyedComponent} from "./components/dialog/dialog-view-ships-destoyed/dialog-view-ships-destroyed.component";
import {DialogForceRevealComponent} from "./components/dialog/dialog-force-reveal/dialog-force-reveal.component";
import { RankingBetaComponent } from './children/ranking-beta/ranking-beta.component';
import {
  DialogDescriptionFarmComponent
} from "./components/dialog/dialog-description-farm/dialog-description-farm.component";
import {DialogWithdrawComponent} from "./components/dialog/dialog-withdraw/dialog-withdraw.component";
import {WarInfoOffOldComponent} from "./children/war/info/war-info-off-old/war-info-off-old.component";
import {WarInfoDefOldComponent} from "./children/war/info/war-info-def-old/war-info-def-old.component";
import {TimerHangarComponent} from "./components/timer-hangar/timer-hangar.component";

@NgModule({
  declarations: [
    DashboardComponent,
    SummaryComponent,
    SidebarComponent,
    HeaderComponent,
    DialogChangeNameComponent,
    DialogMintShipsComponent,
    DialogClaimOldPlanetComponent,
    DialogClaimOldMysteryComponent,
    DialogAddNewFleetComponent,
    DialogEditFleetComponent,
    DialogUpdateStructureComponent,
    DialogConfirmMintShipsComponent,
    DialogChangeCivilizationComponent,
    DialogInfoNameComponent,
    DialogInfoCivComponent,
    DialogConfirmMintPlanetComponent,
    DialogViewShipsMintedComponent,
    DialogDescriptionShipsComponent,
    DialogViewShipsDestroyedComponent,
    DialogForceRevealComponent,
    DialogDescriptionFarmComponent,
    DialogWithdrawComponent,
    ConnectWalletDialogComponent,
    MintPlanetComponent,
    StructureComponent,
    ShipsComponent,
    MapComponent,
    WarComponent,
    WarInfoOffComponent,
    WarInfoOffOldComponent,
    WarInfoDefComponent,
    WarInfoDefOldComponent,
    ResourcesComponent,
    StatisticsComponent,
    SupportComponent,
    FleetComponent,
    ImageComponent,
    ImageAnimateComponent,
    FooterComponent,
    OrderByPipe,
    FarmingComponent,
    TimerComponent,
    TimerCountdownComponent,
    TimerHangarComponent,
    SwapComponent,
    RankingBetaComponent,
  ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTooltipModule,
        TranslateModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatMenuModule,
    ],
  exports: [
    ImageComponent
  ]
})
export class DashboardModule { }
