import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../../environments/environment";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotifierService} from "../../../../../core/services/notifier.service";
import {TranslateService} from "@ngx-translate/core";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {PlanetService} from "../../../../../core/services/planet.service";
import {DialogInfoNameComponent} from "../dialog-info-name/dialog-info-name.component";
const planetABI = require('src/app/core/abi/AstraPlanetManager.json');

@Component({
  selector: 'app-dialog-claim-old-planet',
  templateUrl: './dialog-claim-old-planet.component.html',
  styleUrls: ['./dialog-claim-old-planet.component.scss']
})
export class DialogClaimOldPlanetComponent implements OnInit{
  oldPlanet:any;
  nameForm: FormGroup = new FormGroup({});
  fdc:any;
  address= '';
  civilizationId= 1;
  planetSelected:any;
  loading:boolean=false;
  claimPlanet:boolean=false;
  constructor(
    public _dialog: MatDialog,
    private _dialogRef: MatDialogRef<DialogClaimOldPlanetComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogClaimOldPlanetComponent,
    private _formBuilder: FormBuilder,
    private _notifierService: NotifierService,
    private _translateService: TranslateService,
    private _contractService: ContractService,
    private _planetService: PlanetService,
  ) {}

  ngOnInit(){
    const data = this.data
    this.oldPlanet = data.oldPlanet
    this.address = data.address
    this.nameForm = this._formBuilder.group({
      name: ['', Validators.required],
    });
  }

  selectFaction(id:number){
    this.civilizationId = id;
  }

  selectPlanet(i:any){
    this.planetSelected = i;
  }

  async confirmMint() {
    const checkNetwork = await this._planetService.checkNetwork();
    if(checkNetwork){
      return
    }
    this.loading = true;
    let civilizationId = this.civilizationId;
    let namePlanet = this.nameForm.get('name')?.value
    let oldPlanetId = this.oldPlanet[this.planetSelected].id
    const proof = this.oldPlanet[this.planetSelected].proof;
    this._contractService.writeContract(environment.astraPlanet, planetABI, 'claimPlanet',
      [civilizationId, namePlanet, oldPlanetId, proof])
      .then(async (chargeProject:any) => {
        const charge = await chargeProject.wait();
        if(charge){
          await this._planetService.updatePlanet(this.address,namePlanet)
          this._notifierService.showNotificationSuccess(this._translateService.instant('notification.planet_minted'))
          this._dialogRef.close({data:'confirm'})
        }
      })
      .catch((err:any) => {
        const error = JSON.stringify(err)
        const data = JSON.parse(error)
        if(data.reason.includes('transfer amount exceeds balance')){
          this._notifierService.showNotificationError(this._translateService.instant('notification.no_fdc'))
        } else if(data.reason.includes('tokenId already claimed')) {
          this._notifierService.showNotificationError(this._translateService.instant('notification.already_claimed'))
        } else if(data.reason.includes('name already used')){
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_mint_name'))
        } else{
          this._notifierService.showNotificationError(this._translateService.instant('notification.error_mint'))
        }
        this.loading = false;
      });
  }

  openInfoName(){
    this._dialog.open(DialogInfoNameComponent, {
      width: '90%',
      maxWidth: '700px',
      panelClass: 'css-dialog',
      disableClose: true,
    })
  }
}
