<div *ngIf="!this.loading" class="flex flex-col gap-10">
  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-2">
      <div class="flex flex-row items-center justify-start gap-10">
        <label class="title text-white">{{('dashboard.war.preview_battle'|translate)}}</label>
        <img src="assets/images/icon/back.svg" class="w-6 cursor-pointer" (click)="cancelAttack()" alt="">
      </div>
    </div>
    <div *ngIf="infoPlanetToAttack" class="flex flex-col responsive_table">
      <table class="table-responsive w-full max-w-7xl text-sm text-left bg-transparent shadow-none">
        <thead class="bg-transparent">
        <tr>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">{{"dashboard.war.planet" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-default">{{"dashboard.war.power" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-auto">{{"dashboard.war.shield" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-auto">
            <div class="flex flex-row items-center gap-2">
              {{"dashboard.war.delta" | translate}} <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.delta_description')">
            </div>
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-auto">
            <div class="flex flex-row items-center gap-2">
              {{"dashboard.war.max_reward" | translate}} <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.reward_description')">
            </div>
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-auto">
            <div class="flex flex-row items-center gap-2">
              <label >{{"dashboard.war.fee_farming" | translate}}</label>
              <img src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer" (click)="openDescription('dashboard.global.fee_farming_description')">
            </div>
          </th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-auto">{{"dashboard.war.distance" | translate}}</th>
          <th scope="col" class="p-2 subtitle text-light-purple cursor-auto">{{"dashboard.war.civilization" | translate}}</th>
        </tr>
        </thead>
        <tbody>
        <tr [class]="infoPlanetToAttack.name === planetSelected.name ?'bg-transparent tab_my_planet':'bg-transparent tab_enemy'">
          <td [attr.aria-label]="'dashboard.war.planet' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.name}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.power' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.power}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.shield' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.levelShield}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.delta' | translate" class="p-2 text-light-purple subtitle">
            <img *ngIf="innerWidth < 500" src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer mr-auto" (click)="openDescription('dashboard.global.delta_description')">
            <label class="desc-h text-white">{{infoPlanetToAttack.deltaLevelPlanet}}%</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.max_reward' | translate" class="p-2 text-light-purple subtitle">
            <img *ngIf="innerWidth < 500" src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer mr-auto" (click)="openDescription('dashboard.global.reward_description')">
            <label class="desc-h text-white">{{infoPlanetToAttack.maxRewardToStole | currency: '':'':'1.2'}} FDC</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.fee_farming' | translate" class="p-2 text-light-purple subtitle">
            <img *ngIf="innerWidth < 500" src="assets/images/icon/info_white.svg" alt="" class="w-4 cursor-pointer mr-auto" (click)="openDescription('dashboard.global.fee_farming_description')">
            <label class="desc-h text-white" >{{infoPlanetToAttack.percentageFeeWithdraw}}%</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.distance' | translate" class="p-2 text-light-purple subtitle">
            <label class="desc-h text-white">{{infoPlanetToAttack.distance}} {{"dashboard.war.mil_km" | translate}}</label>
          </td>
          <td [attr.aria-label]="'dashboard.war.civilization' | translate" class="p-2 text-light-purple subtitle">
            <div class="flex flex-row items-center justify-start gap-4">
              <img *ngIf="infoPlanetToAttack.isProtected" class="w-5" src="assets/images/icon/lock.svg" alt="" >
              <img *ngIf="infoPlanetToAttack.planetsCivilizationId === 1" class="h-7" src="assets/images/factions/signarok.png" alt="" >
              <img *ngIf="infoPlanetToAttack.planetsCivilizationId === 2" class="h-7" src="assets/images/factions/numaya.png" alt="" >
              <img *ngIf="infoPlanetToAttack.planetsCivilizationId === 3" class="h-7" src="assets/images/factions/afarit.png" alt="" >
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="infoPlanetToAttack" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full  max-w-7xl">
      <div [class]="'container_data_attack_protected flex flex-col gap-2 !mx-auto md:!mx-0'">
        <label class="subtitle text-light-gray">{{'dashboard.war.fleet_enemy'|translate}} - {{viewInfoBattle.planetNameOff}}</label>
        <div class="flex flex-row items-center gap-4">
          <label class="desc-h text-light-purple">{{'dashboard.war.total_attack_value'|translate}}: <label class="text-light-gray">{{viewInfoBattle.totalAtt}}</label></label>
          <label class="desc-h text-light-purple">{{'dashboard.war.fleets_resistance'|translate}}: <label class="text-light-gray">{{viewInfoBattle.resistanceOff}} %</label></label>
        </div>
        <label class="desc-h text-light-purple">{{"dashboard.war.speed_fleet" | translate}}: <label class="text-light-gray">{{viewInfoBattle.speedMin}} {{"dashboard.war.mil_km/h" | translate}}</label></label>
        <label class="desc-h text-light-purple">{{"dashboard.war.consume_fleet" | translate}}: <label class="text-light-gray">{{viewInfoBattle.kmHydro}} {{"dashboard.war.mil_km/hydro" | translate}}</label></label>
        <div class="flex flex-col gap-4 overflow-x-auto h-28">
          <div *ngFor="let fleet of viewInfoBattle.fleetOff;let i = index" [class]="'button_fleet_battle bg-signarok-red text-white flex !flex-row items-center gap-2 cursor-pointer !py-2'">
            <span class="desc-h text-light-gray"> {{"dashboard.fleet.fleet_att" | translate}} {{fleet.id}}</span>
            <img src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleet(fleet)" alt="">
          </div>
        </div>
      </div>
      <div [class]="'container_data_attack_no_border flex flex-col gap-4 mx-auto'" >
        <label class="subtitle text-light-gray">{{"dashboard.war.preview_attack" | translate}}</label>
        <div *ngIf="!viewInfoBattle.battle.revoked" class="flex flex-col gap-3">
          <label class="desc-h text-light-purple">{{"dashboard.war.probabilities_win" | translate}}: <label class="text-light-gray">{{100 - viewInfoBattle.probabilitiesToWin}} %</label></label>
          <label *ngIf="!viewInfoBattle.battle.revealed" class="desc-h text-light-purple">{{"dashboard.war.transportable_fdc" | translate}}: <label class="text-light-gray">{{viewInfoBattle.totalHold | currency: '':'':'1.2'}} FDC</label></label>
          <label *ngIf="!viewInfoBattle.battle.revealed" class="desc-h text-light-purple">{{"dashboard.war.travel_time" | translate}}: <label class="text-light-gray">{{viewInfoBattle.estimatedTime}}</label></label>
          <label *ngIf="!viewInfoBattle.battle.revealed" class="desc-h text-light-purple">{{"dashboard.war.total_fuel_cons" | translate}}: <label class="text-light-gray">{{viewInfoBattle.totalFuelCons | currency: '':'':'1.2'}} HYD</label></label>
          <label *ngIf="this.dateNow > viewInfoBattle.battle.startBattle && !viewInfoBattle.battle.revealed && !viewInfoBattle.battle.confirmed && this.dateNow > (viewInfoBattle.battle.startBattle + viewInfoBattle.battle.battleDelay.delayAttack)" class="desc-h text-red">{{"dashboard.war.stationary_consumption" | translate}}: <label class="text-light-gray">{{viewInfoBattle.amountHydroToConfirm | currency: '':'':'1.2'}} HYD</label></label>
          <label *ngIf="this.dateNow > viewInfoBattle.battle.startBattle && !viewInfoBattle.battle.revealed && !viewInfoBattle.battle.confirmed && this.dateNow < (viewInfoBattle.battle.startBattle + viewInfoBattle.battle.battleDelay.delayAttack)" class="desc-h text-red">{{"dashboard.war.start_consume_hydro" | translate}}: <label class="text-light-gray">{{getDataTimer(viewInfoBattle.battle.startBattle + viewInfoBattle.battle.battleDelay.delayAttack)}}</label></label>
          <label *ngIf="this.dateNow > viewInfoBattle.battle.startBattle && !viewInfoBattle.battle.revealed && !viewInfoBattle.battle.confirmed"
                 [class]="this.dateNow > viewInfoBattle.battle.startBattle + viewInfoBattle.battle.battleDelay.delayAttack ? 'desc-s text-red' : 'desc-s text-light-purple'">{{"dashboard.war.waiting" | translate}} <app-timer-countdown [time]="viewInfoBattle.battle.startBattle" class="desc-s text-white mx-auto"></app-timer-countdown>
          </label>
          <label *ngIf="this.dateNow > viewInfoBattle.battle.startBattle && !viewInfoBattle.battle.revealed && viewInfoBattle.battle.confirmed && !viewInfoBattle.battle.revoked" class="desc-s text-red">{{"dashboard.war.revelation" | translate}}</label>
          <label *ngIf="this.dateNow < viewInfoBattle.battle.startBattle" class="desc-s text-light-purple">{{"dashboard.war.arriving" | translate}} <app-timer [time]="viewInfoBattle.battle.startBattle" class="desc-s text-white mx-auto"></app-timer></label>
        </div>
        <div *ngIf="viewInfoBattle.battle.revoked" class="flex flex-col gap-3">
          <label class="desc-h text-light-purple">{{"dashboard.war.probabilities_win" | translate}}: <label class="text-light-gray">{{100 - viewInfoBattle.probabilitiesToWin}} %</label></label>
          <label  class="desc-s text-light-purple">{{"dashboard.war.attack_value" | translate}}: <label  class="desc-s text-red">{{"dashboard.war.revoke" | translate}}</label></label>
        </div>
      </div>
      <div [class]="'container_data_attack_fleet flex flex-col gap-4 !mx-auto md:!ml-auto'">
        <label class="subtitle text-light-gray">{{'dashboard.war.fleet_def'|translate}}</label>
        <div class="flex flex-row items-center gap-4">
          <label class="desc-h text-light-purple">{{'dashboard.war.fleets_def'|translate}}: <label class="text-light-gray">{{viewInfoBattle.totalDef}}</label></label>
          <label class="desc-h text-light-purple">{{'dashboard.war.fleets_resistance'|translate}}: <label class="text-light-gray">{{viewInfoBattle.resistanceDef}} %</label></label>
        </div>
        <div class="flex flex-col gap-4 overflow-x-auto h-28">
          <div *ngFor="let fleet of infoPlanetToAttack.fleetDef;let i = index" [class]="'button_fleet_battle bg-purple text-white flex !flex-row items-center gap-2 cursor-pointer !py-2'">
            <span class="desc-h text-light-gray"> {{"dashboard.fleet.fleet_def" | translate}} {{fleet.id}}</span>
            <img src="assets/images/icon/view.svg" class="w-4 cursor-pointer" (click)="viewInfoFleet(fleet)" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full max-w-7xl items-center justify-center gap-3">
      <button *ngIf="viewInfoBattle && viewInfoBattle.battle.confirmed && !viewInfoBattle.battle.revealed && (!loadReveal || battleID !== viewInfoBattle.battle.id )" [class]="(battleID === viewInfoBattle.battle.id || battleID === undefined)? 'button bg-purple text-white !m-0 !cursor-pointer' : 'button !m-0 bg-dark-black text-white opacity-60'" [disabled]="(battleID === viewInfoBattle.battle.id || battleID !== undefined)"
              [matTooltipClass]="{ 'tool-tip': true }" [matTooltipPosition]="'above'" [matTooltip]="'dashboard.war.reveal'|translate" (click)="revealAttack(viewInfoBattle.battle,viewInfoBattle.battle.index)">{{'dashboard.war.reveal_attack'|translate}}</button>
      <button *ngIf="viewInfoBattle && loadReveal && battleID === viewInfoBattle.battle.id" class="button  bg-purple text-white !m-0 flex items-center justify-center"><mat-spinner [diameter]="20"></mat-spinner></button>
      <button *ngIf="!viewInfoBattle.battle.revoked && !viewInfoBattle.battle.confirmed && !loadEmergencyRevokeDef && viewInfoBattle.battle.canDefRevoke" [class]="this.dateNow >= viewInfoBattle.battle.startBattle ? 'button bg-purple text-white !m-0 !cursor-pointer' : 'button !m-0 bg-dark-black text-white opacity-60'" [disabled]="this.dateNow < viewInfoBattle.battle.startBattle" (click)="emergencyRevokeAttackDef(viewInfoBattle.battle,viewInfoBattle.battle.index)">{{'dashboard.war.scare_off_fleets'|translate}}</button>
      <button *ngIf="viewInfoBattle && loadEmergencyRevokeDef" class="button  bg-purple text-white !m-0 flex items-center justify-center"><mat-spinner [diameter]="20"></mat-spinner></button>
    </div>
  </div>
</div>
<div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
