<div *ngIf="ship && ship !== [] && !loading" class="flex flex-col md:flex-row gap-12 px-0 sm:px-2 md:pr-20 md:pl-0">
  <div class="flex flex-col w-full md:w-3/4 gap-4">
    <div class="flex flex-col items-center justify-center w-full gap-4 ">
      <div [class]="amountShipsInCart > 0 ?
      'container_cart bg-purple w-full flex flex-row items-center justify-center cursor-pointer text-white gap-3' :
       'container_cart opacity-60 bg-dark-black w-full flex flex-row items-center justify-center cursor-pointer text-white gap-3'" (click)="ConfirmBuyShips()">
        <app-image src="icon/shopping_cart.svg" class="w-5"></app-image>
        <label class="amount cursor-pointer">{{amountShipsInCart}} {{"dashboard.ships.ships" | translate}}</label>
      </div>
      <label *ngIf="oldMystery && viewClaimMystery" class=" desc-h text-light-purple cursor-pointer" (click)="setClaimMystery()">{{'dashboard.mint.already_have_mystery'|translate}}</label>
    </div>
    <div class="flex flex-row items-center gap-5 mx-auto">
      <div [class]="civId === 1 ? 'container_images_selected':'container_images'">
        <img class="h-12 cursor-pointer" src="assets/images/factions/signarok.png" alt="" (click)="selectFaction(1)">
      </div>
      <div [class]="civId === 2 ? 'container_images_selected':'container_images'">
        <img class="h-12 cursor-pointer" src="assets/images/factions/numaya.png" alt="" (click)="selectFaction(2)">
      </div>
      <div [class]="civId === 3 ? 'container_images_selected':'container_images'">
        <img class="h-12 cursor-pointer" src="assets/images/factions/afarit.png" alt="" (click)="selectFaction(3)">
      </div>
    </div>
    <div class="flex flex-row gap-4 items-center w-full max-w-5xl mx-auto md:px-8">
      <img src="assets/images/icon/arrow_left.png" alt="" class="cursor-pointer w-8 md:w-10" (click)="backCard()">
      <app-image-animate (isEndAnimation)="setDoneAnimation($event)" [type]="type" [src]="ship.imageURL" [class]="this.levelAR < ship.level || (this.planetSelected && ship.civId !== this.planetSelected.civilization) ? 'w-full max-w-3xl grayscale overflow-hidden' : 'w-full max-w-3xl overflow-hidden'"></app-image-animate>
      <img src="assets/images/icon/arrow_right.png" alt="" class="cursor-pointer w-8 md:w-10" (click)="nextCard()">
    </div>
    <div *ngIf="!loading" class="flex flex-col w-full gap-2">
      <button *ngIf="!loadMint" [disabled]="loading || this.levelAR < ship.level || ship.civId !== this.planetSelected.civilization"
              [class]="loading || this.levelAR < ship.level || ship.civId !== this.planetSelected.civilization ? 'button bg-dark-black opacity-60 text-white !mx-auto' : 'button bg-purple text-white !mx-auto'" (click)="mintShips()">{{"dashboard.ships.buy_now" | translate}}</button>
      <button *ngIf="loadMint" class="button bg-purple text-white !mx-auto flex items-center justify-center" ><mat-spinner [diameter]="20"></mat-spinner></button>
      <label *ngIf="!loading && this.levelAR >= ship.level && ship.civId === this.planetSelected.civilization" class="subtitle cursor-pointer text-light-purple !mx-auto underline"  (click)="mintMoreShips()">{{"dashboard.ships.add_to_cart" | translate}}</label>
      <button *ngIf="this.levelAR < ship.level" class="button_no_level text-white !mx-auto" >{{'dashboard.ships.armory_lv'|translate}}: {{this.levelAR}}<label class="desc-h text-signarok-red" >{{' / ' +ship.level}}</label></button>
      <label *ngIf="this.planetSelected && ship.civId !== this.planetSelected.civilization" class="desc-h text-red !mx-auto">{{"dashboard.ships.no_civilization_to_build_ships" | translate}}</label>
    </div>
  </div>
  <div class="flex flex-col w-full md:w-1/4 gap-6">
    <div class="flex flex-col gap-2">
      <div class="flex flex-row gap-2 items-start">
        <label class="title text-white mt-1">{{ship.name}}</label>
        <img src="assets/images/icon/info.svg" alt="" class="w-6 cursor-pointer" (click)="openDescription(ship.desc)">
      </div>
      <label class="subtitle text-white">{{"dashboard.ships.quantity" | translate}}: <label class="amount_ships text-light-purple">{{ship.quantity}}</label><label *ngIf="ship.maxSupply > 0" class="amount_ships text-white">/{{ship.maxSupply}}</label></label>
    </div>
    <div class="flex flex-row gap-5 items-end">
      <div class="flex flex-row gap-2 items-end">
        <app-image src="/icon_token/fdc.svg" class="w-5"></app-image>
        <label class="subtitle text-white">{{ship.priceFDC}}</label>
      </div>
      <div class="flex flex-row gap-2 items-end">
        <app-image src="/icon/Timer.svg" class="w-5"></app-image>
        <label class="desc-h text-white">{{ship.time}}</label>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <table class="w-full max-w-7xl text-sm text-left bg-transparent shadow-none text-gray-500 dark:text-gray-400">
        <tbody>
        <tr class="bg-tab  gap-2">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.size" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.sizeValue}}</td>
        </tr>
        <tr class="bg-transparent  gap-2">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.level" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.level}}</td>
        </tr>
        <tr class="bg-tab  gap-2">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.attack" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.attack}}</td>
        </tr>
        <tr class="bg-transparent  gap-2">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.defense" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.defense}}</td>
        </tr>
        <tr class="bg-tab  gap-2">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.velocity" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.speed}} {{"dashboard.war.mil_km/h" | translate}}</td>
        </tr>
        <tr class="bg-transparent gap-2 ">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.resistance" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.resistance}} %</td>
        </tr>
        <tr class="bg-tab  gap-2">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.hold" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.hold}} {{"dashboard.ships.fdc" | translate}}</td>
        </tr>
        <tr class="bg-transparent  gap-2">
          <th class="subtitle p-2 text-white cursor-default">{{"dashboard.ships.fuel" | translate}}</th>
          <td class="subtitle p-2 text-white">{{ship.kmHydro}} {{"dashboard.war.mil_km/hydro" | translate}}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="arrayShips && arrayShips.length > 0" class="card_time_build flex flex-col items-start w-full p-3 gap-2" >
      <div class="flex flex-row items-center justify-between w-full">
        <label class="subtitle text-light-purple">{{'dashboard.summary.build_time'|translate}}</label>
        <button *ngIf="arrayShips && arrayShips.length >0 && !loadClaimAll" [class]="getFinishBuild() ? 'button_switch !h-6 !w-20 bg-light-purple text-white !m-0 flex items-center justify-center' : 'button_switch !h-6 !w-20 text-white bg-dark-black opacity-60 !m-0 flex items-center justify-center'" [disabled]="!getFinishBuild()" (click)="claimMultipleShips(arrayShips)">{{'dashboard.summary.claim_all'|translate}}</button>
        <button *ngIf="loadClaimAll" [class]="'button_switch !h-6 !w-20 bg-light-purple text-white !m-0 flex items-center justify-center'">
          <mat-spinner [diameter]="20"></mat-spinner>
        </button>
      </div>
      <div *ngIf="!loadingClaim" class="flex flex-col w-full gap-2 overflow-x-auto h-24">
        <div *ngFor="let data of arrayShips; let i = index" class="flex flex-row w-full max-w-xs items-center justify-between">
          <label class="desc-h text-white">{{data.amount}}</label>
          <div class="flex flex-row items-center gap-2 text-start w-32">
            <app-timer *ngIf="i === 0 && this.dateNow < data.endUpdateAllShips" [time]="data.endUpdateAllShips" class="desc-s text-white"></app-timer>
            <app-timer *ngIf="i !== 0 && this.dateNow > arrayShips[i-1].endUpdateAllShips && this.dateNow < data.endUpdateAllShips" [time]="data.endUpdateAllShips" class="desc-s text-white"></app-timer>
            <label *ngIf="i !== 0 && this.dateNow < arrayShips[i-1].endUpdateAllShips " class="desc-h text-orange-400">{{"dashboard.summary.waiting" | translate}} - {{getTimeBuild(arrayShips[i].endUpdateAllShips - arrayShips[i-1].endUpdateAllShips)}}</label>
            <label *ngIf="this.dateNow > data.endUpdateAllShips " class="desc-h text-light-purple">{{"dashboard.summary.finish" | translate}}</label>
          </div>
          <img src="assets/images/icon/info.svg" alt="" class="w-5 cursor-pointer" (click)="viewShips(data.ships)">
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="this.loading" class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
  <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
</div>
<div *ngIf="ship && ship !== [] && !this.noPlanet && !this.loading && this.planetSelected" class="flex overflow-x-auto space-x-8 bg-red-200 items-center py-2 mt-16 w-full">
  <section  *ngFor="let ship of shipsFiltered | orderBy: 'sizeValue':'asc' ,let i = index "  class="flex-shrink-0 card_ships gap-4 mb-4 p-2 cursor-pointer" (click)="selectCard(ship.name, ship)">
    <span>
      <img *ngIf="ship.civId !== this.planetSelected.civilization || this.levelAR < ship.level" [src]="ship.imageURL" [class]="'relative z-10 grayscale image_ships_selected_disabled ml-0'" alt="">
      <img *ngIf="ship.civId === this.planetSelected.civilization && this.levelAR >= ship.level " [src]="ship.imageURL" [class]="nameSelected === ship.name ? 'relative z-10 image_ships_selected ml-0' : 'relative z-10 image_ships ml-0'" alt="">
    </span>
    <div [ngStyle]="{background: nameSelected === ship.name ?'url(assets/images/sidebar_icon/vector_selected_sm.svg)': ''}" [class]=" nameSelected === ship.name ? 'flex justify-start items-center card_selected' :'flex justify-start items-center outside'">
      <div [class]=" nameSelected === ship.name ? 'flex justify-start items-center gap-5 mr-0' :'flex justify-start items-center inside gap-5 mr-0'">
        <label [class]="'subtitle text-white cursor-pointer'">{{ship.name}}</label>
      </div>
    </div>
  </section>
</div>
<div *ngIf="ship && ship !== [] && this.noPlanet && !this.loading && !this.planetSelected" class="flex overflow-x-auto space-x-8 bg-red-200 items-center py-2 mt-16 w-full">
  <section  *ngFor="let ship of shipsFiltered | orderBy: 'sizeValue':'asc' ,let i = index "  class="flex-shrink-0 card_ships gap-4 mb-4 p-2 cursor-pointer" (click)="selectCard(ship.name, ship)">
    <span><img [src]="ship.imageURL" [class]="'relative z-10 grayscale image_ships_selected_disabled ml-0'" alt=""></span>
    <div [ngStyle]="{background: nameSelected === ship.name ?'url(assets/images/sidebar_icon/vector_selected_sm.svg)': ''}" [class]=" nameSelected === ship.name ? 'flex justify-start items-center card_selected' :'flex justify-start items-center outside'">
      <div [class]=" nameSelected === ship.name ? 'flex justify-start items-center gap-5 mr-0' :'flex justify-start items-center inside gap-5 mr-0'">
        <label [class]="'subtitle text-white'">{{ship.name}}</label>
      </div>
    </div>
  </section>
</div>
