import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-dialog-info-civ',
  templateUrl: './dialog-info-civ.component.html',
  styleUrls: ['./dialog-info-civ.component.scss']
})
export class DialogInfoCivComponent implements OnInit{
  constructor(
    public _dialog: MatDialog,
  ) {}

  ngOnInit(){
  }
}
