<nav class="flex flex-col gap-6 container_routing pb-10 md:pb-0 md:pt-4">
  <div class="flex flex-row sm:flex-col-reverse lg:hidden justify-around sm:justify-start items-center sm:items-start px-5 pt-10 sm:px-12 md:px-6 gap-2 sm:gap-6">
    <div class="flex flex-row justify-start items-center gap-4 md:pl-6">
      <app-image src="icon_token/fdc.svg" class="w-6"></app-image>
      <label class="amount">{{fdc | currency: '':'':'1.2-3'}}</label>
    </div>
    <div class="flex flex-row justify-start items-center gap-4 md:pl-6">
      <app-image src="icon_token/hydro.svg" class="w-6"></app-image>
      <label class="amount">{{hydro| currency: '':'':'1.2-3'}}</label>
    </div>
    <div class="flex flex-row sm:w-full md:hidden !justify-end gap-4">
      <img alt="" src="assets/images/icon/close.svg" class="w-6 cursor-pointer mr-0 ml-auto" (click)="drawer.close()"/>
    </div>
  </div>
  <div [ngStyle]="{background: path === 'summary' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/summary')">
    <app-image [src]="path === 'summary' ? '/sidebar_icon/summary.svg' : '/sidebar_icon/summary-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'summary' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.dashboard" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/summary')">
    <app-image [src]="path === 'summary' ? '/sidebar_icon/summary.svg' : '/sidebar_icon/summary-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'summary' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.dashboard" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'planet' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/planet')">
    <app-image [src]="path === 'planet' ? '/sidebar_icon/mint-planet.svg' : '/sidebar_icon/mint-planet-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'planet' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.minting_planet" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/planet')">
    <app-image [src]="path === 'planet' ? '/sidebar_icon/mint-planet.svg' : '/sidebar_icon/mint-planet-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'planet' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.minting_planet" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'structure' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/structure')">
    <app-image [src]="path === 'structure' ? '/sidebar_icon/structure.svg' : '/sidebar_icon/structure-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'structure' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.structure" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/structure')">
    <app-image [src]="path === 'structure' ? '/sidebar_icon/structure.svg' : '/sidebar_icon/structure-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'structure' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.structure" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'ships' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/ships')">
    <app-image [src]="path === 'ships' ? '/sidebar_icon/ships.svg' : '/sidebar_icon/ships-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'ships' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.ships" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/ships')">
    <app-image [src]="path === 'ships' ? '/sidebar_icon/ships.svg' : '/sidebar_icon/ships-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'ships' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.ships" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'map' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/map')">
    <app-image [src]="path === 'map' ? '/sidebar_icon/map.svg' : '/sidebar_icon/map-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'map' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.map" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/map')">
    <app-image [src]="path === 'map' ? '/sidebar_icon/map.svg' : '/sidebar_icon/map-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'map' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.map" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'fleet' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/fleet')">
    <app-image [src]="path === 'fleet' ? '/sidebar_icon/fleet.svg' : '/sidebar_icon/fleet-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'fleet' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.fleet" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/fleet')">
    <app-image [src]="path === 'fleet' ? '/sidebar_icon/fleet.svg' : '/sidebar_icon/fleet-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'fleet' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.fleet" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'war' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/war')">
    <app-image [src]="path === 'war' ? '/sidebar_icon/war.svg' : '/sidebar_icon/war-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'war' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.war" | translate}}</label>
    <span *ngIf="attackUpComing && planetSelected && planetSelected.length >0" class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-3 bg-red"></span>
    </span>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/war')">
    <app-image [src]="path === 'war' ? '/sidebar_icon/war.svg' : '/sidebar_icon/war-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'war' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.war" | translate}}</label>
    <span *ngIf="attackUpComing && planetSelected && planetSelected.length >0" class="relative flex h-3 w-3">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red opacity-75"></span>
        <span class="relative inline-flex rounded-full h-3 w-3 bg-red"></span>
    </span>
  </div>
  <div [ngStyle]="{background: path === 'statistics' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/statistics')">
    <app-image [src]="path === 'statistics' ? '/sidebar_icon/statistics.svg' : '/sidebar_icon/statistics-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'statistics' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.statistics" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/statistics')">
    <app-image [src]="path === 'statistics' ? '/sidebar_icon/statistics.svg' : '/sidebar_icon/statistics-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'statistics' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.statistics" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'farming' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/farming')">
    <app-image [src]="path === 'farming' ? '/sidebar_icon/farming.svg' : '/sidebar_icon/farming-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'farming' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.farming" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/farming')">
    <app-image [src]="path === 'farming' ? '/sidebar_icon/farming.svg' : '/sidebar_icon/farming-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'farming' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.farming" | translate}}</label>
  </div>
  <div [ngStyle]="{background: path === 'swap' ?'url(assets/images/sidebar_icon/vector_selected.svg)': 'url(assets/images/sidebar_icon/vector.svg)'}" class="hidden lg:flex lg:flex-row justify-start items-center gap-5 card" (click)="goToPage('/dashboard/swap')">
    <app-image [src]="path === 'swap' ? '/sidebar_icon/swap.svg' : '/sidebar_icon/swap-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'swap' ? 'text_icon_selected text-white' : 'text_icon text-light-gray'">{{"dashboard.sidebar.swap" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="goToPage('/dashboard/swap')">
    <app-image [src]="path === 'swap' ? '/sidebar_icon/swap.svg' : '/sidebar_icon/swap-gray.svg'" class="w-5"></app-image>
    <label [class]="path === 'swap' ? 'text_icon_selected text-light-purple' : 'text_icon text-light-gray'">{{"dashboard.sidebar.swap" | translate}}</label>
  </div>
  <div class="flex flex-row lg:hidden justify-start items-center gap-5 card" (click)="disconnectWallet()">
    <app-image [src]="'/sidebar_icon/logout.svg'" class="w-5"></app-image>
    <label [class]="'text_icon text-light-gray'">{{"dashboard.sidebar.logout" | translate}}</label>
  </div>
</nav>
