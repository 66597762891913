import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './children/component/landing/landing.component';
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {DappAngularLibModule} from "@scalingparrots/dapp-angular-lib";
import {MatDialogModule} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {DashboardModule} from "./children/dashboard/dashboard.module";
import {NotifierComponent} from "./children/dashboard/components/notifier/notifier.component";
import {MatMenuModule} from "@angular/material/menu";
import { UnderMaintenanceComponent } from './children/component/under-maintenance/under-maintenance.component';
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    NotifierComponent,
    UnderMaintenanceComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        DappAngularLibModule,
        BrowserAnimationsModule,
        MatDialogModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        DashboardModule,
        MatMenuModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
