import {environment} from "../../../environments/environment";

export const RPC_URL = environment.production ? 'https://polygon-rpc.com' : 'https://sparkling-long-county.blast-sepolia.quiknode.pro/0231fe01315f37b74255ece460ce42f52a188928/';

export const PRIMARY_NETWORK = {
  chainId:  environment.production ? '0x89' :'0xa0c71fd',
  chainName:  environment.production ?  'Polygon' :'Blast Sepolia',
  nativeCurrency: {
    name:  environment.production ? 'Polygon' : 'ETH',
    symbol: environment.production ? 'MATIC' : 'ETH',
    decimals: 18,
  },
  rpcUrls:  environment.production ?['https://polygon-rpc.com']:['https://sparkling-long-county.blast-sepolia.quiknode.pro/0231fe01315f37b74255ece460ce42f52a188928/'],
  blockExplorerUrls:  environment.production ?['https://polygonscan.com/'] : ['https://testnet.blastscan.io/'],
};
export const CONTRACT_ADDRESS =
  '0x0000000000000000000000000000000000000000';
