import {Component, OnInit} from '@angular/core';
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {AccountService} from "../../../../core/services/account.service";
import {PlanetService} from "../../../../core/services/planet.service";
import {NotifierService} from "../../../../core/services/notifier.service";
import {environment} from "../../../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {DialogAddNewFleetComponent} from "../../components/dialog/dialog-add-new-fleet/dialog-add-new-fleet.component";
import {DialogEditFleetComponent} from "../../components/dialog/dialog-edit-fleet/dialog-edit-fleet.component";
import {DialogViewShipsMintedComponent} from "../../components/dialog/dialog-view-ships-minted/dialog-view-ships-minted.component";
const fleetABI = require('src/app/core/abi/AstraFleet.json');
const allShips = require('src/app/core/ships/ship.json');

@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss']
})
export class FleetComponent implements OnInit{
  address:string = '';
  type:string = 'all';
  fleets:any[]=[];
  filterFleet:any[]=[];
  myShipBalance:any[]=[];
  fleetSelected:any;
  levelArmory:any;
  viewAllShips:any;
  dateNow:any;
  fleetSelectedType:{ shipsType: any[], amounts: any[] }={ shipsType: [], amounts: [] };
  newFleetSelectedType:{ shipsType: any[], amounts: any[] }={ shipsType: [], amounts: [] };
  ships:any[]=[];
  allShips:any[]=[];
  maxSizeFleet:any;
  fleetIndex:any;
  maxFleetOff:number=1;
  maxFleetDef:number=1;
  fleeDefLength:number=0;
  fleeOffLength:number=0;
  planetSelected:any;
  sumShips=0;
  loadRemove:boolean=false;
  isPlanetInPhaseReveal:boolean=false;
  loadingBuild:boolean=false;
  haveAllowance:boolean=false;
  loadingUpdateShipsAmount:boolean=false;
  loading:boolean=false;
  noPlanet:boolean=false;
  constructor(
    private _contractService:ContractService,
    private _accountService:AccountService,
    private _planetService:PlanetService,
    private _notifierService:NotifierService,
    private _translateService:TranslateService,
    private _dialog:MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.loading = true;
    this.dateNow = Math.floor(Date.now()/1000)
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          let planetSelected = localStorage.getItem(this.address+'planetSelected')
          this._planetService.getPlanet().subscribe({
            next: async (planets) => {
              if (planets && planets.length > 0 && this.planetSelected === undefined) {
                const planet = planets.filter((data) => data.name === planetSelected)
                if(planet && planet.length >0){
                  this.planetSelected = planet[0]
                  this.updateData(this.planetSelected.id)
                }
              } else {
                this.sumShips = 0;
                this.updateData(0)
                this.noPlanet = true;
                this.loading = false;
              }
            }
          });
        }
      }
    })
    setInterval(() => {
      this.dateNow = Math.floor(Date.now()/1000)
    }, 1000);
  }

  updateData(planetId:any){
    this._planetService.getInfoPlanetFleet(planetId,this.address).subscribe({
      next: async (info) => {
        if(info !== null){
          this.isPlanetInPhaseReveal = info.isPlanetInPhaseReveal;
          this.haveAllowance = info.haveAllowance
          this.maxFleetOff = info.maxFleetOff
          this.maxFleetDef = info.maxFleetDef
          this.viewAllShips = this._planetService.createShips(allShips)
          this.fleeDefLength = info.fleetDef ? info.fleetDef.length : 0;
          this.fleeOffLength = info.fleetOff ? info.fleetOff.length : 0;
          this.myShipBalance = info.shipBalance
          this.loading= false;
          this._planetService.getGlobalInfo().subscribe({
            next: async (info) => {
              if (info && info.maxSizeFleet) {
                this.maxSizeFleet = info.maxSizeFleet
              }
            }
          });
          this.getShipImageWithBalance(allShips,info.shipBalance)
          this.getShips(this.viewAllShips,info.fleetOff,info.fleetDef,info.shipBalance)
          this.levelArmory = info.levelAr
        }
      }
    })
  }

  getShipImageWithBalance(infoShip:any,shipBalance:any){
    const allShips = this._planetService.createShips(infoShip)
    const myShipBalance = shipBalance
    const ships = []
    for(let i = 0; i < myShipBalance.ship_type.length; i++){
      const allData = allShips[myShipBalance.ship_type[i]]
      ships.push({uri: allData.imageURL,size: allData.metadata.size,type:myShipBalance.ship_type[i],amount:myShipBalance.amount[i],name:allData.metadata.name})
    }
    this.myShipBalance = ships;
  }

  async updateFleet(fleetOff:any,fleetDef:any) {
    this.sumShips = 0;
    let fleets: any[] = [];
    if(fleetOff && fleetOff.length >0){
      for (let i = 0; i < fleetOff.length; i++) {
        const firstShipsImage = this.viewAllShips[fleetOff[i].shipsType[0]].imageURL
        const fleetData = {
          index: i,
          name: 'fleet_att',
          name_filter: 'fleet_att_' +i,
          type: 'off',
          id: fleetOff[i].id,
          resistance: fleetOff[i].resistance,
          speedMin: fleetOff[i].speedMin,
          totalAtt: fleetOff[i].totAtt,
          totalDef: fleetOff[i].totDef,
          usedTo: fleetOff[i].usedTo,
          kmHydro: fleetOff[i].kmHydro / 10**2,
          totalFuelCons: this._planetService.formatBigNumber(fleetOff[i].totFuelCons),
          totalHold: this._planetService.formatBigNumber(fleetOff[i].totHold),
          totalSize: fleetOff[i].totSize,
          shipsType: fleetOff[i].shipsType,
          amounts: fleetOff[i].amounts,
          isUsed: fleetOff[i].isUsed,
          url: firstShipsImage,
        }
        fleets.push(fleetData)
        if (i === fleetOff.length - 1) {
          if(fleetDef && fleetDef.length >0){
            for (let i = 0; i < fleetDef.length; i++) {
              const firstShipsImage = this.viewAllShips[fleetDef[i].shipsType[0]].imageURL
              const fleetData = {
                index: i,
                name: 'fleet_def',
                name_filter: 'fleet_def_' +i,
                type: 'def',
                id: fleetDef[i].id,
                resistance: fleetDef[i].resistance,
                speedMin: fleetDef[i].speedMin,
                totalAtt: fleetDef[i].totAtt,
                totalDef: fleetDef[i].totDef,
                totalFuelCons: this._planetService.formatBigNumber(fleetDef[i].totFuelCons),
                totalHold: this._planetService.formatBigNumber(fleetDef[i].totHold),
                totalSize: fleetDef[i].totSize,
                shipsType: fleetDef[i].shipsType,
                amounts: fleetDef[i].amounts,
                isUsed: fleetDef[i].isUsed,
                url: firstShipsImage,
              }
              fleets.push(fleetData)
              if (i === fleetDef.length - 1) {
                this.fleets = fleets;
                this.setFleet(this.type)
                this.fleetSelected = fleets[0];
                this.fleetSelectedType = {
                  shipsType: fleets[0].shipsType,
                  amounts: fleets[0].amounts,
                };
                this.newFleetSelectedType ={
                  shipsType: fleets[0].shipsType,
                  amounts: fleets[0].amounts,
                };
                this.updateShips(fleets[0])
                this.loadingBuild= false;
                this.loadingUpdateShipsAmount= false;
                this.loading= false;
              }
            }
          } else {
            this.fleets = fleets;
            this.setFleet(this.type)
            this.fleetSelected = fleets[0];
            this.fleetSelectedType = {
              shipsType: fleets[0].shipsType,
              amounts: fleets[0].amounts,
            };
            this.newFleetSelectedType ={
              shipsType: fleets[0].shipsType,
              amounts: fleets[0].amounts,
            };
            this.updateShips(fleets[0])
            this.loadingBuild= false;
            this.loadingUpdateShipsAmount= false;
            this.loading= false;
          }
        }
      }
    } else if(fleetDef && fleetDef.length >0){
      for (let i = 0; i < fleetDef.length; i++) {
        const firstShipsImage = this.viewAllShips[fleetDef[i].shipsType[0]].imageURL
        const fleetData = {
          index: i,
          name: 'fleet_def',
          name_filter: 'fleet_def_' +i,
          type: 'def',
          id: fleetDef[i].id,
          resistance: fleetDef[i].resistance,
          speedMin: fleetDef[i].speedMin,
          totalAtt: fleetDef[i].totAtt,
          totalDef: fleetDef[i].totDef,
          totalFuelCons: this._planetService.formatBigNumber(fleetDef[i].totFuelCons),
          totalHold: this._planetService.formatBigNumber(fleetDef[i].totHold),
          totalSize: fleetDef[i].totSize,
          shipsType: fleetDef[i].shipsType,
          amounts: fleetDef[i].amounts,
          isUsed: fleetDef[i].isUsed,
          url: firstShipsImage,
        }
        fleets.push(fleetData)
        if (i === fleetDef.length - 1) {
          this.fleets = fleets;
          this.setFleet(this.type)
          this.fleetSelected = fleets[0];
          this.fleetSelectedType = {
            shipsType: fleets[0].shipsType,
            amounts: fleets[0].amounts,
          };
          this.newFleetSelectedType ={
            shipsType: fleets[0].shipsType,
            amounts: fleets[0].amounts,
          };
          this.updateShips(fleets[0])
          this.loadingBuild= false;
          this.loadingUpdateShipsAmount= false;
          this.loading= false;
        }
      }
    } else {
      this.loadingBuild= false;
      this.loadingUpdateShipsAmount= false;
      this.loading= false;
    }
  }

  async getShips(infoShip: any,fleetOff:any,fleetDef:any,allShipBalance:any) {
    let ships :any[] = [];
    for (let i = 0; i < infoShip.length; i++) {
      let balance = 0;
      if(allShipBalance.ship_type.includes(i)){
        const index = allShipBalance.ship_type.findIndex((data:any)=> data === i)
        balance = allShipBalance.amount[index]
      }
      const ship = {
        imageURL : 'assets/images/ships/'+i+'.png',
        desc : this._translateService.instant('dashboard.ships.'+ infoShip[i].metadata.name.toLowerCase()),
        id: i,
        name: infoShip[i].metadata.name,
        civId: infoShip[i].mintInfo.civId,
        level: infoShip[i].mintInfo.level,
        quantity: balance,
        size: 0,
        valueOfSize: infoShip[i].metadata.size,
      }
      ships.push(ship)
      if (i === infoShip.length - 1) {
        ships = ships.sort(function (
          a: { id: number },
          b: { id: number }
        ) {
          return a.id - b.id;
        });
        this.allShips = ships;
        this.ships = ships;
        await this.updateFleet(fleetOff,fleetDef)
      }
    }
  }
  setFleet(type:any){
    if(type === 'def'){
      this.filterFleet = this.fleets.filter((data)=>data.type === 'def')
    } else if(type === 'off'){
      this.filterFleet = this.fleets.filter((data)=>data.type === 'off')
    } else if(type === 'all'){
      this.filterFleet = this.fleets
    }
    this.type = type
  }
  editFleet(fleet:any){
    if(fleet.type === 'def' && this.isPlanetInPhaseReveal){
      return;
    }
    if(this.myShipBalance && this.myShipBalance.length === 0 && fleet.totalSize === 0){
      this._notifierService.showNotificationError(this._translateService.instant('notification.no_ships_available'))
      return
    }
    if(!fleet.isUsed){
      if(this.planetSelected !== undefined && !this.loadingUpdateShipsAmount){
        this.selectFleet(fleet)
        this._dialog.open(DialogEditFleetComponent, {
          width: '90%',
          maxWidth: '700px',
          panelClass: 'css-dialog',
          disableClose: true,
          data: {fleetSelected:fleet,ships:this.ships,allShips:this.viewAllShips,sumShips:this.sumShips,fleetSelectedType: this.fleetSelectedType,newFleetSelectedType: this.newFleetSelectedType, maxSizeFleet:this.maxSizeFleet,haveAllowance:this.haveAllowance,planetSelected:this.planetSelected,levelArmory:this.levelArmory},
        }).afterClosed().subscribe(async (res) => {
          if(res && res.data==='confirm'){
            this.loading = true;
          }
          this.loadingUpdateShipsAmount = true;
          this.updateData(this.planetSelected.id)
        })
      }
    }
  }
  async removeFleet(fleet:any,id: number, removeFleet: string,index:any) {
    if(!fleet.isUsed){
      const checkNetwork = await this._planetService.checkNetwork();
      if(checkNetwork){
        return
      }
      this.fleetIndex = index;
      this.loadRemove = true;
      this._contractService.writeContract(environment.astraFleet, fleetABI, removeFleet, [this.planetSelected.id, [id]])
          .then(async (buildFleet) => {
            const build = await buildFleet.wait();
            if(build){
              this.loading = true;
              this.loadRemove = false;
              this.fleetIndex = undefined;
              this._notifierService.showNotificationSuccess(this._translateService.instant('notification.success_remove_fleet'))
              this.updateData(this.planetSelected.id)
            }
          })
          .catch((err) => {
            const error = JSON.stringify(err)
            const data = JSON.parse(error)
            this._notifierService.showNotificationError(this._translateService.instant('notification.error_remove_fleet'))
            this.loading = false;
            this.loadRemove = false;
            this.fleetIndex = undefined;
          });
    }
  }
  selectFleet(fleet:any){
    this.fleetSelected = fleet;
    this.fleetSelectedType = {
      shipsType: fleet.shipsType,
      amounts: fleet.amounts,
    }
    this.newFleetSelectedType ={
      shipsType: fleet.shipsType,
      amounts: fleet.amounts,
    };
    this.updateShips(fleet)
  }
  updateShips(fleet:any){
    for(let i = 0 ; i < this.ships.length ;i++){
      this.ships[i].size = 0
    }
    for(let i = 0 ; i < fleet.shipsType.length ;i++){
      const index =  this.allShips.findIndex((data: any) => data.id === +fleet.shipsType[i]);
      this.sumShips = fleet.totalSize
      this.allShips[index].size = fleet.amounts[i]
    }
    const ships :any[]=[]
    for(let ship of this.allShips){
      if(ship.quantity >0 || ship.size  >0 ){
        ships.push(ship)
      }
    }
    this.ships = ships
  }

  openNewFleet(){
    if(this.myShipBalance && this.myShipBalance.length === 0){
      this._notifierService.showNotificationError(this._translateService.instant('notification.no_ships_available'))
      return
    }
    if(this.levelArmory === 0){
      this._notifierService.showNotificationError(this._translateService.instant('notification.armory_build_fleet'))
      return
    }
    if(this.planetSelected !== undefined && !this.loadingUpdateShipsAmount){
      const buildFleetDef = this.fleeDefLength < this.maxFleetDef
      const buildFleetAtt = this.fleeOffLength < this.maxFleetOff
      const ships :any[]=[]
      for(let ship of this.ships){
        if(ship.quantity >0){
          ships.push(ship)
        }
      }
      this._dialog.open(DialogAddNewFleetComponent, {
        width: '90%',
        maxWidth: '700px',
        panelClass: 'css-dialog',
        disableClose: true,
        data: {isPlanetInPhaseReveal:this.isPlanetInPhaseReveal,type: this.type === 'all' ? 'off' : this.type ,allShips:this.viewAllShips,ships:ships,maxSizeFleet: this.maxSizeFleet,planetSelected:this.planetSelected,buildFleetDef: buildFleetDef ,buildFleetAtt: buildFleetAtt,haveAllowance:this.haveAllowance,levelArmory:this.levelArmory},
      }).afterClosed().subscribe(async (res) => {
        if (res && res.maxSizeFleet) {
          this.loadingBuild= true;
        }
        this.loadingUpdateShipsAmount = true;
        this.updateData(this.planetSelected.id)
      })
    }
  }

  checkUpdateFleet(e: boolean,fleet:any){
    if(e){
      fleet.isUsed = false;
      this.updateData(this.planetSelected.id)
    }
  }

  viewShips(fleet:any){
    const ships = {
      amounts: fleet.amounts, type: fleet.shipsType
    }
    this._dialog.open(DialogViewShipsMintedComponent, {
      width: '90%',
      maxWidth: '500px',
      panelClass: 'css-dialog',
      disableClose: true,
      data:{ships:ships,allShips:this.viewAllShips},
    })
  }
}
