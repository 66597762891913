import {Component, OnInit} from '@angular/core';
import {PlanetService} from "../../../../core/services/planet.service";
import {AccountService} from "../../../../core/services/account.service";

@Component({
  selector: 'app-ranking-beta',
  templateUrl: './ranking-beta.component.html',
  styleUrls: ['./ranking-beta.component.scss']
})
export class RankingBetaComponent implements OnInit{
  score:any;
  type:string = 'off';
  address:any;
  planetSelected:any;
  dateNow:any;
  dateLimit:any;
  scoreDate:any;
  filterRanking:any;
  constructor(
    private _planetService:PlanetService,
    private _accountService:AccountService,

  ) {
  }

  ngOnInit(): void {
    this.dateNow = Math.floor(Date.now()/1000)
    this.dateLimit = this.dateNow + 604800
    this.scoreDate = Math.floor(Date.now()/1000)
    this._accountService.getWalletAddress().subscribe({
      next: async (account) => {
        if (account && (this.address === '' || account.address !== this.address)) {
          this.address = account.address;
          this._planetService.getPlanet().subscribe({
            next:(planets) => {
              if (planets && planets.length > 0 && this.planetSelected === undefined) {
                let planetSelectedName = localStorage.getItem(this.address+'planetSelected')
                if(planetSelectedName){
                  const planet = planets.filter((data) => data.name === planetSelectedName)
                  if(planet){
                    this.planetSelected = planet[0]
                    this.updateData(this.planetSelected.id)
                  } else {
                    this.planetSelected = planets[0]
                    this.updateData(this.planetSelected.id)
                  }
                } else {
                  this.planetSelected = planets[0]
                  this.updateData(this.planetSelected.id)
                }
              } else {
                this.updateData(0)
              }
            }
          })
        }
      }
    });
  }

  updateData(id:any){
    this._planetService.getInfoPlanetScore(id,this.scoreDate).subscribe({
      next:(score)=>{
        this.score = {
          rewardDef: this._planetService.formatBigNumber(score.rewardDef),
          rewardOff: this._planetService.formatBigNumber(score.rewardOff),
          rewardTotDef: this._planetService.formatBigNumber(score.rewardTotDef),
          rewardTotOff: this._planetService.formatBigNumber(score.rewardTotOff),
          roundInfo: score.roundInfo,
          roundToClaim: score.roundToClaim,
          scoreDef: score.scoreDef,
          scoreOff: score.scoreOff,
          scoreTotDef: score.scoreTotDef,
          scoreTotOff: score.scoreTotOff,
          startScore: score.startScore,
        };
        this.setType(this.type)
      }
    })
  }

  setType(type:any){
    if(type === 'def'){
      this._planetService.getRankingDef(this.score.roundInfo.roundId).subscribe({
        next:(rankingDef)=>{
          this.filterRanking = rankingDef
          this.filterRanking = this.filterRanking.filter((planet:any)=>planet.name !== '')
        }
      })
    } else if(type === 'off'){
      this._planetService.getRankingOff(this.score.roundInfo.roundId).subscribe({
        next:(rankingOff)=>{
          this.filterRanking = rankingOff;
          this.filterRanking = this.filterRanking.filter((planet:any)=>planet.name !== '')
        }
      })
    }
    this.type = type
  }

}
