<mat-sidenav-container>
  <mat-sidenav-content class="!ml-0">
    <div *ngIf="!this.loading" class="flex flex-col max-w-full container background_image" [ngStyle]="
       this.pathForBg === 'summary' ?
       {background:this.planetSelected.civilization === 1 ?
       'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(../../../../assets/images/background_signarok.png)'
        : this.planetSelected.civilization === 2 ?
       'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(../../../../assets/images/background_numaya.png)'
        : this.planetSelected.civilization === 3 ?
       'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(../../../../assets/images/background_afarit.png)'
        :
       'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(../../../../assets/images/background_no_planet.png)'
       }
       : this.pathForBg === 'planet'  ?
       {background:'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(../../../../assets/images/background_mint.png)'}
       :
       {background:'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%)'}
    ">
      <app-header class="hidden md:block"  (PlanetSelected)="setPlanet($event)" [address]="this.addressHeader" [walletAddress]="this.walletAddress" [drawer]="drawer" [planets]="planets" [attackUpComing]="attackUpComing"></app-header>
      <app-header *ngIf="drawer" [class]="drawer.opened ? 'hidden':'block md:hidden'" (PlanetSelected)="setPlanet($event)" [address]="this.addressHeader" [walletAddress]="this.walletAddress" [drawer]="drawer" [planets]="planets" [attackUpComing]="attackUpComing"></app-header>
      <div class="flex flex-col container_dashboard overflow-x-auto justify-between  mx-auto">
        <div class="flex flex-row ">
          <mat-sidenav mode="side" #drawer [opened]="isOpened" class="lg:!static !z-20 !h-full min_width !bg-light-black lg:!bg-transparent">
            <app-sidebar [drawer]="drawer" [attackUpComing]="attackUpComing" [planetAttacked]="planetAttacked"></app-sidebar>
          </mat-sidenav>
          <div class="h-full min-h-full container_router p-4 overflow-x-hidden">
            <router-outlet></router-outlet>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
    <div *ngIf="this.loading" class="flex flex-col max-w-full container background_image" [ngStyle]="this.path === 'summary' ?
       {background:'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%),url(../../../../assets/images/background_dashboard.png)'}
       :
       {background:'linear-gradient(180deg, #131627 0%, rgba(19, 22, 39, 0.44) 55.68%, rgba(19, 22, 39, 0.00) 100%)'}
    ">
      <div class="flex flex-col items-center justify-center w-screen h-screen absolute top-0 left-0" >
        <img src="assets/images/logo/logo-o-viola-chiaro.png" alt="" class="animate-pulse w-full max-w-xs" />
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
